import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class complain_modal extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.choose_complain = ko.observable('none');
        this.complain_text = ko.observable('');

        document.getElementById('complain-modal').addEventListener('hidden.bs.modal', () => {
            this.choose_complain('none');
            this.complain_text('');
        });

    }

}
