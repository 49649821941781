import ko from 'knockout';
import Handler from 'engine/Handler';
import 'styles/style_old.min.css';
//import 'knockout-mapping';
export default class home extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.unsubscribe = Store.subscribe(()=>{
            //console.log('subscribe',Store.getState());
        });
        this.fileUploads = ko.observableArray([ ]);
            this.textBtn = ko.observable();

            if(Store.getState().auth.value){
                this.getUserProfile();
            }else{
                this.Router.navigate('/login');
               // console.log('%c ' + i18next.t('you are not authorized!') , 'background: #222; color: #cf1616');
            }
    }

    async sendFiles(model, { target }) {
        this.Server.sendFiles( target.files,1000)
            .then(Files=>Files.forEach(file=>{
                let info = {
                    name : ko.observable(file.get('name')+' ('+Math.round( (file.get('size')/1048576) )+'mb/'+Math.round( (file.get('offset')/1048576) )+'mb)'),
                    size:ko.observable(Math.round( file.get('offset')/(file.get('size')/100) )+'%')
                };
                this.fileUploads.push(info);
                file.on('update',()=>{
                    info.size(Math.round( file.get('offset')/(file.get('size')/100) )+'%');
                    info.name(file.get('name')+' ('+Math.round( (file.get('size')/1048576) )+'mb/'+Math.round( (file.get('offset')/1048576) )+'mb)');
                });
            }));
    }

    getCompanyProfile() {
        this.Server.Request('company_profile')
            .then(res=>{
            let result = JSON.parse(res);
            if(result.success) {
                //console.log('%c Your company data: ', 'background: #222; color: #bada55',result);
                this.textBtn(result.profile.name);
            }
        }).catch(error=>this.textBtn(error));
    }

    getUserProfile() {
        this.Server.Request('user_profile')
            .then(res=>{
            let result = JSON.parse(res);
               // console.log(result);
            if(result.success) {
                ///console.log('%c Your user data: ', 'background: #222; color: #bada55',result);
                this.textBtn(result.profile.name);
            }
        }).catch(()=>this.getCompanyProfile());
    }

    logOut() {
        this.Server
            .Request('remove_device')
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.Router.navigate('/login');
                } else {
                    this.textBtn(result.error);
                }
            })
            .catch(error=>this.textBtn(error));
    }

}
