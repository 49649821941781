export const album_business = (params) => {
    const prefix = 'album-business';
    const name = params.surname ? params.surname + ' ' + params.name : params.name;

    return {
        tagName: 'div',
        type: 'page',
        attributes: {
            class: prefix + '_sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        components: [
            {
                type: 'header',
                tagName: 'div',
                attributes: {
                    class: 'header__require_content'
                },
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                components: [
                    {
                        type: 'image',
                        tagName: 'img',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        type: 'text',
                        tagName: 'h1',
                        content: name
                    }
                ]
            },
            {
                type: 'Main Submits',
                tagName: 'div',
                attributes: {
                    class: 'locals-page-menu'
                },
                layerable: false,
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                components: [
                    {
                        type: 'business_local_menu',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                    {
                        type: 'Main Submits',
                        tagName: 'div',
                        attributes: {
                            class: 'main__require_content'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                        <div>
                            <button class="main__item dropdown-toggle" id='album__btn-show-nav-menu' data-toggle="dropdown" aria-expanded="false">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                           
                        </div>
                        <button class="main__subscribe_item" id='album__btn-add_friends'>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.83C2 8.12153 2 7.26729 2.33776 6.61708C2.62239 6.06915 3.06915 5.62239 3.61708 5.33776C4.26729 5 5.12153 5 6.83 5H9V22H2V9.83ZM4 8C4 7.44772 4.44772 7 5 7H8V9H5C4.44772 9 4 8.55228 4 8ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H8V10H5ZM4 14C4 13.4477 4.44772 13 5 13H8V15H5C4.44772 15 4 14.5523 4 14Z" fill="#0068AD"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.83C7 5.12153 7 4.26729 7.33776 3.61708C7.62239 3.06915 8.06915 2.62239 8.61708 2.33776C9.26729 2 10.1215 2 11.83 2H12.17C13.8785 2 14.7327 2 15.3829 2.33776C15.9309 2.62239 16.3776 3.06915 16.6622 3.61708C17 4.26729 17 5.12153 17 6.83V21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H7V6.83ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H10ZM9 9C9 8.44771 9.44772 8 10 8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10C9.44772 10 9 9.55229 9 9ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H10ZM9 15C9 14.4477 9.44772 14 10 14H14C14.5523 14 15 14.4477 15 15C15 15.5523 14.5523 16 14 16H10C9.44772 16 9 15.5523 9 15ZM12 18C11.4477 18 11 18.4477 11 19V21H13V19C13 18.4477 12.5523 18 12 18Z" fill="white"/>
                                <circle cx="18" cy="14" r="5" fill="#0068AD"/>
                                <path d="M18 16L18 12M16 14H20" stroke="white" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <span class="main__item-title">Подписаться</span>
                        </button>
                    `
                    },
                ]
            },
            {
                tagName: 'main',
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        tagName: 'div',
                        type: 'six_column',
                        attributes: {
                            class: prefix + '_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'business_description'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'avatar_company'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'business_news'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'card_product'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'card_product'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'card_product'
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        type: 'two_test_column',
                        attributes: {
                            class: prefix + '_footer_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'contacts'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'test_map'
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'hr',
                        layerable: false,
                    },
                    {
                        type: 'footer'
                    }
                ]
            },
        ],
        styles: `
            body {
                background-color: #F3F5F5; 
            }    
            .sample__container {
                height: 100%
                padding: 0 40px;
            }
            .header__require_content {
                width: 100%;
                min-height: 250px;
                background-color: #0099ff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 25px;
            }
            .header__require_content > img {
                border-radius: 50%;
                border: none;
                width: 100px;
                height: 100px;
                object-fit: cover;
                position: relative;
            }
            .header__require_content > h1 {
                background: transparent;
                color: white;
            }
            .main__require_content {
                display: flex;
                position: relative;
            }
            .${prefix}_body__content {
                margin: 24px 5rem;
            }
            .main__subscribe_item {
                cursor: pointer;
                border-radius: 10px;
                border: none;
                color: white;
                background: #0099ff;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                align-items: center;
                font-family: FixelText-SemiBold, sans-serif;
                 padding: 12px 16px;
            }
            .require_content-right {
                display: flex;
                position: relative;
            }
            .posts-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .locals-page-menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 24px 5rem;
            }
            .dropdown-post-menu {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 10px;
                border: none;
                padding: 20px;
                text-align: start;
                left: -110px;
            }
            .dropdown-post-menu > button {
                margin-bottom: 5px;
                border-radius: 5px;
                padding: 10px
            }
            .dropdown-toggle {
                margin-right: 10px;
            }
            .dropdown-toggle::after {
                display: none;
            }
            .album_card {
                background-color: white;
                min-height: 400px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .${prefix}_card {
                background: #f3f5f5;
                border-radius: 10px;
                padding: 20px 32px;
                width: 100%;
                height: 100%;
            }
            .${prefix}_cards_list {
                min-height: 400px;
                gap: 1rem;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 0 17px;
                margin-bottom: 18px;
            }
            .${prefix}_footer_cards_list {
                min-height: 400px;
                gap: 1rem;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 0 17px;
                margin-top: 17px;
            }
        `
    };
};
