export const CloudStorageWidget = ({editor}) => {
    const prefix = 'cloud_storage';

    editor.Blocks.add(prefix, {
        media: `<svg width="60" height="80" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.666656 20C0.666656 8.95431 9.62096 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62096 40 0.666656 31.0457 0.666656 20Z" fill="#E0F3FF"/>
                    <path d="M15.008 18C14.7869 17.3744 14.6666 16.7013 14.6666 16C14.6666 12.6863 17.3529 10 20.6666 10C23.6464 10 26.1188 12.1721 26.5869 15.0194C28.8751 15.2313 30.6666 17.1564 30.6666 19.5C30.6666 21.9853 28.6519 24 26.1666 24C26.0742 24 25.9824 23.9972 25.8913 23.9917C25.8171 23.9972 25.7422 24 25.6666 24H13.6666C12.0098 24 10.6666 22.6569 10.6666 21C10.6666 19.3431 12.0098 18 13.6666 18H15.008Z" fill="#99D6FF"/>
                    <path d="M21.9956 19.7362L17.8036 23.9283C17.547 24.1848 17.4028 24.5328 17.4028 24.8957C17.4028 25.2585 17.547 25.6065 17.8036 25.8631C18.0601 26.1196 18.4081 26.2638 18.771 26.2638C19.1338 26.2638 19.4818 26.1196 19.7383 25.8631L23.9304 21.671C24.4435 21.1579 24.7318 20.4619 24.7318 19.7362C24.7318 19.0105 24.4435 18.3146 23.9304 17.8014C23.4173 17.2883 22.7213 17 21.9956 17C21.2699 17 20.574 17.2883 20.0608 17.8014L15.8688 21.9935C15.099 22.7632 14.6666 23.8071 14.6666 24.8957C14.6666 25.9842 15.099 27.0282 15.8688 27.7979C16.6385 28.5676 17.6824 29 18.771 29C19.8595 29 20.9034 28.5676 21.6731 27.7979L25.8652 23.6058" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Облачное хранилище'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_header d-flex flex-row align-items-center justify-content-between'
                        },
                        components: [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: 'd-flex align-items-center'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                        <path d="M0.666656 20C0.666656 8.95431 9.62096 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62096 40 0.666656 31.0457 0.666656 20Z" fill="#E0F3FF"/>
                                        <path d="M15.008 18C14.7869 17.3744 14.6666 16.7013 14.6666 16C14.6666 12.6863 17.3529 10 20.6666 10C23.6464 10 26.1188 12.1721 26.5869 15.0194C28.8751 15.2313 30.6666 17.1564 30.6666 19.5C30.6666 21.9853 28.6519 24 26.1666 24C26.0742 24 25.9824 23.9972 25.8913 23.9917C25.8171 23.9972 25.7422 24 25.6666 24H13.6666C12.0098 24 10.6666 22.6569 10.6666 21C10.6666 19.3431 12.0098 18 13.6666 18H15.008Z" fill="#99D6FF"/>
                                        <path d="M21.9956 19.7362L17.8036 23.9283C17.547 24.1848 17.4028 24.5328 17.4028 24.8957C17.4028 25.2585 17.547 25.6065 17.8036 25.8631C18.0601 26.1196 18.4081 26.2638 18.771 26.2638C19.1338 26.2638 19.4818 26.1196 19.7383 25.8631L23.9304 21.671C24.4435 21.1579 24.7318 20.4619 24.7318 19.7362C24.7318 19.0105 24.4435 18.3146 23.9304 17.8014C23.4173 17.2883 22.7213 17 21.9956 17C21.2699 17 20.574 17.2883 20.0608 17.8014L15.8688 21.9935C15.099 22.7632 14.6666 23.8071 14.6666 24.8957C14.6666 25.9842 15.099 27.0282 15.8688 27.7979C16.6385 28.5676 17.6824 29 18.771 29C19.8595 29 20.9034 28.5676 21.6731 27.7979L25.8652 23.6058" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <h5 class="main__item-title m-0">Облачное хранилище</h5>
                                `
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_go_to_page',
                                    type: 'button'
                                },
                                content: `
                                    <svg width="9" height="12" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.69699 0.46967C1.40409 0.176777 0.92922 0.176777 0.636326 0.46967C0.343433 0.762563 0.343433 1.23744 0.636326 1.53033L3.106 4L0.636326 6.46967C0.343433 6.76256 0.343433 7.23744 0.636326 7.53033C0.92922 7.82322 1.40409 7.82322 1.69699 7.53033L4.69699 4.53033C4.98988 4.23744 4.98988 3.76256 4.69699 3.46967L1.69699 0.46967Z" fill="hsla(204, 100%, 46%, 1)" fill-opacity="1"/>
                                    </svg>
                                `
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_items_content mb-3 d-flex flex-column',
                        },
                        content: `
                            <div class="${prefix}_item d-flex flex-row align-items-center" type="button">
                                <svg width="51" height="50" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                    <path d="M0.666656 20C0.666656 8.95431 9.62096 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62096 40 0.666656 31.0457 0.666656 20Z" fill="#E0F3FF"/>
                                    <path d="M10.6666 18.05C10.6666 15.2025 10.6666 13.7788 11.2296 12.6951C11.7039 11.7819 12.4485 11.0373 13.3618 10.5629C14.4454 10 15.8692 10 18.7166 10H22.6166C25.4641 10 26.8878 10 27.9715 10.5629C28.8847 11.0373 29.6293 11.7819 30.1037 12.6951C30.6666 13.7788 30.6666 15.2025 30.6666 18.05V21.95C30.6666 24.7975 30.6666 26.2212 30.1037 27.3049C29.6293 28.2181 28.8847 28.9627 27.9715 29.4371C26.8878 30 25.4641 30 22.6166 30H18.7166C15.8692 30 14.4454 30 13.3618 29.4371C12.4485 28.9627 11.7039 28.2181 11.2296 27.3049C10.6666 26.2212 10.6666 24.7975 10.6666 21.95V18.05Z" fill="#99D6FF"/>
                                    <path d="M14.6666 29.8447C14.1596 29.7614 13.74 29.6335 13.3618 29.4371C12.4486 28.9627 11.7039 28.2181 11.2296 27.3049C11.0331 26.9267 10.9052 26.5071 10.822 26H14.6666V29.8447Z" fill="#008DEB"/>
                                    <path d="M18.7166 30H19.6666V26H16.6666V29.9883C17.2572 30 17.933 30 18.7166 30Z" fill="#008DEB"/>
                                    <path d="M21.6666 30H22.6166C23.4003 30 24.0761 30 24.6666 29.9883V26H21.6666V30Z" fill="#008DEB"/>
                                    <path d="M30.5113 26C30.4281 26.5071 30.3002 26.9267 30.1037 27.3049C29.6293 28.2181 28.8847 28.9627 27.9715 29.4371C27.5933 29.6335 27.1737 29.7614 26.6666 29.8447V26H30.5113Z" fill="#008DEB"/>
                                    <path d="M26.6666 10.1553C27.1737 10.2386 27.5933 10.3665 27.9715 10.5629C28.8847 11.0373 29.6293 11.7819 30.1037 12.6951C30.3002 13.0733 30.4281 13.4929 30.5113 14H26.6666V10.1553Z" fill="#008DEB"/>
                                    <path d="M22.6166 10H21.6666V14H24.6666V10.0117C24.0761 10 23.4003 10 22.6166 10Z" fill="#008DEB"/>
                                    <path d="M19.6666 10H18.7166C17.933 10 17.2572 10 16.6666 10.0117V14H19.6666V10Z" fill="#008DEB"/>
                                    <path d="M14.6666 10.1553V14H10.822C10.9052 13.4929 11.0331 13.0733 11.2296 12.6951C11.7039 11.7819 12.4486 11.0373 13.3618 10.5629C13.74 10.3665 14.1596 10.2386 14.6666 10.1553Z" fill="#008DEB"/>
                                    <path d="M22.6666 20C22.6666 19.4722 22.137 19.1162 21.0778 18.4043C20.0041 17.6827 19.4673 17.3219 19.0669 17.5868C18.6666 17.8517 18.6666 18.5678 18.6666 20C18.6666 21.4322 18.6666 22.1483 19.0669 22.4132C19.4673 22.6781 20.0041 22.3173 21.0778 21.5957C22.137 20.8838 22.6666 20.5278 22.6666 20Z" fill="#008DEB"/>
                                </svg>
                                <div>
                                    <p class="${prefix}_item_title">376 _Old_ vs _New_ Button Widgets.mp4</p>
                                    <span class="${prefix}_item_info">6 дек 2023 — 93,8Мб</span>
                                </div>
                            </div>
                            <div class="${prefix}_item d-flex flex-row align-items-center" type="button">
                                <svg width="51" height="50" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                    <path d="M0.666656 20C0.666656 8.95431 9.62096 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62096 40 0.666656 31.0457 0.666656 20Z" fill="#E0F3FF"/>
                                    <path d="M11.6666 18C11.6666 14.2288 11.6666 12.3431 12.8382 11.1716C14.0098 10 15.8954 10 19.6666 10H20.6666L29.6666 18V22C29.6666 25.7712 29.6666 27.6569 28.4951 28.8284C27.3235 30 25.4379 30 21.6666 30H19.6666C15.8954 30 14.0098 30 12.8382 28.8284C11.6666 27.6569 11.6666 25.7712 11.6666 22V18Z" fill="#99D6FF"/>
                                    <path d="M20.6745 10.2142L20.6666 12.6027C20.6666 13.5271 20.6665 14.3442 20.7566 15.0027C20.8542 15.7165 21.0783 16.4303 21.6708 17.0128C22.2634 17.5953 22.9893 17.8156 23.7154 17.9116C24.3853 18.0001 25.2164 18.0001 26.1567 18L29.6301 18C29.6484 17.9676 29.657 17.9307 29.654 17.8937C29.5936 17.1536 29.2796 16.3177 28.8365 15.7239C28.7565 15.6167 28.7018 15.5523 28.5924 15.4236C27.913 14.6243 27.0189 13.6285 26.2381 12.944C25.543 12.3347 24.5912 11.668 23.761 11.123C23.0478 10.6546 22.6912 10.4205 22.2022 10.2465C22.0726 10.2003 21.9016 10.1494 21.7677 10.117C21.4386 10.0373 21.1182 10.0096 20.6666 10L20.6745 10.2142Z" fill="#008DEB"/>
                                    <path d="M26.1666 24H24.6666M26.6666 21H24.6666V24M24.6666 24V26" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.6666 26H18.6666C18.6666 26.2652 18.772 26.5196 18.9595 26.7071C19.1471 26.8946 19.4014 27 19.6666 27V26ZM19.6666 21V20C19.1143 20 18.6666 20.4477 18.6666 21L19.6666 21ZM19.6666 22H20.6666V20H19.6666V22ZM21.6666 23V24H23.6666V23H21.6666ZM20.6666 25H19.6666V27H20.6666V25ZM21.6666 24C21.6666 24.5523 21.2189 25 20.6666 25V27C22.3235 27 23.6666 25.6569 23.6666 24H21.6666ZM20.6666 22C21.2189 22 21.6666 22.4477 21.6666 23H23.6666C23.6666 21.3431 22.3235 20 20.6666 20V22ZM18.6666 21L18.6666 26H20.6666L20.6666 21L18.6666 21Z" fill="#008DEB"/>
                                    <path d="M14.6666 24H16.1666C16.9951 24 17.6666 23.3284 17.6666 22.5C17.6666 21.6716 16.9951 21 16.1666 21H14.6666V24ZM14.6666 24V26" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <div>
                                    <p class="${prefix}_item_title">Грокаем алгоритмы.pdf</p>
                                    <span class="${prefix}_item_info">6 дек 2023 — 72,8 МБ</span>
                                </div>
                            </div>
                             <div class="${prefix}_item d-flex flex-row align-items-center" type="button">
                                <svg width="51" height="50" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                    <path d="M0.666656 20C0.666656 8.95431 9.62096 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62096 40 0.666656 31.0457 0.666656 20Z" fill="#E0F3FF"/>
                                    <path d="M11.6666 18C11.6666 14.2288 11.6666 12.3431 12.8382 11.1716C14.0098 10 15.8954 10 19.6666 10H20.6666L29.6666 18V22C29.6666 25.7712 29.6666 27.6569 28.4951 28.8284C27.3235 30 25.4379 30 21.6666 30H19.6666C15.8954 30 14.0098 30 12.8382 28.8284C11.6666 27.6569 11.6666 25.7712 11.6666 22V18Z" fill="#99D6FF"/>
                                    <path d="M20.6745 10.2142L20.6666 12.6027C20.6666 13.5271 20.6665 14.3442 20.7566 15.0027C20.8542 15.7165 21.0783 16.4303 21.6708 17.0128C22.2634 17.5953 22.9893 17.8156 23.7154 17.9116C24.3853 18.0001 25.2164 18.0001 26.1567 18L29.6301 18C29.6484 17.9676 29.657 17.9307 29.654 17.8937C29.5936 17.1536 29.2796 16.3177 28.8365 15.7239C28.7565 15.6167 28.7018 15.5523 28.5924 15.4236C27.913 14.6243 27.0189 13.6285 26.2381 12.944C25.543 12.3347 24.5912 11.668 23.761 11.123C23.0478 10.6546 22.6912 10.4205 22.2022 10.2465C22.0726 10.2003 21.9016 10.1494 21.7677 10.117C21.4386 10.0373 21.1182 10.0096 20.6666 10L20.6745 10.2142Z" fill="#008DEB"/>
                                    <path d="M14.6666 22H22.6666M14.6666 26H19.6666" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                                <div>
                                    <p class="${prefix}_item_title">Без названия.txt</p>
                                    <span class="${prefix}_item_info">5 дек 2023 —  19 Б</span>
                                </div>
                            </div>
                        `
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_using_indicator',
                        },
                        content: `
                            <div class="d-flex flex-column align-items-center">
                                <div class="${prefix}_indicator_container">
                                    <div class="${prefix}_indicator"></div>
                                </div>
                                <span class="${prefix}_indicator_value">Использовано 64 МБ из 500 МБ</span>
                            </div>
                        `
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        color: hsla(0, 0%, 0%, 0.54);
                         font-size: 12px;
                        font-weight: 400;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                        background: linear-gradient(159.19deg, rgba(0, 153, 255, 0.501961) 0%, rgba(0, 153, 255, 0.470588) 1.85%, rgba(0, 153, 255, 0.4) 6.77%, rgba(0, 153, 255, 0.301961) 14.76%, rgba(0, 153, 255, 0.2) 24.6%, rgba(0, 153, 255, 0.1) 35.68%, rgba(0, 153, 255, 0.03) 48.59%, rgba(0, 153, 255, 0.0001) 61.51%),
linear-gradient(0deg, #FCFDFD, #FCFDFD);
                     }
                     .${prefix}_go_to_page {
                        border-radius: 50%;
                        padding: 10px 11px;
                        background: hsla(204, 100%, 80%, 1);
                        display: flex;
                     }
                     .${prefix}_item_title {
                        font-size: 14px;
                        font-weight: 600;
                        color: black;
                        margin: 0;
                     }
                     .${prefix}_item {
                        padding: 10px 15px;
                     }
                     .${prefix}_indicator_container {
                        width: 80%;
                        background: hsla(0, 0%, 0%, 0.04);
                        height: 5px;
                        border-radius: 10px;
                        margin-bottom: 12px;
                        overflow: hidden;
                     }
                     .${prefix}_indicator {
                        width: 20%;
                        height: 5px;
                        background: hsla(204, 100%, 37%, 1);
                     }
                `
            }
        }
    });
};