export const Categories = ({editor}) => {
    const prefix = 'categories';

    function script() { }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                draggable: '[data-gjs-type=container]',
                tagName: 'div',
                attributes: {
                    class: prefix + '_card'
                },
                components: [
                    {
                        tagName: 'h3',
                        type: 'text',
                        name: 'Название раздела',
                        attributes: {
                            class: prefix + '_title'
                        },
                        content: 'Категории'
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                 components: [
                                     {
                                         type: 'card_category'
                                     },
                                 ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'card_category'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'card_category'
                                    },
                                ]
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_card {
                        margin-top: 50px;
                        background: #f3f5f5;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                         padding: 0 17px;
                    }
                    .${prefix}_cards_list {
                        min-height: 400px;
                        gap: 1rem;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                    }
                    .${prefix}_title {
                        margin-bottom: 30px;
                        font-size: 28px;
                        font-weight: 500;
                    }
                `
            }
        }
    });
};
