import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class invitation_modal extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.isShowForm = ko.observable(false);

        this.listInviteContact = Array.from([
            {
                name: 'Василий Петров',
                isCheck: false
            },
            {
                name: 'Василий Петров',
                isCheck: false
            },
            {
                name: 'Василий Петров',
                isCheck: true
            },
            {
                name: 'Василий Петров',
                isCheck: false
            },
            {
                name: 'Василий Петров',
                isCheck: true
            },
            {
                name: 'Василий Петров',
                isCheck: false
            },
        ], contact => ko.mapping.fromJS(contact));
    }

    checked(position) {
        ko.utils.arrayMap(this.listInviteContact, (contact, index) => {
           if (index === position) contact.isCheck(!contact.isCheck());
           return contact;
        });
    }

}
