import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import 'binding/i18nText';
import 'styles/style_old.min.css';

export default class  extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.unsubscribe = Store.subscribe(()=>{
            this.lang(Store.getState().lang.value);
        });
        this.lang = ko.observable(Store.getState().lang.value);

        if (Store.getState().auth.value)
            this.Router.navigate('/my_page');

       // this.textBtn = ko.observable(this.param.caption);
        this.isShowPass = ko.observable(false);
        this.email = ko.observable('');
        this.password = ko.observable('');
        this.errorEmail = ko.observable('');
        this.errorPassword = ko.observable('');
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    validatePhone(phone) {
        const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
        return re.test(phone);
    }

    async sendBuffer(model, { target }) {
        this.Server.sendFiles(target.files)
    }

    eventField(fieldName) {
        let self = this;
        return {
            keyup: (data, event) => {
                let value = event.target.value;
                if (fieldName === 'email') {
                    if (document.querySelector('#email').classList.contains('error-border') && self.validateEmail(value) || self.validatePhone(value)) {
                        document.querySelector('#email').classList.remove('error-border');
                    }
                    if (event.code === 'Enter') {
                        document.querySelector('#password').focus();
                    }
                }
                if (fieldName === 'password') {
                    if (document.querySelector('#password').classList.contains('error-border') && value.length >= 8) {
                        document.querySelector('#password').classList.remove('error-border');
                    }
                    if (event.code === 'Enter') {
                        self.sendForm();
                    }
                }
            },
            blur: (data, event) => {
                let value = event.target.value;
                if (fieldName === 'email' && value.length > 0) {
                    if (self.validateEmail(value) || self.validatePhone(value)) {
                        document.querySelector('#email').classList.remove('error-border');
                    } else {
                        document.querySelector('#email').classList.add('error-border');
                    }
                }
                if (fieldName === 'password' && value.length > 0) {
                    if (value.length >= 8) {
                        document.querySelector('#password').classList.remove('error-border');
                    } else {
                        document.querySelector('#password').classList.add('error-border');
                    }
                }
            }
        };
    }

    authError(error) {
        this.errorEmail(this.i18next.t(error));
    }

    authSuccess() {
        this.Server.Request('config').then((resp)=>{
            console.log(resp);
            this.Store.dispatch({ type: 'auth/set', payload:true });
            this.Server.User = JSON.parse(resp);
            localStorage.setItem('lng', this.Server.User.lng);
            this.errorEmail('');
            this.Router.navigate('/my_page');
        }).catch(e=>console.log(e));
    }

    sendForm() {
        this.Server
            .Request((this.email().indexOf("@") === -1)?'login_by_phone':'login_by_email', {
                value: (this.email().indexOf("@") === -1)?this.email().replace(/\D/g, ''):this.email(),
                password: this.password()
            })
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.authSuccess(result.id);
                }
            })
            .catch(e=>this.authError(this.i18next.t(e)));


    }
}
