export const universal = (params) => {
    const prefix = 'universal';

    const name = params.surname ? params.surname + ' ' + params.name : params.name;

    return {
        tagName: 'div',
        type: 'page',
        attributes: {
            class: prefix + '_sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        editable: false,
        components: [
            {
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    class: prefix + '_hero_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_hero'
                        },
                        components: [
                            {
                                tagName: 'img',
                                type: 'image',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                attributes: {
                                    class: prefix + '_hero_img',
                                    src: 'https://blog.adobe.com/en/publish/2023/03/21/media_1300bfc0a489236a9b0268781c17bcc32582cd288.jpeg?width=750&format=jpeg&optimize=medium'
                                }
                            },
                            {
                                tagName: 'img',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                type: 'image',
                                attributes: {
                                    class: prefix + '_hero_img',
                                    src: 'https://www.fotor.com/blog/wp-content/uploads/2017/09/photo-composition-e1597393995467-797x1024.jpg'
                                }
                            },
                            {
                                tagName: 'div',
                                layerable: false,
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_state'
                                },
                                content: `
                                            <span>личный рекорд</span>
                                            <span class="state_count">5392</span>
                                            <div class="${prefix}_state_desc_value">
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.6029 16.265L21.3084 12.1852C21.4846 11.1662 20.701 10.2342 19.668 10.2342H14.4869C13.9734 10.2342 13.5824 9.77328 13.6655 9.26598L14.3282 5.22142C14.4358 4.56435 14.4051 3.892 14.2379 3.24752C14.0994 2.71364 13.6875 2.28495 13.1458 2.11093L13.0008 2.06435C12.6734 1.95918 12.3161 1.98365 12.0074 2.13239C11.6677 2.29611 11.4191 2.59473 11.327 2.94989L10.8513 4.78374C10.6999 5.36723 10.4795 5.93045 10.1952 6.46262C9.77984 7.24017 9.13766 7.86246 8.47012 8.43769L7.03138 9.67749C6.62573 10.0271 6.41269 10.5506 6.45885 11.0844L7.27101 20.4771C7.34551 21.3386 8.06581 22 8.92959 22H13.5782C17.0595 22 20.0305 19.5744 20.6029 16.265Z" fill="white" fill-opacity="0.54"/>
                                                    <path d="M2.33325 11.0416C2.33325 10.4663 2.79959 10 3.37485 10C3.91657 10 4.36786 10.4152 4.41284 10.9551L5.2035 20.443C5.27335 21.2812 4.61192 22 3.77086 22C2.97689 22 2.33325 21.3564 2.33325 20.5624V11.0416Z" fill="white" fill-opacity="0.87"/>
                                                </svg>
                                                <span>Лайки за пост</span>
                                            </div>
                                        `
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_hero_content d-flex flex-row align-items-center justify-content-between'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_hero_text'
                                },
                                components: [
                                    {
                                        tagName: 'span',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        type: 'text',
                                        attributes: {
                                            class: prefix + '_content_eye_brow'
                                        },
                                        content: 'Автор цифрового контента'
                                    },
                                    {
                                        tagName: 'h1',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        type: 'text',
                                        attributes: {
                                            class: prefix + '_content_title'
                                        },
                                        content: name
                                    },
                                    {
                                        tagName: 'p',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        type: 'text',
                                        attributes: {
                                            class: prefix + '_content_subtitle'
                                        },
                                        content: 'Как наемся, так хочу быть летчицей. А как голодная, так поварихой!'
                                    },
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_content_action_items'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                components: [
                                    {
                                        type: 'Main Submits',
                                        tagName: 'div',
                                        attributes: {
                                            class: prefix + '_action_items'
                                        },
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        content: `
                                            <button class="${prefix}_item main__subscribe_item" id='album__btn-add_friends'>
                                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.83C2 8.12153 2 7.26729 2.33776 6.61708C2.62239 6.06915 3.06915 5.62239 3.61708 5.33776C4.26729 5 5.12153 5 6.83 5H9V22H2V9.83ZM4 8C4 7.44772 4.44772 7 5 7H8V9H5C4.44772 9 4 8.55228 4 8ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H8V10H5ZM4 14C4 13.4477 4.44772 13 5 13H8V15H5C4.44772 15 4 14.5523 4 14Z" fill="#0068AD"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.83C7 5.12153 7 4.26729 7.33776 3.61708C7.62239 3.06915 8.06915 2.62239 8.61708 2.33776C9.26729 2 10.1215 2 11.83 2H12.17C13.8785 2 14.7327 2 15.3829 2.33776C15.9309 2.62239 16.3776 3.06915 16.6622 3.61708C17 4.26729 17 5.12153 17 6.83V21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H7V6.83ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H10ZM9 9C9 8.44771 9.44772 8 10 8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10C9.44772 10 9 9.55229 9 9ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H10ZM9 15C9 14.4477 9.44772 14 10 14H14C14.5523 14 15 14.4477 15 15C15 15.5523 14.5523 16 14 16H10C9.44772 16 9 15.5523 9 15ZM12 18C11.4477 18 11 18.4477 11 19V21H13V19C13 18.4477 12.5523 18 12 18Z" fill="white"/>
                                                    <circle cx="18" cy="14" r="5" fill="#0068AD"/>
                                                    <path d="M18 16L18 12M16 14H20" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                                </svg>
                                                <span class="main__item-title">Подписаться</span>
                                            </button>
                                            <div>
                                                <button class="${prefix}_item ${prefix}_nav_menu" id='album__btn-show-nav-menu' data-toggle="dropdown" aria-expanded="false">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        `
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'Main Submits',
                tagName: 'div',
                attributes: {
                    class: prefix + '_locals-page-menu'
                },
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                components: [
                    {
                        type: 'personal_local_menu',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                ]
            },
            {
                tagName: 'main',
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_cards_top_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'about_me'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'personal_data'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        attributes: {
                            class: prefix + '_cards_bottom_list'
                        },
                        components: [
                            {
                                tagName: 'div',
                                hoverable: false,
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                attributes: {
                                    class: prefix + '_plaster d-flex flex-column'
                                },
                                components: [
                                    {
                                        tagName: 'div',
                                        type: 'gallery',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        attributes: {
                                            class: prefix + '_plaster_gallery'
                                        },
                                        components: [
                                            {
                                                tagName: 'img',
                                                type: 'photo',
                                                attributes: {
                                                    src: 'https://www.fotor.com/blog/wp-content/uploads/2017/09/photo-composition-e1597393995467-797x1024.jpg'
                                                }
                                            },
                                            {
                                                tagName: 'img',
                                                type: 'photo',
                                                attributes: {
                                                    src: 'https://images.unsplash.com/photo-1544465544-1b71aee9dfa3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBob3RvfGVufDB8fDB8fHww'
                                                }
                                            },
                                            {
                                                tagName: 'img',
                                                type: 'photo',
                                                attributes: {
                                                    src: 'https://images.pexels.com/photos/214574/pexels-photo-214574.jpeg?cs=srgb&dl=pexels-sebastian-voortman-214574.jpg&fm=jpg'
                                                }
                                            },
                                            {
                                                tagName: 'img',
                                                type: 'photo',
                                                attributes: {
                                                    src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg'
                                                }
                                            },
                                            {
                                                tagName: 'div',
                                                hoverable: false,
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                layerable: false,
                                                attributes: {
                                                    class: prefix + '_helper_title'
                                                },
                                                content: `
                                                    <span>Фото</span>
                                                `
                                            }
                                        ]
                                    },
                                    {
                                        tagName: 'div',
                                        hoverable: false,
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        layerable: false,
                                        attributes: {
                                            class: prefix + '_plaster_state'
                                        },
                                        components: [
                                            {
                                                tagName: 'div',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                attributes: {
                                                    class: prefix + '_state_content ' + prefix + '_state_friends'
                                                },
                                                content: `
                                                    <span class="${prefix}_state_title">Друзья</span>
                                                    <span class="${prefix}_state_count">128</span>
                                                    <span>1 общий</span>
                                                 `
                                            },
                                            {
                                                tagName: 'img',
                                                type: 'image',
                                                attributes: {
                                                    src: 'https://blog.adobe.com/en/publish/2023/03/21/media_1300bfc0a489236a9b0268781c17bcc32582cd288.jpeg?width=750&format=jpeg&optimize=medium'
                                                }
                                            },
                                            {
                                                tagName: 'div',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                attributes: {
                                                    class: prefix + '_state_content ' + prefix + '_state_subscribers'
                                                },
                                                content: `
                                                    <span class="${prefix}_state_title">Подписчики</span>
                                                    <span class="${prefix}_state_count">124 221</span>
                                                    <span>человек</span>
                                                 `
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'career'
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        type: 'feed_post_list',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        editable: false,
                        hoverable: false,
                    }
                ]
            }
        ],
        styles: `
            body {
                background-color: #F3F5F5; 
            } 
            .${prefix}_hero_container {
                background: #ffffff;
                font-family: sans-serif;
            }  
            .${prefix}_hero_images {
                width: 650px;
                height: 100%;
                position: relative;
            }
            .${prefix}_hero_img {
                object-fit: cover;
            }
            .${prefix}_content_avatar {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
            .${prefix}_hero_discount {
               width: 125px;
               height: 125px;
               position: absolute;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 50%;
               bottom: 15%;
               right: 27%;
               background: #0099FF;
               font-size: 50px;
               color: #FFFFFF8A;
               font-wight: 500;
            }
            .${prefix}_hero_content {
                padding: 56px 20px 56px 40px;
            } 
            .${prefix}_hero {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
            .${prefix}_hero > img {
                width: 100%;
                height: 360px;
            }
            .${prefix}_state {
                background: #0099FF;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .${prefix}_state > span, .${prefix}_state > div > span {
                color: #FFFFFFDE;
                font-weight: 400;
            }
            .${prefix}_state > .state_count {
                color: #FFFFFF;
                font-size: 51px;
                font-weight: 600;
            }
            .${prefix}_state_desc_value {
                display: flex;
                align-items: center;
            }
            .${prefix}_state_desc_value > span {
                margin-left: 5px;
            }
            .${prefix}_hero_text > .${prefix}_content_title {
               font-size: 51px;
               font-weight: 600;
               letter-spacing: -1px;
               padding: 18px 0px 6px 0px;
            }
             .${prefix}_hero_text > .${prefix}_content_subtitle {
                font-size: 21px;
                font-wight: 400;
                padding: 13px 0 27px 0;
                color: #0000008A;
                max-width: 975px;
             }
             .${prefix}_action_items {
                display: flex;
                position: relative;
             } 
             .${prefix}_nav_menu {
                padding: 5px 15px;
                margin-left: 10px;
                border: none;
                background: none;
                height: 100%;
                border-radius: 10px;
                transition: all .2s ease;
             }
             .${prefix}_nav_menu:hover {
                background: white;
             }
             .main__subscribe_item {
                cursor: pointer;
                border-radius: 10px;
                border: none;
                color: white;
                background: #0099ff;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                align-items: center;
                padding: 12px 16px;
            }
            .${prefix}_locals-page-menu {
                margin: 20px 40px 20px;
            }
         
            .${prefix}_body__content {
               padding: 0 40px 48px 40px;
            }
            .${prefix}_cards_top_list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin: 18px 0 0;
            }
             .${prefix}_cards_bottom_list {
                display: grid;
                grid-template-columns: 70% 30%;
                align-items: center;
            }
            .${prefix}_plaster_gallery {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 205px;
                position: relative;
            }
            .${prefix}_plaster_gallery > img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
            .${prefix}_helper_title {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.12);
                color: white;
                font-size: 38px;
                font-weight: 500;
            }
            .${prefix}_plaster_state {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 205px;
            }
            .${prefix}_plaster_state > img {
                width: 100%;
                position: relative;
                border-radius: 0;
            }
            .${prefix}_state_content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: white;
            }
            .${prefix}_state_content {
                color: #0000008A;
                font-size: 14px;
                font-weight: 400;
            }
            .${prefix}_state_count {
                font-size: 28px;
                font-weight: 500;
                line-height: 32px;
            }
             .${prefix}_state_title {
                font-weight: 600;
                letter-spacing: 0.25px;
                color: black;
            }
        `
    };
};
