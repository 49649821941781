import ko from 'knockout';
import Handler from 'engine/Handler';
import GLightbox from "glightbox";

//import 'knockout-mapping';
export default class community_page extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.filter = ko.observable('ribbon');

        setTimeout(()=>{
            GLightbox({
                touchNavigation: false,
                loop: false,
                autoplayVideos: true
            });
        },0);
    }

    toggleMenu() {
        return {
            click: (data, e) => {
                e.target.closest('.planet_layout .float-menu').classList.toggle('is-active');
            }
        };
    }

    chooseSample(name) {
        this.Store.dispatch({type: 'sample/setSampleName', payload: { name }});
        this.Router.navigate('/community_layout');
    }


}
