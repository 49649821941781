import ko from 'knockout';
import {Toast} from 'bootstrap';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class toast_info extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});

        const liveToastBtn = document.querySelector('.show_toast');
        const liveToast = document.getElementById('liveToast');
        const blurOverlay = document.getElementById('blurOverlay');

        if (liveToastBtn && liveToast) {
            liveToastBtn.addEventListener('click', () => {
                const toast = new Toast(liveToast, {
                    autohide: false
                });
                toast.show();
                blurOverlay.classList.add('show');
                liveToast.addEventListener('hidden.bs.toast', () => {
                    blurOverlay.classList.remove('show');
                });
            });
            document.addEventListener('click', (event) => {
                if (!liveToast.contains(event.target) && !liveToastBtn.contains(event.target)) {
                    const toast = new Toast(liveToast);
                    toast.hide();
                }
            });
            liveToast.querySelector('.btn-close').addEventListener('click', () => {
                blurOverlay.classList.remove('show');
            });
        }
    }

}
