export const ConnectionComponent = ({editor}) => {
    const prefix = 'connection';

    function script() {
        const carouselList = document.querySelectorAll('.connection_carousel__list');
        const carouselItems = document.querySelectorAll('.connection_carousel__item');
        const elems = Array.from(carouselItems);

        carouselList.forEach(item => {
            item.addEventListener('click', function (event) {
                var newActive = event.target;
                var isItem = newActive.closest('.connection_carousel__item');

                if (!isItem || newActive.classList.contains('carousel__item_active')) {
                    return;
                }

                update(newActive);
            });
        });

        const update = function(newActive) {
            const newActivePos = newActive.dataset.pos;

            const current = elems.find((elem) => parseInt(elem.dataset.pos) === 0);
            const prev = elems.find((elem) => parseInt(elem.dataset.pos) === -1);
            const next = elems.find((elem) => parseInt(elem.dataset.pos) === 1);

            current.classList.remove('carousel__item_active');

            [current, prev, next].forEach(item => {
                var itemPos = item.dataset.pos;

                item.dataset.pos = getPos(itemPos, newActivePos);
            });
        };

        const getPos = function (current, active) {
            const diff = current - active;

            if (Math.abs(current - active) > 1) {
                return -current;
            }

            return diff;
        };
    }

    editor.Blocks.add(prefix, {
        media: `<svg width="60" height="80" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.666687 20C0.666687 8.95431 9.62099 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62099 40 0.666687 31.0457 0.666687 20Z" fill="#E0F3FF"/>
                    <path d="M13.5902 26.7696C14.8946 25.02 15.6667 22.8502 15.6667 20.5C15.6667 17.6786 14.5539 15.117 12.7432 13.2304C11.4388 14.9801 10.6667 17.1499 10.6667 19.5C10.6667 22.3215 11.7795 24.883 13.5902 26.7696Z" fill="#99D6FF"/>
                    <path d="M28.4364 27.0765C26.5497 28.8872 23.9882 30 21.1668 30C18.8166 30 16.6468 29.2279 14.8972 27.9235C16.7838 26.1128 19.3453 25 22.1668 25C24.5169 25 26.6867 25.7721 28.4364 27.0765Z" fill="#99D6FF"/>
                    <path d="M14.832 28.9743C13.6361 23.3 17.2667 17.7306 22.941 16.5347" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                    <circle cx="24.1667" cy="16.5" r="6.5" fill="#008DEB"/>
                    <circle cx="24.6667" cy="16" r="4" fill="#99D6FF"/>
                    <circle cx="25.1667" cy="15.5" r="2.5" fill="#008DEB"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Знакомства'
    });
    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_header d-flex flex-row align-items-center justify-content-between'
                        },
                        components: [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: 'd-flex align-items-center'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                        <path d="M0.666687 20C0.666687 8.95431 9.62099 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62099 40 0.666687 31.0457 0.666687 20Z" fill="#E0F3FF"/>
                                        <path d="M13.5902 26.7696C14.8946 25.02 15.6667 22.8502 15.6667 20.5C15.6667 17.6786 14.5539 15.117 12.7432 13.2304C11.4388 14.9801 10.6667 17.1499 10.6667 19.5C10.6667 22.3215 11.7795 24.883 13.5902 26.7696Z" fill="#99D6FF"/>
                                        <path d="M28.4364 27.0765C26.5497 28.8872 23.9882 30 21.1668 30C18.8166 30 16.6468 29.2279 14.8972 27.9235C16.7838 26.1128 19.3453 25 22.1668 25C24.5169 25 26.6867 25.7721 28.4364 27.0765Z" fill="#99D6FF"/>
                                        <path d="M14.832 28.9743C13.6361 23.3 17.2667 17.7306 22.941 16.5347" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                                        <circle cx="24.1667" cy="16.5" r="6.5" fill="#008DEB"/>
                                        <circle cx="24.6667" cy="16" r="4" fill="#99D6FF"/>
                                        <circle cx="25.1667" cy="15.5" r="2.5" fill="#008DEB"/>
                                     </svg>
                                    <h5 class="main__item-title m-0">Знакомства</h5>
                                `
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_go_to_page',
                                    type: 'button'
                                },
                                content: `
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.69699 0.46967C1.40409 0.176777 0.92922 0.176777 0.636326 0.46967C0.343433 0.762563 0.343433 1.23744 0.636326 1.53033L3.106 4L0.636326 6.46967C0.343433 6.76256 0.343433 7.23744 0.636326 7.53033C0.92922 7.82322 1.40409 7.82322 1.69699 7.53033L4.69699 4.53033C4.98988 4.23744 4.98988 3.76256 4.69699 3.46967L1.69699 0.46967Z" fill="black" fill-opacity="0.38"/>
                                    </svg>
                                `
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_items_content',
                        },
                        content: `
                              <ul class="${prefix}_carousel__list">
                                <li class="${prefix}_carousel__item" data-pos="-1">1</li>
                                <li class="${prefix}_carousel__item" data-pos="0">2</li>
                                <li class="${prefix}_carousel__item" data-pos="1">3</li>
                              </ul>
                        `
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                     }
                     .${prefix}_go_to_page {
                        border-radius: 50%;
                        padding: 10px 11px;
                        background: hsla(0, 0%, 0%, 0.12);
                        display: flex;
                     }
                     .${prefix}_items_content {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                     }
                        
                     .${prefix}_carousel__list {
                        display: flex;
                        list-style: none;
                        position: relative;
                        width: 90%;
                        height: 270px;
                        justify-content: center;
                        perspective: 300px;
                     } 
                     .${prefix}_carousel__item {
                        border: 10px solid white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 0px;
                        width: 220px;
                        height: 270px;
                        border-radius: 12px;
                        box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5);
                        position: absolute;
                        transition: all .3s ease-in;
                        cursor: pointer;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                     }  
                     .${prefix}_carousel__item:nth-child(1) {
                        background-image: url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg);
                     }
                     .${prefix}_carousel__item:nth-child(2) {
                        background-image: url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg);
                     }
                     .${prefix}_carousel__item:nth-child(3) {
                        background-image: url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg);
                     }
                     .${prefix}_carousel__item[data-pos="0"] {
                        z-index: 5;
                     }
                     .${prefix}_carousel__item[data-pos="-1"],
                     .${prefix}_carousel__item[data-pos="1"] {
                        opacity: 0.7;
                        filter: blur(1px) grayscale(10%);
                     }
                     .${prefix}_carousel__item[data-pos="-1"] {
                        transform: translateX(-40%) scale(.9);
                        z-index: 4;
                     }
                     .${prefix}_carousel__item[data-pos="1"] {
                        transform: translateX(40%) scale(.9);
                        z-index: 4;
                     }
                     
                `
            }
        }
    });
};