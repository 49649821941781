import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class call_modal extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        // this.textBtn = ko.observable(this.param.caption);
        this.cameraOn = ko.observable(false);
        this.micOn = ko.observable(false);
        this.muteOn = ko.observable(false);

        // Server
        //     .Request('profile')
        //     .then(res=>{
        //         console.log(res)
        //     })
        //     .catch(e=>console.log(e))
        //
        // this.Server.on('message',(message)=>{
        //     console.log('message',message);
        // })

    }

}
