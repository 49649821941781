export const DoubleColumn = ({editor}) => {
    const prefix = 'double_column';

    editor.Blocks.add(prefix, {
        media: `
            <div style="width: 65px; height: 65px;display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                <div style="padding: 24px 5px; width: 25px; height: 40px;background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 5px;"></div>
                <div style="padding: 24px 5px; width: 25px; height: 40px;background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 5px;"></div>
            </div> 
        `,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: '2 колонки'},
        label: '2 колонки'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=container]',
                attributes: {
                    class: prefix
                },
                components: [
                    {
                        type: 'one_column',
                    },
                    {
                        type: 'one_column',
                    },
                ],
                styles: `
                    .${prefix} {
                         border-radius: 10px;
                         background: white;
                         min-height: 400px;
                         height: 100%;
                         display: table;
                         width: 100%;
                         background: #f3f5f5;
                         border-spacing: 1rem;
                    } 
                    .${prefix} > div {
                        position: relative;
                        border-radius: 10px;
                        background: white;
                        width: 8%;
                        height: 100%;
                        padding: 10px;
                        display: table-cell;
                    }
                    
                    @media (max-width: 768px) {
                        .${prefix} > div {
                            display: block;
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                    `
            }
        }
    });
};
