export const portfolio = (params) => {
    const prefix = 'portfolio';

    const name = params.surname ? params.surname + ' ' + params.name : params.name;

    return {
        tagName: 'div',
        type: 'page',
        attributes: {
            class: prefix + '_sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        components: [
            {
                tagName: 'div',
                attributes: {
                    class: prefix + '_hero_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_hero_content'
                        },
                        components: [
                            {
                                tagName: 'span',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_eye_brow'
                                },
                                content: 'Автор цифрового контента'
                            },
                            {
                                tagName: 'h1',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_title'
                                },
                                content: name
                            },
                            {
                                tagName: 'p',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_subtitle'
                                },
                                content: 'Как наемся, так хочу быть летчицей. А как голодная, так поварихой!'
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                layerable: false,
                                attributes: {
                                    class: prefix + '_content_action_items'
                                },
                                components: [
                                    {
                                        type: 'Main Submits',
                                        tagName: 'div',
                                        attributes: {
                                            class: prefix + '_action_items'
                                        },
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        layerable: false,
                                        content: `
                                            <button class="${prefix}_item main__subscribe_item" id='album__btn-add_friends'>
                                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z" fill="white"/>
                                                    <path d="M18 17.5C18 19.9853 18 22 10 22C2 22 2 19.9853 2 17.5C2 15.0147 5.58172 13 10 13C14.4183 13 18 15.0147 18 17.5Z" fill="white"/>
                                                    <circle cx="17" cy="17" r="5" fill="#0068AD"/>
                                                    <path d="M17 19L17 15M15 17H19" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                                </svg>
                                                <span class="main__item-title">Добавить в друзья</span>
                                            </button>
                                            <div>
                                                <button class="${prefix}_item ${prefix}_nav_menu" id='album__btn-show-nav-menu' data-toggle="dropdown" aria-expanded="false">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        `
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_hero_images flex-1'
                        },
                        components: [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_hero_bg'
                                }
                            },
                            {
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    class: prefix + '_hero_img ' + prefix + '_hero_img_1',
                                    src: 'https://www.fotor.com/blog/wp-content/uploads/2017/09/photo-composition-e1597393995467-797x1024.jpg'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_circles circle_1'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_circles circle_2'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_circles circle_3'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_hero_vector',
                                },
                                content: `
                                    <svg width="48" height="184" viewBox="0 0 48 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.5807 182.963C11.034 171.699 0.077672 157.194 3.69051 152.636C8.85678 146.114 38.8569 166.197 45.0854 159.332C54.9682 148.444 -6.15767 82.0969 1.94762 73.9225C6.79541 69.0314 30.7666 90.733 39.1417 84.6697C48.0961 78.1897 34.5342 43.3964 13.2674 0.852885" stroke="#0099FF" stroke-width="2" stroke-miterlimit="10"/>
                                    </svg>

                                `
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_hero_state_subscribers',
                                },
                                content : `
                                    <img src="https://www.fotor.com/blog/wp-content/uploads/2017/09/photo-composition-e1597393995467-797x1024.jpg" alt="Cофья смирнова"/>
                                    <div>
                                        <h5>Самый популярный пост</h5>
                                        <p>Ну всё: закрыла я в эти выхи свой дачный сезон, увезла с дачи обратно в Москву своё барахло и три мешка сушеных грибов, и чо хочу щас рассказать-то.</p>
                                    </div>
                                `
                            },
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_hero_last_visit',
                                },
                                content: `
                                    <span>Последний визит</span>
                                    <span class="time">Сегодня 21:37</span>
                                `
                            }
                        ]
                    }
                ]
            },
            {
                type: 'Main Submits',
                tagName: 'div',
                attributes: {
                    class: prefix + '_locals-page-menu'
                },
                layerable: false,
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                components: [
                    {
                        type: 'business_local_menu',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                ]
            },
            {
                tagName: 'main',
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        type: 'one_column',
                        components: [
                            {
                                type: 'about_me'
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'personal_data'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'photo_video'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'friends'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'career'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: 'feed_post_list',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        editable: false,
                        hoverable: false,
                    }
                ]
            }
        ],
        styles: `
            body {
                background-color: #F3F5F5; 
            } 
            .${prefix}_sample__container {
                margin: 0 170px 0 170px;
            }
            .${prefix}_hero_container {
                box-shadow: 0px 8px 16px -2px #0000000A;
                box-shadow: 0px 4px 8px -1px #0000000A;
                box-shadow: 0px 2px 4px -1px #0000000A;
                box-shadow: 0px 1px 2px 0px #0000000A;
                box-shadow: 0px 0px 0px 1px #00000008;
                border-radius: 20px;
                height: 100%;
                background: white;
                margin: 25px 17px 0;
                font-family: sans-serif;
                color: black;
                padding: 110px 0;
                display: flex;
                justify-content: space-between;
            }
            .${prefix}_hero_images {
                margin-right: 107px;
                height: 100%;
                position: relative;
            }
            .${prefix}_hero_bg {
                border-radius: 10px;
                background: #008DEB;
                width: 350px;
                 width: 405px;
                height: 490px;
                top: 2%;
                left: 8%;
                position: absolute;
            }
            .${prefix}_hero_img_1 {
                object-fit: cover;
                width: 405px;
                height: 490px;
                left: 5%;
                top: 0;
                position: relative;
                border-radius: 10px;
            }
            .${prefix}_hero_content {
                max-width: 405px;
                margin-left: 110px;
            } 
            .${prefix}_hero_content > .${prefix}_content_avatar {
                border-radius: 50%;
            } 
            .${prefix}_hero_content > .${prefix}_content_title {
               font-size: 50px;
               font-weight: 600;
               letter-spacing: -1px;
               padding: 10px 0px 6px 0px;
            }
             .${prefix}_hero_content > .${prefix}_content_subtitle {
                font-size: 21px;
                font-weight: 400;
                padding: 13px 0 27px 0;
                max-width: 400px;
                color: #0000008A;
             }
             .${prefix}_action_items {
                display: flex;
                position: relative;
             } 
             .${prefix}_nav_menu {
                padding: 5px 15px;
                margin-left: 10px;
                border: none;
                background: none;
                height: 100%;
                border-radius: 10px;
                transition: all .2s ease;
             }
             .${prefix}_nav_menu:hover {
                background: white;
             }
             .main__subscribe_item {
                cursor: pointer;
                border-radius: 10px;
                border: none;
                color: white;
                background: #0099ff;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                align-items: center;
                padding: 12px 16px;
            }
            .${prefix}_locals-page-menu {
                display: flex;
                justify-content: center;
                margin: 16px 5rem 16px;
            }
         
            .${prefix}_body__content {
                padding-bottom: 55px;
                padding: 0 20px;
            }
            .${prefix}_circles {
                border-radius: 50%;
                position: absolute;
            }
            .circle_1 {
                width: 144px;
                height: 144px;
                background-blend-mode: color;
                background: rgb(196 231 255 / 95%);
                top: -10%;
                right: -20%;
                filter: blur(1px);
            }
            .circle_2 {
                 width: 55px;
                height: 55px;
                background: #2EABFF;
                top: -10%;
                right: -20%;
            }
            .circle_3 {
                 width: 88px;
                height: 88px;
                background: #C4E7FF;
                bottom: -8%;
                left: -8%;
            }
            .${prefix}_hero_vector {
                position: absolute;  
                left: -4%;
                top: 20%;
            }
            .${prefix}_hero_state_subscribers {
                padding: 18px 20px;
                position: absolute;
                box-shadow: 0px 4px 8px -1px #414E620D;
                box-shadow: 0px 2px 4px -1px #414E620D;
                box-shadow: 0px 1px 2px 0px #414E620D;
                box-shadow: 0px 0px 0px 1px #414E620F;
                background: #F1F3F4B8;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                border-radius: 10px;
                bottom: -70px;
                right: -60px;
                width: 350px;
            }
            .${prefix}_hero_state_subscribers > img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
            }
            .${prefix}_hero_state_subscribers > div {
                margin-left: 16px;
                width: 450px;
            }
            .${prefix}_hero_state_subscribers > div > h5 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
            .${prefix}_hero_state_subscribers > div > p {
                font-size: 12px;
                color: ##0000008A;
                font-weight: 400;
                line-height: 16px;
                margin: 0;
            }
            .${prefix}_hero_last_visit {
                position: absolute;
                top: -35px;
                left: 25%;
                color: #0000008A;
                display: flex;
                flex-direction: column;
                font-size: 12px;
                align-items: center;
            }
            .${prefix}_hero_last_visit > .time {
                padding: 4px 16px;
                border-radius: 15px;
                font-size: 16px;
                font-weight: 400;
                background: #F1F2F4;
                padding: 4px 16px;
                margin-top: 5px;
            }
            .${prefix}_cards_list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
                margin: 18px 0;
            }
        `
    };
};
