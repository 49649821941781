export const TextBlock = ({editor}) => {
    const prefix = 'text_block';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 28C8 25.7909 9.79086 24 12 24H76C78.2091 24 80 25.7909 80 28V60C80 62.2091 78.2091 64 76 64H12C9.79086 64 8 62.2091 8 60V28Z" fill="#0099FF"/>
                    <path d="M18 42H42" stroke="#F4F5F6" stroke-width="4" stroke-linecap="round"/>
                    <path d="M18 49H42" stroke="#F4F5F6" stroke-width="4" stroke-linecap="round"/>
                    <path d="M17 55H33" stroke="#F4F5F6" stroke-width="2" stroke-linecap="round"/>
                    <circle cx="18.5" cy="34.5" r="2.5" fill="#F4F5F6"/>
                </svg>`,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: 'Текстовый блог'},
        label: 'Текстовый блок'
    });

    function script() {

    }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=container],[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_card'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_text_content'
                        },
                        components: [
                            {
                                tagName: 'h4',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                type: 'text',
                                name: 'Заглавие',
                                content: 'А в этих пунктах раскройте подробности'
                            },
                            {
                                tagName: 'p',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                type: 'text',
                                name: 'Текст',
                                content: 'Какую выгоду вы приносите своим клиентам? Почему потенциальный клиент должен выбрать вас, а не конкурентов?'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                attributes: {
                                    href: '#'
                                },
                                name: 'Ссылка',
                                content: 'Подробнее'
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_card {
                        position: relative;
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                        min-height: 180px;
                        display: table;
                    }
                     .${prefix}_text_content {
                        border-radius: 10px;
                        position: relative;
                        z-index: 1;
                     }
                     .${prefix}_text_content > h4 {
                        color: ##000000DE;
                        font-size: 21px;
                        font-weight: 500;
                     }
                     .${prefix}_text_content > p {
                        color: #0000008A;
                        font-size: 16px;
                        font-weight: 400;
                     }
                    
                `
            }
        }
    });
};
