import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class settings_modal extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

    }

}
