export const profiles = () => {

    const prefix = 'profiles';

    function script() {

        const date = new Date();
        const day_name = date.toLocaleString('default', {dateStyle: 'full'});

        document.querySelector('.header__require_content h2').textContent = `${day_name}`;
    }
    return {
        script,
        tagName: 'div',
        type: 'page',
        attributes: {
            class: 'sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        components: [
            {
                type: 'header',
                tagName: 'div',

                attributes: {
                    class: 'header__require_content',
                },
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                components: [
                    {
                        type: 'date',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        editable: false,
                        tagName: 'h2',
                    }
                ]
            },
            {
                tagName: 'main',
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'last_subscribes_post'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'marketplace'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'last_photo'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'purse'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'popular_posts'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'organizer'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'media'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'communities'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'referendum'
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                type: 'feed_post_list',
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                editable: false,
                hoverable: false,
            }
        ],
        styles: `
            body {
                background-color: #F3F5F5; 
            } 
            .sample__container {
                height: 100%
            }
            .header__require_content {
                width: 100%;
                min-height: 100px;
                background-color: #0099ff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 25px;
            }
            .header__require_content > h2 {
                background: transparent;
                color: white;
            }
            .${prefix}_body__content {
                margin: 24px 5rem;
            }
            .album_card {
                background-color: white;
                min-height: 400px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
             .${prefix}_card {
                background: #f3f5f5;
                border-radius: 10px;
                padding: 20px 32px;
                width: 100%;
                height: 100%;
            }
            .${prefix}_cards_list {
                min-height: 400px;
                gap: 1rem;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 0 17px
            }
        `,
    };
};