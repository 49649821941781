export const ContactsWidget = ({editor}) => {
    const prefix = 'contacts';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z" fill="#99D6FF"/>
                    <path d="M21.024 22.5601C21.5166 22.363 21.763 22.2645 21.9562 22.095C22.0055 22.0518 22.0518 22.0055 22.095 21.9562C22.2645 21.763 22.363 21.5166 22.5601 21.024C23.484 18.7142 23.946 17.5593 23.4977 16.8996C23.3914 16.7432 23.2565 16.6083 23.1001 16.5021C22.4405 16.0538 21.2856 16.5158 18.9758 17.4397C18.4831 17.6367 18.2368 17.7353 18.0435 17.9047C17.9943 17.9479 17.9479 17.9943 17.9047 18.0435C17.7353 18.2368 17.6367 18.4831 17.4397 18.9758C16.5158 21.2856 16.0538 22.4405 16.5021 23.1001C16.6083 23.2565 16.7432 23.3914 16.8996 23.4977C17.5593 23.946 18.7142 23.484 21.024 22.5601Z" fill="#008DEB"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Контакты'
    });

    function script() {

    }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_container_bg',
                        },
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_container_icon position-relative'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                   <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                    <path d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z" fill="#99D6FF"/>
                                    <path d="M21.024 22.5601C21.5166 22.363 21.763 22.2645 21.9562 22.095C22.0055 22.0518 22.0518 22.0055 22.095 21.9562C22.2645 21.763 22.363 21.5166 22.5601 21.024C23.484 18.7142 23.946 17.5593 23.4977 16.8996C23.3914 16.7432 23.2565 16.6083 23.1001 16.5021C22.4405 16.0538 21.2856 16.5158 18.9758 17.4397C18.4831 17.6367 18.2368 17.7353 18.0435 17.9047C17.9943 17.9479 17.9479 17.9943 17.9047 18.0435C17.7353 18.2368 17.6367 18.4831 17.4397 18.9758C16.5158 21.2856 16.0538 22.4405 16.5021 23.1001C16.6083 23.2565 16.7432 23.3914 16.8996 23.4977C17.5593 23.946 18.7142 23.484 21.024 22.5601Z" fill="#008DEB"/>
                                </svg>
                                `
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_container_content position-relative'
                        },
                        components: [
                            {
                                tagName: 'h5',
                                content: '+7-495-000-00-00',
                            },
                            {
                                tagName: 'ul',
                                components: [
                                    {
                                        tagName: 'li',
                                        content: 'Выше наберите свой номер телефона,'
                                    },
                                    {
                                        tagName: 'li',
                                        content: 'А в этом списке укажите адрес и время работы.'
                                    },
                                    {
                                        tagName: 'li',
                                        content: 'Замените фоновон изображение'
                                    }
                                ]
                            }
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                       
                        color: white;
                     }
                     .${prefix}_container_bg {
                        border-radius: 10px;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url('https://veragold.ru/assets/cache_image/products/587/1_(2)_340x340_c92.jpg');
                        background-position: center;
                        background-repeat: no-repeat;
                        position: absolute;
                        filter: brightness(65%);
                        background-size: cover;
                     }
                     .${prefix}_container_content > ul {
                        padding-left: 20px;
                        font-size: 16px;
                        font-weight: 400;
                        color: hsla(204, 11%, 99%, 1);
                     }
                     
                     .${prefix}_container_content > h5 {
                        padding: 30px 0px 2px 0px;
                        margin-top: 8px;
                        font-size: 28px;
                        font-weight: 500;
                     }
                `
            }
        }
    });
};
