import {bindingHandlers} from 'knockout';

bindingHandlers.carousel = {
    init: function(element, valueAccessor) {

        if (element.querySelector(`.${valueAccessor().container}`)) {

            const nextBtn = element.querySelector('.next-btn');
            const prevBtn = element.querySelector('.prev-btn');
            const nextPoll = element.querySelector('.next-poll');
            const prevPoll = element.querySelector('.prev-poll');

            const getGap = (container) => {
                // получение размера gap между элементами
                const computedStyle = getComputedStyle(container);
                return parseInt(computedStyle.gap || computedStyle.rowGap);
            };

            const scrollLeft = (e) => {
                if (e.detail === 1) {
                    const carousel = element.querySelector(`.${valueAccessor().container}`);
                    let item_width = carousel.querySelectorAll(`.${valueAccessor().itemClass}`)[1].clientWidth + getGap(carousel);
                    carousel.scrollLeft += item_width;
                }
            };
            const scrollRight = (e) => {
                if (e.detail === 1) {
                    const carousel = element.querySelector(`.${valueAccessor().container}`);
                    let item_width = carousel.querySelectorAll(`.${valueAccessor().itemClass}`)[1].clientWidth + getGap(carousel);
                    carousel.scrollLeft -= item_width;
                }
            };

            const scrollLeftPoll = (e) => {
                if (e.detail === 1) {
                    document.querySelectorAll(`.${valueAccessor().container}`).forEach(item => item.scrollLeft +=  document.querySelector(`.${valueAccessor().itemClass}`).clientWidth);
                }
            };
            const scrollRightPoll = (e) => {
                if (e.detail === 1) {
                    document.querySelectorAll(`.${valueAccessor().container}`).forEach(item => item.scrollLeft -=  document.querySelector(`.${valueAccessor().itemClass}`).clientWidth);
                }
            };

            const onWheel = (e) => {
                e.target.scrollLeft += e.deltaX;
            };

            if (nextBtn || prevBtn) {
                nextBtn.addEventListener('click', scrollLeft);
                prevBtn.addEventListener('click', scrollRight);
            }
            if (nextPoll || prevPoll) {
                nextPoll.addEventListener('click', scrollLeftPoll);
                prevPoll.addEventListener('click', scrollRightPoll);
            }


            element.addEventListener('wheel', onWheel);

            new ResizeObserver(() => {
                const carousel = element.querySelector(`.${valueAccessor().container}`);
                if (nextBtn || prevBtn) {
                    element.querySelector('.next-btn').style.display =
                        carousel.scrollWidth === carousel.clientWidth ? 'none' : 'flex';
                    element.querySelector('.prev-btn').style.display =
                        carousel.scrollWidth === carousel.clientWidth ? 'none' : 'flex';
                }
            }).observe(element);

            return { controlsDescendantBindings: true };
        }
    }
};
