import ko from 'knockout';
import 'knockout-mapping';
import Handler from 'engine/Handler';
import 'binding/include';
import {Constructor} from "../../assets/js/Constructor";
import {profiles} from "../../assets/js/samples";

import CKEDITOR from './../../assets/js/ckeditor.min';

import './../../assets/css/grapes.min.css';
import './../../assets/css/styles.css';
import './../../assets/css/edit_post.css';
import './../../assets/css/edit_image.css';
import './../../assets/css/default_grapes_styles.css';

export default class profile extends Handler {
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.editor = ko.observable(null);
        this.isShowLeftMenu = ko.observable(false);
        this.isShowRightMenu = ko.observable(false);
        this.isShowNotification = ko.observable(false);
        this.base_loader = ko.observable(true);
        this.type = ko.observable('profile');
        this.constructorInto = ko.observable(null);

        this.lang = ko.observable(Store.getState().lang.value);

        this.isShowPostEditor = ko.observable(false);

        this.isShowConstructorMenu = ko.observable(false);
        this.tab = ko.observable('property');

        if(Store.getState().auth.value){
            this.getSearchPage();
        }

        this.Store.subscribe(() => {
            if (this.Store.getState().handler.name === 'post-editor') {
                this.Store.dispatch({type: 'handler/set', payload: {
                        name: 'initial'
                    }});
                this.isShowPostEditor(true);
            } else {
                // this.editor().I18n.setLocale(this.Store.getState().lang.value);
            }
        });

        this.postEditorInitial();
    }

    postEditorInitial() {
        CKEDITOR.ClassicEditor.create(document.getElementById("post-editor"), {
            // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
            toolbar: {
                items: [
                    'exportPDF','exportWord', '|',
                    'findAndReplace', 'selectAll', '|',
                    'heading', '|',
                    'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
                    'bulletedList', 'numberedList', 'todoList', '|',
                    'outdent', 'indent', '|',
                    'undo', 'redo',
                    '-',
                    'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                    'alignment', '|',
                    'link', 'insertImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
                    'specialCharacters', 'horizontalLine', 'pageBreak', '|',
                    'textPartLanguage', '|',
                    'sourceEditing'
                ],
                shouldNotGroupWhenFull: true
            },
            // Changing the language of the interface requires loading the language file using the <script> tag.
            // language: 'es',
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true
                }
            },
            // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
            placeholder: 'О чем будет пост?',
            // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
            fontFamily: {
                options: [
                    'default',
                    'Arial, Helvetica, sans-serif',
                    'Courier New, Courier, monospace',
                    'Georgia, serif',
                    'Lucida Sans Unicode, Lucida Grande, sans-serif',
                    'Tahoma, Geneva, sans-serif',
                    'Times New Roman, Times, serif',
                    'Trebuchet MS, Helvetica, sans-serif',
                    'Verdana, Geneva, sans-serif'
                ],
                supportAllValues: true
            },
            // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
            fontSize: {
                options: [ 10, 12, 14, 'default', 18, 20, 22 ],
                supportAllValues: true
            },
            // Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
            // https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        attributes: true,
                        classes: true,
                        styles: true
                    }
                ]
            },
            // Be careful with enabling previews
            // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
            htmlEmbed: {
                showPreviews: true
            },
            // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
            link: {
                decorators: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    toggleDownloadable: {
                        mode: 'manual',
                        label: 'Downloadable',
                        attributes: {
                            download: 'file'
                        }
                    }
                }
            },
            // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
            mention: {
                feeds: [
                    {
                        marker: '@',
                        feed: [
                            '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                            '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                            '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                            '@sugar', '@sweet', '@topping', '@wafer'
                        ],
                        minimumCharacters: 1
                    }
                ]
            },
            // The "super-build" contains more premium features that require additional configuration, disable them below.
            removePlugins: [
                // These two are commercial, but you can try them out without registering to a trial.
                // 'ExportPdf',
                // 'ExportWord',
                'CKBox',
                'CKFinder',
                'EasyImage',
                // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
                // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
                // Storing images as Base64 is usually a very bad idea.
                // Replace it on production website with other solutions:
                // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
                // 'Base64UploadAdapter',
                'RealTimeCollaborativeComments',
                'RealTimeCollaborativeTrackChanges',
                'RealTimeCollaborativeRevisionHistory',
                'PresenceList',
                'Comments',
                'TrackChanges',
                'TrackChangesData',
                'RevisionHistory',
                'Pagination',
                'WProofreader',
                // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
                // from a local file system (file://) - load this site via HTTP server if you enable MathType
                'MathType'
            ]
        });
    }

    closeEditor(data, e) {
        if (this.isShowPostEditor() && !e.target.closest('#post-editor-container')) {
            this.isShowPostEditor(false);
        }
    }

    createProfileConstructor() {
        this.editor().store();
        this.editor().on('storage:store', (data) => {
            let instance = JSON.stringify(data);

            this.Server.Request('save_page', {
                name: '',
                type: 'profile'
            })
                .then(res=>{
                    let result = JSON.parse(res);
                    if (result.success) {
                        this.updateConstructor(result.constructor.id, {});
                    }
                }).catch((error)=> { console.log(error); });
        });
    }

    updateConstructor(id, param) {
        this.Server.Request('add_page', {id, ...param})
            .then(res=>{
                let result = JSON.parse(res);
                console.log(result)
            })
            .catch((error)=> { console.log(error); });
    }

    closeConstructor() {
        this.isShowConstructorMenu(false);
    }

    openConstructor() {
        this.tab('property');
        this.isShowConstructorMenu(true);
    }

    constructorInstance(params, type) {

        this.type(type);

        params.type = type;

        new Constructor(params).instance().then(editor => {

            this.editor(editor);

            editor.addComponents(profiles(params));

        });
    }

    getCompanyProfile() {
        this.Server.Request('company_profile')
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success) {
                    console.log('%c Your company data: ', 'background: #222; color: #bada55',result);

                    this.constructorInstance(result.profile, 'profile');
                }
            }).catch(error=>console.log(error));
    }

    getUserProfile() {
        this.Server.Request('user_profile')
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success) {
                   // console.log('%c Your user data: ', 'background: #222; color: #bada55',result);

                    this.constructorInstance(result.profile, 'profile');
                }
            }).catch(()=>this.getCompanyProfile());
    }

    closeMenu() {
        this.isShowLeftMenu(false);
        this.isShowRightMenu(false);
        this.isShowNotification(false);
    }

    getSearchPage() {
        this.Server.Request('find_page', {
            type: 'profile'
        })
            .then(res=>{
                let result = JSON.parse(res);

                this.base_loader(false);

                this.getUserProfile();

                this.constructorInto(result.constructor);
            }).catch((e)=>{ console.log(e); });
    }
}
