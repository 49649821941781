import ko from 'knockout';
import Handler from 'engine/Handler';
import GLightbox from "glightbox";
//import 'knockout-mapping';
export default class group_chat extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.messages = Array.from([
            {
                message: 'Привет',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: true,
                action: 'incoming',
                name: 'vasia',
                date: '14:30',
            },
            {
                message: 'Текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: false,
                action: 'incoming',
            },
            {
                message: 'Текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: true,
                action: 'outgoing',
                name: 'dima',
                date: '12:30',
            },
            {
                message: 'Содержательный текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'unread',
                isChecked: false,
                isFirstMessage: false,
                action: 'outgoing'
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [], recording: [],
                    image: ['https://static-cse.canva.com/blob/847064/29.jpg']
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing'
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [
                        'https://cdn.pixabay.com/video/2022/12/10/142376-779987436_large.mp4',
                        'https://cdn.pixabay.com/video/2022/12/10/142376-779987436_large.mp4',
                    ],
                    image: [], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing'
            },
            {
                message: 'bla bla bla',
                isFirstMessage: false,
                attachments: {
                    image: [
                        'https://static-cse.canva.com/blob/847064/29.jpg',
                        'https://static-cse.canva.com/blob/847064/29.jpg',
                        'https://static-cse.canva.com/blob/847064/29.jpg',
                    ],
                    video: [], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing'
            },
            {
                message: 'На Планете 3 природа – это уникальное произведение искусства. Горы величественны, океаны манят своей бескрайностью.',
                isFirstMessage: false,
                attachments: {
                    video: [],
                    image: [
                        // 'https://static-cse.canva.com/blob/847064/29.jpg',
                        // 'https://static-cse.canva.com/blob/847064/29.jpg',
                        // 'https://static-cse.canva.com/blob/847064/29.jpg',
                        // 'https://static-cse.canva.com/blob/847064/29.jpg',
                        'https://static-cse.canva.com/blob/847064/29.jpg',
                    ], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing'
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [],
                    image: [],
                    recording: ['bla bla']
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing'
            },
        ], message => ko.mapping.fromJS(message));

        this.tabItem = ko.observable('info');
        this.isSearchContainer = ko.observable(false);
        setTimeout(()=>{
            GLightbox({
                touchNavigation: false,
                loop: false,
                autoplayVideos: true
            });
        },0)
    }

    checkMessage(position) {
        let self = this;
        return {
            click: (data, event) => {
                if (event.target.classList.length > 0 && !['action-item', 'message-menu-item','speech-synthesis', 'message-text', 'image','select-item-message', 'image-container', 'speech-play-item', 'message-video-play-item'].includes(event.target.classList[0])) {
                    ko.utils.arrayMap(self.messages, (message, index) => {
                        if (index === position) {
                            message.isChecked(!message.isChecked());
                        }
                        return message;
                    });
                } else {
                    return true;
                }
            }
        };
    }

    resetCheckMessages() {
        ko.utils.arrayMap(this.messages, message => message.isChecked(false));
    }

    checkCountMessage() {
        return ko.utils.arrayFilter(this.messages, (message) => message.isChecked()).length;
    }


    navHandle() {
        return {
            click: (data, event) => {
                if (window.screen.width > 767) return true;

                event.target.closest('.layout-content').querySelector('#dialog-item').classList.toggle('main-shown');
                event.target.closest('.layout-page').querySelector('#main-menu').classList.toggle('d-none');

            }
        };
    }
}
