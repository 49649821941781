import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class main_menu extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        // this.textBtn = ko.observable(this.param.caption);
        this.cameraOn = ko.observable(false);
        this.micOn = ko.observable(false);
        this.muteOn = ko.observable(false);
    }
}
