export const TextBlockWithImage = ({editor}) => {
    const prefix = 'text_block_with_image';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="8" y="24" width="72" height="40" rx="4" fill="#D1EDFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.9426 33.25H61.0574C63.3658 33.25 65.1748 33.25 66.5863 33.4397C68.031 33.634 69.1711 34.0393 70.0659 34.9341C70.9607 35.8289 71.366 36.969 71.5603 38.4137C71.75 39.8252 71.75 41.6342 71.75 43.9426V44.0309C71.75 45.9397 71.75 47.5023 71.6463 48.7745C71.5422 50.0531 71.3287 51.1214 70.8509 52.0087C70.6401 52.4001 70.3812 52.7506 70.0659 53.0659C69.1711 53.9607 68.031 54.366 66.5863 54.5603C65.1748 54.75 63.3658 54.75 61.0574 54.75H60.9426C58.6342 54.75 56.8252 54.75 55.4137 54.5603C53.969 54.366 52.8289 53.9607 51.9341 53.0659C51.1409 52.2726 50.7312 51.2852 50.5134 50.0604C50.2993 48.8573 50.2602 47.3603 50.2521 45.5015C50.25 45.0287 50.25 44.5286 50.25 44.001L50.25 43.9426C50.25 41.6342 50.25 39.8252 50.4397 38.4137C50.634 36.969 51.0393 35.8289 51.9341 34.9341C52.8289 34.0393 53.969 33.634 55.4137 33.4397C56.8252 33.25 58.6342 33.25 60.9426 33.25ZM55.6136 34.9264C54.3352 35.0982 53.5645 35.4251 52.9948 35.9948C52.4251 36.5645 52.0982 37.3352 51.9264 38.6136C51.7516 39.9136 51.75 41.6218 51.75 44C51.75 44.5287 51.75 45.0257 51.7521 45.4949C51.7603 47.369 51.8021 48.7406 51.9902 49.7978C52.1744 50.8333 52.4877 51.4981 52.9948 52.0052C53.5645 52.5749 54.3352 52.9018 55.6136 53.0736C56.9136 53.2484 58.6218 53.25 61 53.25C63.3782 53.25 65.0864 53.2484 66.3864 53.0736C67.6648 52.9018 68.4355 52.5749 69.0052 52.0052C69.2151 51.7953 69.3872 51.5631 69.5302 51.2976C69.8619 50.6816 70.0531 49.8578 70.1513 48.6527C70.2494 47.4482 70.25 45.9459 70.25 44C70.25 41.6218 70.2484 39.9136 70.0736 38.6136C69.9018 37.3352 69.5749 36.5645 69.0052 35.9948C68.4355 35.4251 67.6648 35.0982 66.3864 34.9264C65.0864 34.7516 63.3782 34.75 61 34.75C58.6218 34.75 56.9136 34.7516 55.6136 34.9264Z" fill="#0099FF"/>
                    <path d="M70.2487 45.2804L70.0266 45.2497C67.1828 44.8559 64.5805 46.3343 63.2554 48.5626C61.5459 44.2376 57.0284 41.2881 51.9807 42.0129L51.755 42.0454C51.7501 42.6421 51.75 43.2913 51.75 44C51.75 44.5287 51.75 45.0257 51.7521 45.4949C51.7603 47.369 51.8021 48.7406 51.9902 49.7978C52.1744 50.8333 52.4877 51.4981 52.9948 52.0052C53.5645 52.5749 54.3352 52.9018 55.6136 53.0736C56.9136 53.2484 58.6218 53.25 61 53.25C63.3782 53.25 65.0864 53.2484 66.3864 53.0736C67.6648 52.9018 68.4355 52.5749 69.0052 52.0052C69.2151 51.7953 69.3872 51.5631 69.5302 51.2976C69.8619 50.6816 70.0531 49.8578 70.1513 48.6527C70.2267 47.7267 70.2445 46.6247 70.2487 45.2804Z" fill="#99D6FF"/>
                    <path d="M67 40C67 41.1046 66.1046 42 65 42C63.8954 42 63 41.1046 63 40C63 38.8954 63.8954 38 65 38C66.1046 38 67 38.8954 67 40Z" fill="#0099FF"/>
                    <path d="M18 34H42" stroke="#0099FF" stroke-width="4" stroke-linecap="round"/>
                    <path d="M17 43H41" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                    <path d="M17 47H41" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                    <path d="M17 51H41" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                    <path d="M17 55H33" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                </svg>`,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: 'Текстовый блог c картинкой'},
        label: 'Текстовый блог c картинкой'
    });

    function script() {

    }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=container],[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_card'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_text_content'
                        },
                        components: [
                            {
                                tagName: 'img',
                                attributes: {
                                    src: 'https://www.shutterstock.com/image-photo/empty-meeting-room-office-600nw-2173235165.jpg'
                                }
                            },
                            {
                                tagName: 'h4',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                type: 'text',
                                name: 'Заглавие',
                                content: 'А в этих пунктах раскройте подробности'
                            },
                            {
                                tagName: 'p',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                type: 'text',
                                name: 'Текст',
                                content: 'Какую выгоду вы приносите своим клиентам? Почему потенциальный клиент должен выбрать вас, а не конкурентов?'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                attributes: {
                                    href: '#'
                                },
                                name: 'Ссылка',
                                content: 'Подробнее'
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_card {
                        position: relative;
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                        min-height: 180px;
                        display: table;
                    }
                     .${prefix}_text_content {
                        border-radius: 10px;
                        position: relative;
                        z-index: 1;
                     }
                     .${prefix}_text_content > h4 {
                        color: ##000000DE;
                        font-size: 21px;
                        font-weight: 500;
                     }
                     .${prefix}_text_content > p {
                        color: #0000008A;
                        font-size: 16px;
                        font-weight: 400;
                     }
                      .${prefix}_text_content > img {
                        object-fit: cover;
                        height: 520px;
                        width: 100%;
                        border-radius: 10px;
                     }
                `
            }
        }
    });
};
