import ko from 'knockout';
import 'knockout-mapping';
import Handler from 'engine/Handler';
import 'binding/include';

export default class web_constructors extends Handler {
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.pages = ko.observableArray([]);
        this.toggleActive = ko.observable(false);
        this.base_loader = ko.observable(true);

        this.pageName = ko.observable('');
        this.pageType = ko.observable('shop');
        this.pageIsActive = ko.observable(false);
        this.pageId = ko.observable(0);
        this.errorPageName = ko.observable(false);

        if(Store.getState().auth.value){
            Server.Request('get_all_pages')
                .then(res=>{
                    let result = JSON.parse(res);
                    if (result.success) {

                        result.constructors.map(page => {
                            this.pages.push(ko.mapping.fromJS(page));
                        });

                        setTimeout(() => {
                            this.base_loader(false);
                        }, 600);
                    }
                });
        }
    }

    savePageForm() {
        this.Server.Request('add_page', {
            name: this.pageName(),
            type: this.pageType(),
            id: this.pageId(),
            isActive: this.pageIsActive()
        })
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.pages().map(page => {
                        if (page.id() === this.pageId()) {
                            page.name(this.pageName());
                            page.type(this.pageType());
                            page.isActive(this.pageIsActive());
                        }
                    });
                    this.pageId(0);
                }
            }).catch((error)=>alert(error.message));
    }

    createPage() {
        this.Server.Request('save_page', {
            name: '',
            type: 'universal'
        })
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.pages.push(ko.mapping.fromJS(result.constructor));
                }
            }).catch((error)=>  alert(error.message) );
    }

    closeModalWindow(data, e) {
        if (this.pageId() > 0 && !e.target.closest('.success_block')) this.pageId(0);
    }

    eventField() {
        return {
            input: (data, event) => {
                if (event.target.value.length > 0) {
                    event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g,'');

                    this.debounce(300, () => {
                        this.Server.Request('find_page', {
                            name: this.pageName()
                        })
                            .then(res=>{
                                let result = JSON.parse(res);

                                this.errorPageName(result.success ? this.pageId() !== result.constructor.id : false);

                            }).catch(error=> alert(error.message));
                    });
                } else {
                    this.errorPageName(false);
                }
            }
        };
    }

    removePage(id) {
        this.Server.Request('remove_page', {id})
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.pages.remove(page => page.id() === id);
                }
            }).catch((error)=>  alert(error.message) );
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    showEditForm(id) {
        const page = this.pages().find(page => page.id() === id);

        this.pageName(page.name());
        this.pageId(page.id());
        this.pageIsActive(page.isActive());
        this.pageType(page.type() !== null ? page.type() : 'shop');
    }
}