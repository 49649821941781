export const ColumnTwoOne = ({editor}) => {
    const prefix = 'column_two_one';
    editor.Blocks.add(prefix, {
        media: `
            <div style="width: 65px; height: 65px;display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                <div style="padding: 24px 5px; width: 30px; height: 40px;background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 5px;"></div>
                <div style="padding: 24px 5px; width: 1px; height: 40px;background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 5px;"></div>
            </div> 
        `,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: '2 колонки'},
        label: '2/3 + 1/3'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=container]',
                attributes: {
                    class: prefix
                },
                components: [
                    {
                        type: 'one_column',
                        attributes: {
                            class: prefix + '_left_column'
                        },
                    },
                    {
                        type: 'one_column',
                        attributes: {
                            class: prefix + '_right_column'
                        },
                    }
                ],
                styles: `
                    .${prefix} {
                         border-radius: 10px;
                         background: white;
                         display: table;
                         height: 100%;
                         width: 100%;
                         min-height: 400px;
                         background: #f3f5f5;
                         border-spacing: 1rem;
                    } 
                    .${prefix} > div {
                        position: relative;
                        display: table-cell;
                        border-radius: 10px;
                        background: white;
                        padding: 10px;
                        height: 100%;
                    }
                    .${prefix}_left_column {
                        width: 70%;
                    }
                    .${prefix}_right_column {
                        width: 30%;
                    }
                `
            }
        }
    });
};
