export const TitleElement = ({editor}) => {
    const prefix = 'title_element';

    editor.Blocks.add(prefix, {
        media: `
            <svg width="45" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_15331_7142)">
                <path d="M34.4692 51L33.1157 47.0728H27.4761L26.1123 51H23.7539L29.0962 36.2036H31.5674L36.9097 51H34.4692ZM30.2139 38.8901L28.0605 45.145H32.5312L30.3882 38.8901H30.2139Z" fill="#0099FF"/>
                <path d="M45.5742 51H39.4937V36.2036H45.4409C48.2197 36.2036 49.8911 37.6187 49.8911 39.895C49.8911 41.4434 48.7529 42.8276 47.2969 43.0737V43.248C49.2964 43.4531 50.6294 44.8784 50.6294 46.8062C50.6294 49.4106 48.7324 51 45.5742 51ZM41.7905 38.1519V42.4688H44.5181C46.5483 42.4688 47.6045 41.7202 47.6045 40.3154C47.6045 38.9517 46.6099 38.1519 44.9077 38.1519H41.7905ZM41.7905 49.0518H45.1128C47.1841 49.0518 48.2812 48.2109 48.2812 46.6421C48.2812 45.0938 47.1431 44.2837 44.9795 44.2837H41.7905V49.0518Z" fill="#0099FF"/>
                <path d="M59.6528 51.3589C55.541 51.3589 52.9775 48.3955 52.9775 43.6069C52.9775 38.8081 55.5308 35.8447 59.6426 35.8447C62.9033 35.8447 65.4258 37.957 65.7949 40.9717H63.5083C63.0776 39.1567 61.5908 37.9878 59.6426 37.9878C56.9868 37.9878 55.3257 40.1514 55.3257 43.6069C55.3257 47.0522 56.9868 49.2158 59.6528 49.2158C61.6113 49.2158 63.0981 48.1597 63.5083 46.4883H65.7949C65.3848 49.4106 62.9546 51.3589 59.6528 51.3589Z" fill="#0099FF"/>
                </g>
                <defs>
                <filter id="filter0_i_15331_7142" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_innerShadow_15331_7142"/>
                <feOffset/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_15331_7142"/>
                </filter>
                </defs>
            </svg> 
        `,
        prefix,
        category: 'Elements',
        select: true,
        content: {type: prefix, name: 'Title'},
        label: 'Title'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                name: 'Заголовок',
                draggable: '[data-gjs-type=container],[data-gjs-type=one_column]',
                components: [
                    {
                        tagName: 'h2',
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        type: 'text',
                        attributes: {
                            class: prefix
                        },
                        content: 'Впишите текст для заголовка',
                    }
                ],
                styles: `
                    .${prefix} {
                        font-size: 21px;
                        font-weight: 500;
                        padding: 13px 0 3px 0;
                        margin-bottom: 10px;
                    }
                `
            }
        }
    });
};
