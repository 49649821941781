export const Net = ({editor}) => {
    const prefix = 'net_column';

    editor.Blocks.add(prefix, {
        media: `
            <div style="width: 65px; height: 65px;display: grid; grid-template-columns: repeat(2, 1fr);padding: 5px;gap: .5rem;">
                <div style="background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 3px;"></div>
                <div style="background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 3px;"></div>
                <div style="background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 3px;"></div>
                <div style="background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 3px;"></div>
            </div> 
        `,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: 'Сетка'},
        label: 'Сетка'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=container],[data-gjs-type=one_column]',
                attributes: {
                    class: prefix
                },
                components: [
                    {
                        type: 'one_column',
                    },
                    {
                        type: 'one_column',
                    },
                    {
                        type: 'one_column',
                    },
                    {
                        type: 'one_column',
                    }
                ],
                styles: `
                    .${prefix} {
                         padding: 0 17px;
                         border-radius: 10px;
                         height: 100%;
                         display: grid;
                         grid-template-columns: repeat(2, 1fr);
                         width: 100%;
                         min-height: 400px;
                         background: #f3f5f5;
                         gap: 1rem;
                    } 
                    .${prefix} > div {
                        position: relative;
                        border-radius: 5px;
                        background: white;
                        height: 100%;
                        width: 100%;
                        display: table-cell;
                    }
                `
            }
        }
    });
};
