export const VideoTheDayWidget = ({editor}) => {
    const prefix = 'video_the_day';

    editor.Blocks.add(prefix, {
        media: `  <svg width="65" height="65" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 18.05C10.6667 15.2025 10.6667 13.7788 11.2296 12.6951C11.704 11.7819 12.4486 11.0373 13.3618 10.5629C14.4455 10 15.8692 10 18.7167 10H22.6167C25.4641 10 26.8879 10 27.9716 10.5629C28.8848 11.0373 29.6294 11.7819 30.1038 12.6951C30.6667 13.7788 30.6667 15.2025 30.6667 18.05V21.95C30.6667 24.7975 30.6667 26.2212 30.1038 27.3049C29.6294 28.2181 28.8848 28.9627 27.9716 29.4371C26.8879 30 25.4641 30 22.6167 30H18.7167C15.8692 30 14.4455 30 13.3618 29.4371C12.4486 28.9627 11.704 28.2181 11.2296 27.3049C10.6667 26.2212 10.6667 24.7975 10.6667 21.95V18.05Z" fill="#99D6FF"/>
                    <path d="M14.6667 29.8447C14.1596 29.7614 13.74 29.6335 13.3618 29.4371C12.4486 28.9627 11.704 28.2181 11.2296 27.3049C11.0332 26.9267 10.9053 26.5071 10.822 26H14.6667V29.8447Z" fill="#008DEB"/>
                    <path d="M18.7167 30H19.6667V26H16.6667V29.9883C17.2573 30 17.9331 30 18.7167 30Z" fill="#008DEB"/>
                    <path d="M21.6667 30H22.6167C23.4003 30 24.0761 30 24.6667 29.9883V26H21.6667V30Z" fill="#008DEB"/>
                    <path d="M30.5114 26C30.4281 26.5071 30.3002 26.9267 30.1038 27.3049C29.6294 28.2181 28.8848 28.9627 27.9716 29.4371C27.5934 29.6335 27.1738 29.7614 26.6667 29.8447V26H30.5114Z" fill="#008DEB"/>
                    <path d="M26.6667 10.1553C27.1738 10.2386 27.5934 10.3665 27.9716 10.5629C28.8848 11.0373 29.6294 11.7819 30.1038 12.6951C30.3002 13.0733 30.4281 13.4929 30.5114 14H26.6667V10.1553Z" fill="#008DEB"/>
                    <path d="M22.6167 10H21.6667V14H24.6667V10.0117C24.0761 10 23.4003 10 22.6167 10Z" fill="#008DEB"/>
                    <path d="M19.6667 10H18.7167C17.9331 10 17.2573 10 16.6667 10.0117V14H19.6667V10Z" fill="#008DEB"/>
                    <path d="M14.6667 10.1553V14H10.822C10.9053 13.4929 11.0332 13.0733 11.2296 12.6951C11.704 11.7819 12.4486 11.0373 13.3618 10.5629C13.74 10.3665 14.1596 10.2386 14.6667 10.1553Z" fill="#008DEB"/>
                    <path d="M22.6667 20C22.6667 19.4722 22.1371 19.1162 21.0779 18.4043C20.0042 17.6827 19.4673 17.3219 19.067 17.5868C18.6667 17.8517 18.6667 18.5678 18.6667 20C18.6667 21.4322 18.6667 22.1483 19.067 22.4132C19.4673 22.6781 20.0042 22.3173 21.0779 21.5957C22.1371 20.8838 22.6667 20.5278 22.6667 20Z" fill="#008DEB"/>
                  </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Видео дня'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: 'title_section d-flex align-items-center mb-2'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                              <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-3">
                                <path d="M0.666687 20C0.666687 8.95431 9.62099 0 20.6667 0C31.7124 0 40.6667 8.95431 40.6667 20C40.6667 31.0457 31.7124 40 20.6667 40C9.62099 40 0.666687 31.0457 0.666687 20Z" fill="#E0F3FF"/>
                                <path d="M10.6667 18.05C10.6667 15.2025 10.6667 13.7788 11.2296 12.6951C11.704 11.7819 12.4486 11.0373 13.3618 10.5629C14.4455 10 15.8692 10 18.7167 10H22.6167C25.4641 10 26.8879 10 27.9716 10.5629C28.8848 11.0373 29.6294 11.7819 30.1038 12.6951C30.6667 13.7788 30.6667 15.2025 30.6667 18.05V21.95C30.6667 24.7975 30.6667 26.2212 30.1038 27.3049C29.6294 28.2181 28.8848 28.9627 27.9716 29.4371C26.8879 30 25.4641 30 22.6167 30H18.7167C15.8692 30 14.4455 30 13.3618 29.4371C12.4486 28.9627 11.704 28.2181 11.2296 27.3049C10.6667 26.2212 10.6667 24.7975 10.6667 21.95V18.05Z" fill="#99D6FF"/>
                                <path d="M14.6667 29.8447C14.1596 29.7614 13.74 29.6335 13.3618 29.4371C12.4486 28.9627 11.704 28.2181 11.2296 27.3049C11.0332 26.9267 10.9053 26.5071 10.822 26H14.6667V29.8447Z" fill="#008DEB"/>
                                <path d="M18.7167 30H19.6667V26H16.6667V29.9883C17.2573 30 17.9331 30 18.7167 30Z" fill="#008DEB"/>
                                <path d="M21.6667 30H22.6167C23.4003 30 24.0761 30 24.6667 29.9883V26H21.6667V30Z" fill="#008DEB"/>
                                <path d="M30.5114 26C30.4281 26.5071 30.3002 26.9267 30.1038 27.3049C29.6294 28.2181 28.8848 28.9627 27.9716 29.4371C27.5934 29.6335 27.1738 29.7614 26.6667 29.8447V26H30.5114Z" fill="#008DEB"/>
                                <path d="M26.6667 10.1553C27.1738 10.2386 27.5934 10.3665 27.9716 10.5629C28.8848 11.0373 29.6294 11.7819 30.1038 12.6951C30.3002 13.0733 30.4281 13.4929 30.5114 14H26.6667V10.1553Z" fill="#008DEB"/>
                                <path d="M22.6167 10H21.6667V14H24.6667V10.0117C24.0761 10 23.4003 10 22.6167 10Z" fill="#008DEB"/>
                                <path d="M19.6667 10H18.7167C17.9331 10 17.2573 10 16.6667 10.0117V14H19.6667V10Z" fill="#008DEB"/>
                                <path d="M14.6667 10.1553V14H10.822C10.9053 13.4929 11.0332 13.0733 11.2296 12.6951C11.704 11.7819 12.4486 11.0373 13.3618 10.5629C13.74 10.3665 14.1596 10.2386 14.6667 10.1553Z" fill="#008DEB"/>
                                <path d="M22.6667 20C22.6667 19.4722 22.1371 19.1162 21.0779 18.4043C20.0042 17.6827 19.4673 17.3219 19.067 17.5868C18.6667 17.8517 18.6667 18.5678 18.6667 20C18.6667 21.4322 18.6667 22.1483 19.067 22.4132C19.4673 22.6781 20.0042 22.3173 21.0779 21.5957C22.1371 20.8838 22.6667 20.5278 22.6667 20Z" fill="#008DEB"/>
                              </svg>
                              <h5 class="main__item-title m-0">Видео дня</h5>
                        `
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_poster__container'
                        },
                        components: [
                            {
                                tagName: 'img',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    src: 'https://ir.ozone.ru/multimedia/c1000/1025956175.jpg',
                                    class: prefix + '_poster'
                                }
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_poster__play_item'
                                },
                                content: `
                                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.6667 7.26795C17 8.03775 17 9.96225 15.6667 10.7321L3.66669 17.6603C2.33335 18.4301 0.666686 17.4678 0.666686 15.9282L0.666687 2.0718C0.666687 0.532196 2.33335 -0.430055 3.66669 0.339746L15.6667 7.26795Z" fill="white"/>
                                     </svg>
                                `
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                    }
                    .${prefix}_poster {
                        width: 100%;
                        border-radius: 10px;
                        transition: all .3s ease;
                        cursor: pointer;
                        max-height: 300px;
                        object-fit: cover;
                    }
                    .${prefix}_poster__container {
                        position: relative;
                    }
                    .${prefix}_poster:hover {
                        filter: brightness(70%);
                    }
                    .${prefix}_poster__play_item {
                        position: absolute;
                        top: 42%;
                        bottom: 0;
                        left: 45%;
                        height: fit-content;
                        padding: 15px;
                        display: flex;
                        border-radius: 50%;
                        background: #939393bd;
                    }
                    .${prefix}_poster__play_item:hover .${prefix}_poster__play_item {
                        background: #ededed;
                    }
                `
            }
        }
    });
};
