import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class header extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        const toggleButtons = document.querySelectorAll('.toggle-menu');
        const layout = document.querySelector('.layout');

        toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
                layout.classList.toggle('hide_menu');
            });
        });
    }

    logOut() {
        this.Server.Request('remove_device')
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.Router.navigate('/login');
                } else {
                    this.textBtn(result.error);
                }
            }).catch(error=>this.textBtn(error));
    }

}
