import ko from 'knockout';
ko.bindingHandlers.i18nText  = {
    update: function(element, valueAccessor,q, {Router,Server,Store,i18next}) {
        let key = ko.unwrap(valueAccessor());

        if(element.tagName === 'INPUT'){
            element.placeholder = i18next.t(key);
        }else{
            element.innerText = i18next.t(key);
        }

        Store.subscribe(()=>{

            if(element.tagName === 'INPUT'){
                element.placeholder = i18next.t(key);
            }else{
                element.innerText = i18next.t(key);
            }

        });
    }
};
