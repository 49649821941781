export const OrganizerWidget = ({editor}) => {
    const prefix = 'organizer';

    editor.Blocks.add(prefix, {
        media: `<svg width="50" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 3V7M8 3V7M4 11H20M11 15H12V18M6 5H18C19.1046 5 20 5.89543 20 7V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V7C4 5.89543 4.89543 5 6 5Z" stroke="#008DEB" stroke-opacity="0.87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Органайзер'
    });

    // date picker
    const dateList = [
        {
            date: 27,
            shortName: 'Пн'
        },
        {
            date: 28,
            shortName: 'Вт'
        },
        {
            date: 29,
            shortName: 'Ср'
        },
        {
            date: 30,
            shortName: 'Чт'
        },
        {
            date: 1,
            shortName: 'Пт'
        },
        {
            date: 2,
            shortName: 'Сб'
        },
        {
            date: 3,
            shortName: 'Вс'
        },
    ];

    let resultDateList = [];

    dateList.map((item, index) => {
       return resultDateList.push({
            tagName: 'div',
            removable: false,
            draggable: false,
            droppable: false,
            copyable: false,
            selectable: false,
            hoverable: false,
            editable: false,
            attributes: {
                class: prefix + '_date_item',
                'data-data-index': index
            },
            components: [
                {
                    tagName: 'h5',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    hoverable: false,
                    editable: false,
                    content: item.shortName,
                    attributes: {
                        class: prefix + '_short_name'
                    }
                },
                {
                    tagName: 'span',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    hoverable: false,
                    editable: false,
                    content: item.date
                }
            ]
        });
    });

    // todos
    const todos = [
        {
            isActive: false,
            todo: 'Купить батон',
            type: 'Работа',
            time: '10:00',
            isActual: false,
            id: 0
        },
        {
            isActive: false,
            todo: 'Созвон',
            type: 'Дом',
            time: '15:00',
            isActual: true,
            id: 1
        },
    ];

    let activeDate = todos.length - 2;

    let resultTodoList = [];

    let actualTodos = [];

    let notActualTodos = [];

    todos.map((item) => {
        if (!item.isActual) {
            return notActualTodos.push({
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                tagName: 'div',
                attributes: {
                    class: prefix + '_todo-item'
                },
                content: `
                        <div>
                            <input type="checkbox" class="${prefix}_todo_checkbox" id="check-item-${item.id}" name="todo_item"/>
                            <div class="${prefix}_todo_content">
                                <p>${item.todo}</p>
                            </div>
                        </div>
                        <div>
                            <span>&#x2022 ${item.type}</span>
                        </div>
                    `
            });
        } else {
           return actualTodos.push({
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                tagName: 'div',
                attributes: {
                    class: prefix + '_todo-item'
                },
                content: `
                       <div>
                            <input type="checkbox" class="${prefix}_todo_checkbox" id="check-item-${item.id}" name="todo_item"/>
                            <div class="${prefix}_todo_content">
                                <p>${item.todo}</p>
                                <span>${item.time}</span>
                            </div>
                       </div>
                       <div>
                            <span>&#x2022 ${item.type}</span>
                       </div>
                `
            });
        }
    });

    resultTodoList.push({
        tagName: 'div',
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        editable: false,
        attributes: {
            class: prefix + '_todo_position'
        },
        components: [
            {
                tagName: 'span',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    class: prefix + '_todo_position__section'
                },
                content: 'Просрочено',
            },
            {
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                components: [notActualTodos]
            }
        ]
    });
    resultTodoList.push({
        tagName: 'div',
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        editable: false,
        attributes: {
            class: prefix + '_todo_position'
        },
        components: [
            {
                tagName: 'span',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                content: 'Сегодня',
            },
            {
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                tagName: 'div',
                components: [actualTodos]
            }
        ]
    });

    function script() {
        document.querySelector('.organizer_date_item').classList.add('is-active');
        document.querySelectorAll('.organizer_date_item').forEach(item => {
            item.addEventListener('click', () => {
                document.querySelectorAll('.organizer_date_item').forEach(item => {
                    item.classList.remove('is-active');
                });
                item.classList.add('is-active');
            });
        });

        document.querySelectorAll('.organizer_todo_checkbox').forEach(item => {
            item.addEventListener('click', () => {
                if (item.checked) {
                    item.closest('div').querySelector('.organizer_todo_content p').style.textDecoration = 'line-through';
                } else {
                    item.closest('div').querySelector('.organizer_todo_content p').style.textDecoration = 'none';
                }
            });
        });
    }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_date_picker'
                        },
                        components: resultDateList
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        components: [resultTodoList]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_footer_todo'
                        },
                        components: [
                            {
                                tagName: 'button',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_add-item'
                                },
                                content: `
                                     <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3334 5V19M5.33337 12H19.3334" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>
                                    <span>Добавить</span>
                                `
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_more-count'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                    Еще ${activeDate}
                                `
                            }
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-family: sans-serif;
                     }
                     .${prefix}_date_item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        border-radius: 10px;
                        padding: 10px 0;
                        cursor: pointer;
                        transition: all .3s ease;
                     }
                     .${prefix}_date_item.is-active {
                        background: #008DEB;
                        color: white;
                     }
                     .${prefix}_date_item > .${prefix}_short_name {
                        margin: 0 0 5px 0; 
                     } 
                     .${prefix}_date_picker {
                        display: flex;
                        justify-content: space-between;
                        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -25px, rgba(0, 0, 0, 0.3) 0px 30px 60px -40px;
                     }
                     .${prefix}_footer_todo {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                     }
                     .${prefix}_add-item {
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 10px 20px;
                        border-radius: 5px;
                     }
                     .${prefix}_add-item:hover {
                         background: hsl(0, 0%, 92%);
                     }
                     .${prefix}_add-item > span {
                        margin-left: 8px;
                        font-weight: 600;
                        color: #0000008A;
                     }
                     .${prefix}_more-count {
                        color: #008DEB;
                        cursor: pointer;
                     }
                     input[type=checkbox] {
                        position: relative;
                        cursor: pointer; 
                     }
                     input[type=checkbox]:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 0;
                        left: 0;
                        background-color:#e9e9e9;
                        border-radius: 3px;
                     }
                     input[type=checkbox]:checked:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 0;
                        left: 0;
                        background-color:#1E80EF;
                     }
                     input[type=checkbox]:checked:after {
                         content: "";
                         display: block;
                         width: 5px;
                         height: 10px;
                         border: solid white;
                         border-width: 0 2px 2px 0;
                         -webkit-transform: rotate(45deg);
                         -ms-transform: rotate(45deg);
                         transform: rotate(45deg);
                         position: absolute;
                         top: 2px;
                         left: 6px;
                     }
                     .${prefix}_todo-item {
                        display: flex;
                        align-items: top;
                        justify-content: space-between;
                        margin-top: 10px;
                     }
                     .${prefix}_todo-item > div {
                        display: flex;
                        align-items: top;
                        flex-direction: row;
                        align-items: flex-start;
                     }
                     .${prefix}_todo_content {
                        margin-top: 2px;
                     }
                     .${prefix}_todo_content > p {
                        margin: 0 0 5px;
                        font-size: 16px;
                     }
                      .${prefix}_todo_content > span {
                        color: #0000008A;
                        font-size: 14px;
                     }
                     .${prefix}_todo_checkbox {
                        margin-right: 20px;
                     }
                     .${prefix}_todo_position {
                        margin-bottom: 25px;
                     }
                     .${prefix}_todo_position__section {
                        margin-bottom: 20px;
                     }
                `
            }
        }
    });
};