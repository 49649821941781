import ko from 'knockout';
import Handler from 'engine/Handler';
//import 'knockout-mapping';
export default class company_layout extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.tab_sample = ko.observable('retro');

    }
}
