export const MainFeedWidget = ({editor}) => {
    const prefix = 'feed_post_list';
    function script() {}

    let resultPosts = [];

    const posts = [
        {
            author: {
                name: 'Рафик Аслоян',
                avatar: '/../../images/avatar2.jpg',
                time: '5 минут назад'
            },
            content: {
                image: 'https://i.pinimg.com/550x/31/23/2f/31232fe4b51b47763282524f008d9081.jpg',
                text: 'Все дуэли Александра Сергеевича Пушкина 1816 год. Пушкин вызвал на дуэль Павла Ганнибала, родного дядю. Причина: Павел отбил у молодого 17–летнего Пушкина девушку Лошакову на балу. Итог: дуэль отменена. 1817 год. Пушкин вызвал на дуэль Петра Кавер…'
            },
            reactions: {
                like: 1,
                cry: 0,
                dizlike: 0
            }
        },
        {
            author: {
                name: 'Алена Шевченко',
                avatar: '/../../images/avatar3.jpeg',
                time: '2 часа назад'
            },
            content: {
                image: null,
                text: 'Девонька красивая месяц назад приходила на консультацию по продаже квартиры. Посчитала ей рыночную цену продажи, пробежалась по нюансам.\n' +
                    'От сопровождения сделки девушка отказалась, ведь «мы знакомы с покупателями десять лет, они наши хорошие знакомые и у них в фирме есть юрист, который бесплатно нам все проведет». Зато сэкономлю 60 тысяч рублей на сопровождении, улыбнулась она.\n' +
                    'Сейчас звонит, рыдает: нет пяти миллионов рублей. Рассказыв...'
            },
            reactions: {
                like: 1,
                cry: 2,
                dizlike: 0
            }
        },
    ];

    posts.map(post => {
        resultPosts.push({
            tagName: 'div',
            removable: false,
            draggable: false,
            droppable: false,
            copyable: false,
            selectable: false,
            editable: false,
            hoverable: false,
            attributes: {
                class: prefix + '_post_card'
            },
            components: [
                {
                    tagName: 'div',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    editable: false,
                    hoverable: false,
                    attributes: {
                        class: post.content.image === null ? prefix + '_post_card_header blue-text centered' : prefix + '_post_card_header centered'
                    },
                    components: [
                        {
                            tagName: 'div',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            hoverable: false,
                            editable: false,
                            attributes: {
                                class: prefix + '_card_line_cell_content'
                            },
                            components: [
                                {
                                    tagName: 'img',
                                    removable: false,
                                    draggable: false,
                                    droppable: false,
                                    copyable: false,
                                    selectable: false,
                                    hoverable: false,
                                    editable: false,
                                    attributes: {
                                        src: post.author.avatar,
                                        class: prefix + '_avatar'
                                    }
                                },
                                {
                                    tagName: 'div',
                                    removable: false,
                                    draggable: false,
                                    droppable: false,
                                    copyable: false,
                                    selectable: false,
                                    hoverable: false,
                                    editable: false,
                                    content: `
                                            <h6 class="${prefix}_line_cell_title">${post.author.name}</h6>
                                            <span class="${prefix}_line_cell_sub_title">${post.author.time}</span>
                                        `
                                },
                            ]
                        },
                        {
                            tagName: 'button',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            hoverable: false,
                            editable: false,
                            attributes: {
                                class: prefix + '_nav-menu centered'
                            },
                            content: `
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.33337 13C5.88566 13 6.33337 12.5523 6.33337 12C6.33337 11.4477 5.88566 11 5.33337 11C4.78109 11 4.33337 11.4477 4.33337 12C4.33337 12.5523 4.78109 13 5.33337 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.3334 13C12.8857 13 13.3334 12.5523 13.3334 12C13.3334 11.4477 12.8857 11 12.3334 11C11.7811 11 11.3334 11.4477 11.3334 12C11.3334 12.5523 11.7811 13 12.3334 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.3334 13C19.8857 13 20.3334 12.5523 20.3334 12C20.3334 11.4477 19.8857 11 19.3334 11C18.7811 11 18.3334 11.4477 18.3334 12C18.3334 12.5523 18.7811 13 19.3334 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                `
                        },
                    ]
                },
                {
                    tagName: 'div',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    editable: false,
                    hoverable: false,
                    attributes: {
                        class: prefix + '_post_card_content'
                    },
                    components: [
                        {
                            tagName: 'img',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            editable: false,
                            hoverable: false,
                            attributes: {
                                src: post.content.image !== null ? post.content.image : '',
                                class: post.content.image === null ? 'not-image' : ''
                            }
                        },
                        {
                            tagName: 'p',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            editable: false,
                            hoverable: false,
                            attributes: {
                                class: prefix + '_'
                            },
                            content: `${post.content.text}`
                        }
                    ]
                },
                {
                    tagName: 'div',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    editable: false,
                    hoverable: false,
                    attributes: {
                        class: prefix + '_post_card_footer centered'
                    },
                    components: [
                        {
                            tagName: 'div',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            editable: false,
                            hoverable: false,
                            attributes: {
                                class: prefix + '_post_card_footer-action-left centered'
                            },
                            content: `
                                <div class="${prefix}_emoji-container">
                                   <div class="emoji-position">
                                        <img src="https://images.emojiterra.com/google/noto-emoji/unicode-15/color/512px/1f44d.png" alt="emoji like" class="emoji-item">
                                        <img src="https://images.emojiterra.com/google/android-pie/512px/1f44e.png" alt="emoji dizlike" class="emoji-item">
                                        <img src="https://www.cambridge.org/elt/blog/wp-content/uploads/2019/07/Crying-Face-Emoji.png" alt="emoji cry" class="emoji-item">
                                    </div>
                                </div>
                                <span>Как вам?</span>
                            `
                        },
                        {
                            tagName: 'div',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            editable: false,
                            hoverable: false,
                            attributes: {
                                class: prefix + '_post_card_footer-action-right centered'
                            },
                            content: `
                                <div class="${prefix}_comment-container centered">
                                   <button class="centered comment-item">
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5213 2.33973 14.9633 2.94746 16.2543C3.37383 17.1599 3.51978 18.2005 3.14801 19.13L2.86658 19.8336C2.54005 20.6499 3.35013 21.4599 4.16645 21.1334L4.87002 20.852C5.79945 20.4802 6.84005 20.6262 7.74574 21.0525C9.03666 21.6603 10.4787 22 12 22Z" fill="#C7CCD1"/>
                                            <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" fill="black" fill-opacity="0.38"/>
                                            <path d="M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z" fill="black" fill-opacity="0.38"/>
                                            <path d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z" fill="black" fill-opacity="0.38"/>
                                        </svg>
                                        <span>Коммент.</span>
                                    </button>
                                   <button class="centered">
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.00026 18.0197L3 18C3 12.8141 6.94741 8.55028 12.0014 8.0492C12.551 7.99471 13 7.55225 13 6.99997V5.22681C13 4.36396 14.0195 3.90615 14.6644 4.4794L21.1592 11.2526C21.4001 11.4667 21.5113 11.7682 21.4928 12.063C21.477 12.3159 21.3658 12.5637 21.1592 12.7474L14.6644 19.5205C14.0195 20.0938 13 19.636 13 18.7731V16C13 15.4477 12.5514 14.9959 12.0007 15.0378C9.00117 15.2658 6.2861 16.5118 4.20021 18.431C3.75469 18.8409 3.01621 18.617 3.00026 18.0197Z" fill="black" fill-opacity="0.38"/>
                                            <path d="M16 14L17.4142 12.5858" stroke="#C7CCD1" stroke-width="2" stroke-linecap="round"/>
                                       </svg>
                                    </button>
                                </div>
                            `
                        }
                    ]
                }
            ]
        });
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                layerable: false,
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        editable: false,
                        hoverable: false,
                        attributes: {
                            class: prefix + '_posts_section centered'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                editable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_header_card_content centered'
                                },
                                components: [
                                    {
                                        tagName: 'h1',
                                        content: 'Главная лента',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        editable: false,
                                        hoverable: false,
                                    },
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        editable: false,
                                        hoverable: false,
                                        components: [
                                            {
                                                tagName: 'div',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                editable: false,
                                                hoverable: false,
                                                attributes: {
                                                    class: prefix + '_post-card__line-cell line-cell centered'
                                                },
                                                content: `
                                                  <div class="dropdown">
                                                           <span class="${prefix}_filter_title dropdown-toggle" data-toggle="dropdown" aria-expanded="false">За все время</span>
                                                          <div class="dropdown-menu dropdown-post-menu">
                                                            <button class="dropdown-item">За все время</button>
                                                            <button class="dropdown-item">За неделю</button>
                                                            <button class="dropdown-item">За месяц</button>
                                                          </div>
                                                    </div>
                                                `
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                editable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_quick_post centered'
                                },
                                components: [
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        editable: false,
                                        hoverable: false,
                                        components: [
                                            {
                                                tagName: 'img',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                editable: false,
                                                hoverable: false,
                                                attributes: {
                                                    src: '/../../images/avatar3.jpeg',
                                                    class: prefix + '_quick_post_avatar'
                                                }
                                            },
                                            {
                                                tagName: 'span',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                editable: false,
                                                hoverable: false,
                                                attributes: {
                                                    class: prefix + '_quick_post_placeholder'
                                                },
                                                content: 'Что у вас нового?'
                                            }
                                        ]
                                    },
                                    {
                                        tagName: 'button',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        editable: false,
                                        hoverable: false,
                                        attributes: {
                                            class: prefix + '_quick_post_add_file'
                                        },
                                        content: `
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9426 1.25H12.0574C14.3658 1.24999 16.1748 1.24998 17.5863 1.43975C19.031 1.63399 20.1711 2.03933 21.0659 2.93414C21.9607 3.82895 22.366 4.96897 22.5603 6.41371C22.75 7.82519 22.75 9.63423 22.75 11.9426V12.0309C22.75 13.9397 22.75 15.5023 22.6463 16.7745C22.5422 18.0531 22.3287 19.1214 21.8509 20.0087C21.6401 20.4001 21.3812 20.7506 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.14086 20.2726 1.7312 19.2852 1.51335 18.0604C1.29935 16.8573 1.2602 15.3603 1.25207 13.5015C1.25 13.0287 1.25 12.5286 1.25 12.001L1.25 11.9426C1.24999 9.63423 1.24998 7.82519 1.43975 6.41371C1.63399 4.96897 2.03933 3.82895 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.42514 4.56445 3.09825 5.33517 2.92637 6.61358C2.75159 7.91356 2.75 9.62177 2.75 12C2.75 12.5287 2.75 13.0257 2.75205 13.4949C2.76025 15.369 2.80214 16.7406 2.99017 17.7978C3.17436 18.8333 3.48774 19.4981 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.2151 19.7953 20.3872 19.5631 20.5302 19.2976C20.8619 18.6816 21.0531 17.8578 21.1513 16.6527C21.2494 15.4482 21.25 13.9459 21.25 12C21.25 9.62177 21.2484 7.91356 21.0736 6.61358C20.9018 5.33517 20.5749 4.56445 20.0052 3.9948C19.4355 3.42514 18.6648 3.09825 17.3864 2.92637C16.0864 2.75159 14.3782 2.75 12 2.75C9.62177 2.75 7.91356 2.75159 6.61358 2.92637Z" fill="black" fill-opacity="0.38"/>
                                                <path d="M21.2487 13.2804L21.0266 13.2497C18.1828 12.8559 15.5805 14.3343 14.2554 16.5626C12.5459 12.2376 8.02844 9.28807 2.98073 10.0129L2.75497 10.0454C2.75014 10.6421 2.75 11.2913 2.75 12C2.75 12.5287 2.75 13.0257 2.75205 13.4949C2.76025 15.369 2.80214 16.7406 2.99017 17.7978C3.17436 18.8333 3.48774 19.4981 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.2151 19.7953 20.3872 19.5631 20.5302 19.2976C20.8619 18.6816 21.0531 17.8578 21.1513 16.6527C21.2267 15.7267 21.2445 14.6247 21.2487 13.2804Z" fill="black" fill-opacity="0.12"/>
                                                <path d="M18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8Z" fill="black" fill-opacity="0.38"/>
                                            </svg>
                                        `
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                editable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_posts_container'
                                },
                                components: [resultPosts]
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_container {
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family:SF Pro Text, sans-serif;
                        display: flex;
                        justify-content: center;
                    }
                    .${prefix}_posts_section {
                        color: #0000008A;
                        justify-content: center;
                        max-width: 700px;
                        flex-direction: column;
                    }
                    .${prefix}_header_card_content {
                        width: 100%;
                        justify-content: space-between;
                        padding: 24px 0px 24px 0px;
                        margin-bottom: 24px;
                    }
                    .${prefix}_filter_title {
                        font-size: 14px;
                        padding: 10px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .dropdown-toggle::after {
                        display: none;
                    }
                    .${prefix}_filter_title:hover {
                        background: hsl(0, 0%, 82%);
                    }
                    .dropdown-post-menu {
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        border-radius: 10px;
                        border: none;
                        padding: 20px;
                        text-align: start;
                    }
                    .dropdown-post-menu > button {
                        margin-bottom: 5px;
                        border-radius: 5px;
                        padding: 10px
                    }
                    .${prefix}_posts_container {
                        width: 100%;
                    }
                    .${prefix}_post_card {
                        width: 100%;
                        background: white;
                        padding: 5px 20px 32px;
                        border-radius: 10px;
                        margin-bottom: 24px;
                    }
                    .${prefix}_post_card_header {
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 5px;
                    }
                    .blue-text {
                        color: #008DEB;
                        background: #E0F3FF;
                        border-radius: 30px 10px 10px 30px;
                        margin-top: 10px;
                    }
                    .not-image {
                        display: none;
                    }
                    .${prefix}_card_line_cell_content {
                        display: flex;
                        align-items: center;
                        padding: 10px;
                    }
                    .${prefix}_avatar {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 16px;
                        object-fit: cover;
                    }
                    .${prefix}_nav-menu {
                       background: none;
                       border: none;
                       cursor: pointer;
                       border-radius: 10px;
                       padding: 10px 20px;
                    }
                    .${prefix}_line_cell_title {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 600;
                    }
                    .${prefix}_line_cell_sub_title {
                        font-size: 12px;
                    }
                    
                    .${prefix}_post_card_content > img {
                        width: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                        max-height: 300px;
                    }
                    .${prefix}_post_card_content > p {
                        font-size: 18px;
                        line-height: 30px;
                        padding: 15px;
                        color: #0000008A;
                        text-align: justify;
                        margin: 0;
                    }
                    
                    
                    .${prefix}_post_card_footer {
                        margin-top: 16px;
                        justify-content: space-between;
                    } 
                    .${prefix}_emoji-container {
                        display: inline-block;
                        padding: 4px 4px;
                        border-radius: 20px;
                        background: hsl(0, 0%, 92%);
                        margin-right: 16px;
                        width: 42px;
                        overflow: hidden;
                    }
                    .${prefix}_emoji-container:hover {
                         width: auto;
                    }
                    .centered {
                        display: flex;
                        align-items: center;
                    }
                    .emoji-position {
                        display: flex;
                    }
                    .emoji-position > img {
                        width: 30px;
                        height: 30px;
                        padding: 3px;
                    }
                    .emoji-item {
                         padding: 5px;
                         border-radius: 50%;
                         transition: all .3s ease;
                         cursor: pointer;
                         margin: 0 3px;
                    }
                    .emoji-item:hover {
                         background: hsl(0, 0%, 82%);
                    }
                    .${prefix}_comment-container > button {
                        background: none;
                        border: none;
                        color: #0000008A;
                        padding: 5px;
                        cursor: pointer;
                        border-radius: 10px;
                    }
                    .${prefix}_comment-container > button:hover {
                        background: hsl(0, 0%, 92%);
                    }
                    .${prefix}_comment-container > .comment-item {
                        margin-right: 10px;
                        padding: 5px 10px;
                    }
                    .${prefix}_comment-container > .comment-item > svg {
                        margin-right: 8px;
                    }
                    
                    
                    .dropdown-container {
                        position: relative;
                        width: 150px;
                    }
                    .drop-down-menu {
                        background: white;
                        flex-direction: column;
                        padding: 10px;
                        display: flex;
                        border-radius: 10px;
                        position: absolute;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        top: -8px;
                        left: -25px;
                        display: none;
                    }
                    .drop-down-menu.is-active {
                        display: flex;
                    }
                    .drop-down-menu > span {
                        margin: 2px 0 5px;
                        padding: 10px 15px;
                        border-radius: 5px;
                    }
                    .drop-down-menu > span:hover {
                        background: hsl(0, 0%, 82%);
                    }
                    .placeholder-bg {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        top: 0;
                        right: 0
                    }
                    .${prefix}_quick_post {
                       padding: 12px 32px;
                       background: white;
                       border-radius: 10px;
                       width: 100%;
                       margin-bottom: 24px;
                       justify-content: space-between;
                    }
                    .${prefix}_quick_post_avatar {
                        width: 40px;
                        height: 40px;
                        margin-right: 16px;
                        border: .5px solid rgb(0 0 0 / 13%);
                        border-radius: 50%;
                        
                    }
                    .${prefix}_quick_post_add_file {
                        border: none;
                        background: none;
                    }
                    .${prefix}_quick_post_placeholder {
                        color: #00000061;
                    }
                `
            }
        }
    });
};
