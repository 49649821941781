import ko from 'knockout';
import 'knockout-mapping';
import 'styles/style_old.min.css';
import Handler from "engine/Handler";
import intlTelInput from 'intl-tel-input';
export default class remember extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        if (Store.getState().auth.value)
            Router.navigate('/my_page');

        this.unsubscribe = Store.subscribe(()=>{
            this.lang(Store.getState().lang.value);
        });
        this.lang = ko.observable(Store.getState().lang.value);




        this.email = ko.observable('');
        this.successResult = ko.observable(false);
        this.phone = ko.observable('');
        this.truePhone = ko.observable('');
        this.mail_code = ko.observable('');

        this.validatePhone = ko.observable(false);
        this.errorEmail = ko.observable('');
        this.errorPhone = ko.observable('');
        this.errorCodeEmail = ko.observable('');
        this.smsRevText = ko.observable(true);
        this.password = ko.observable('');
        this.password_repeat = ko.observable('');
        this.showPassword = ko.observable(false);
        this.showPasswordRepeat = ko.observable(false);
        this.passwordEmpty = ko.observable(false);
        this.passwordLength = ko.observable(true);
        this.passwordLat = ko.observable(true);
        this.passwordNumber = ko.observable(true);
        this.passwordSymbol = ko.observable(true);

        this.passwordRequired = ko.computed(function() {
            if(this.str_rot13()!== ''){
                this.passwordEmpty(false);
            }else{
                this.passwordEmpty(true);
            }

            if(this.str_rot13().length < 8){
                this.passwordLength(true);
            }else{
                this.passwordLength(false);
            }

            if(!/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*[a-zA-Z]+[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/.test(this.str_rot13())){
                this.passwordLat(true);
            }else{
                this.passwordLat(false);
            }

            if(!/\d/.test(this.str_rot13())){
                this.passwordNumber(true);
            }else{
                this.passwordNumber(false);
            }

            if(!/[!@#$%^&*(),.?":{}|<>]/.test(this.str_rot13())){
                this.passwordSymbol(true);
            }else{
                this.passwordSymbol(false);
            }

            return !(this.passwordEmpty()===false && this.passwordLength()===false && this.passwordLat()===false && this.passwordNumber()===false && this.passwordSymbol()===false);

        }, this);

        this.numberBlock = ko.observable('phone');
        this.stopsend = ko.observable(0);
        this.showsecMail = ko.observable(0);
        this.showsecSMS = ko.observable(0);
        this.sms = ko.observable('');
        this.checkSMS = ko.observable(false);
        this.checkMail = ko.observable(false);
        this.errorSMS = ko.observable('');
        this.errorEmail = ko.observable('');

        //set number mask
        const inputPhone = document.querySelector('#phone');

        const iti = intlTelInput(inputPhone, {
            preferredCountries: ['ru', 'us'],
            autoPlaceholder: 'polite',
            nationalMode: false,
            initialCountry: 'RU',
            placeholderNumberType: 'MOBILE',
            utilsScript:
                '/js/utils.js',
        });
        inputPhone.addEventListener('keyup',  () => {
            if (iti.isValidNumber()) {
                this.truePhone(window.intlTelInputGlobals.getInstance(inputPhone).getNumber());
                this.validatePhone(true);
            } else {
                this.truePhone('');
                this.validatePhone(false);
            }
        });
    }



    showPhoneStep(){
        this.numberBlock('phone');
        this.changeStateFunction('phone');
    }
    showSMSStep(){
        this.numberBlock('sms');
    }
    showEmailStep() {
        this.numberBlock('email');
        this.changeStateFunction('email');
    }
    showCheckEmailStep() {
        this.numberBlock('check_email');
    }
    showLastStep() {
        this.numberBlock('last');
        this.changeStateFunction('steps_last');
    }

    changeState(param) {
        let activeItem = document.querySelector(".steps_block .active");
        if (activeItem) {
            let nextItem = param === 'prev' ? activeItem.previousElementSibling : document.querySelector("." + param);
            if (nextItem) {
                activeItem.classList.remove("active");
                nextItem.classList.add("active");
                switch (true) {
                    case nextItem.classList.contains("step_phone"):
                        this.showPhoneStep();
                        break;
                    case nextItem.classList.contains("step_sms"):
                        this.showSMSStep();
                        break;
                    case nextItem.classList.contains("step_email"):
                        this.showEmailStep();
                        break;
                    case nextItem.classList.contains("step_check_email"):
                        this.showCheckEmailStep();
                        break;
                    case nextItem.classList.contains("steps_last"):
                        this.showLastStep();
                        break;
                    default:
                        this.showPhoneStep();
                        break;
                }
            }
        }
    }
    changeStateFunction (number) {
        switch (number) {
            case "phone":
                return !!this.truePhone() && this.validatePhone();
            case "email":
                return !!this.email() && this.validateEmail();
            case "default":
                return !!this.truePhone() && this.validatePhone();
            case "last":
                return !!this.password() && !!this.password() === !!this.password_repeat();
        }
    }

    SMSCountdown(stopsec){
        let seconds = Math.floor(new Date().getTime()/1000);
        let waitSecSMS = document.getElementById('waitSecSMS');
        let waitSendSMS = document.getElementById('waitSendSMS');
        let sendSMS = document.getElementById('sendSMS');
        this.showsecSMS(stopsec - seconds);
        if ( (this.showsecSMS() <= 0 || this.stopsend() === 1) &&(waitSecSMS !== null && sendSMS !== null)) {
            sendSMS.style.display = 'block';
            waitSendSMS.style.display = 'none';
            return;
        }
        if (waitSecSMS !== null) {
            waitSecSMS.innerHTML = this.showsecSMS();
        }

        setTimeout(function(){
            this.SMSCountdown.call(this,stopsec);
        }.bind(this), 1000);
    }
    mailCountdown(stopsec){
        let seconds = Math.floor(new Date().getTime()/1000);
        let waitSecEmail = document.getElementById('waitSecEmail');
        let sendEmail = document.getElementById('sendEmail');
        this.showsecMail(stopsec - seconds);
        if ((this.showsecMail() <= 0 || this.stopsend() === 1) &&(waitSecEmail !== null && sendEmail !== null)) {
            document.getElementById("sendEmail").style.display = 'block';
            document.getElementById("waitSendEmail").style.display = 'none';
            return;
        }
        if (waitSecEmail !== null) {
            waitSecEmail.innerHTML = this.showsecMail();
        }
        setTimeout(function(){
            this.mailCountdown.call(this,stopsec);
        }.bind(this), 1000);
    }


    validateEmail(_email) {
        return /@[^.]+\.\w/.test(this.email());
    }
    validateSMS () {
        if( this.sms() === '') {
            this.errorSMS('code cannot be empty')
            return false;
        }
        this.Server
            .Request('code_reset_check', { phone: this.truePhone().replace(/\D/g, ''), code: this.sms() })
            .then(res=>{
                res = JSON.parse(res);
                if(res.success){
                    this.checkSMS(true);
                    this.errorSMS('')
                    this.changeState('step_email');
                }
            }).catch(e=>{
                this.checkSMS(false);
                this.errorSMS(this.i18next.t(e))
            })
    }
    validateMail () {
        this.Server
            .Request('check_reset_email', {email: this.email(),code: this.mail_code()})
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success){
                    this.checkMail(true);
                    this.errorCodeEmail('')
                    this.changeState('steps_last');
                }
            })
            .catch(e=>{
                this.checkMail(false);
                this.errorCodeEmail(this.i18next.t(e))
            })
    }

    sendSMSlRequest() {
        this.Server
            .Request('add_phone_reset_call', { phone: this.truePhone().replace(/\D/g, '') })
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success){
                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecSMS( );
                    this.SMSCountdown.call(this,seconds);
                    this.changeState('step_sms');
                    this.errorPhone('');
                }

            }).catch(error=>{
            if (error === 'get_sms') {
                return this.sendSMSRequest();
            } else {
                this.errorPhone(this.i18next.t(error));
                return false;
            }
        });
    }
    sendSMSRequest() {
        this.Server
            .Request('add_phone_reset_sms', { phone: this.truePhone().replace(/\D/g, '') })
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success){
                    this.errorPhone('');
                    this.smsRevText(false);
                    this.changeState('step_sms');

                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");
                    this.smsRevText(false);

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecSMS( );
                    this.SMSCountdown.call(this,seconds);
                }
            }).catch(error=>{
            this.errorPhone(this.i18next.t(error));
            return false;
        });
    }
    sendEmailRequest() {
        this.Server
            .Request('add_reset_email', {email: this.email(),phone: this.truePhone().replace(/\D/g, '')})
            .then(res=>{
                let result = JSON.parse(res);

                if(result.error){
                }
                if(result.success){
                    document.getElementById("sendEmail").style.display = 'none';
                    document.getElementById("waitSendEmail").style.display = 'block';
                    this.errorEmail('')
                    this.stopsend(0);
                    this.showsecMail(60);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecMail( );
                    this.mailCountdown.call(this,seconds);
                    this.changeState('step_check_email');
                }
            })
            .catch(e=>{
                this.errorEmail(this.i18next.t(e))
                return false;
            })
    }


    EventPassword() {
        return {
            keyup: (d, event)=>{
                this.password(event.currentTarget.value);
            }
        };
    }
    str_rot13(){
        return this.password().replace(/[a-zA-Z]/g, function(c){
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }
    checkPasswordRepeat() {
        return this.password() && this.password() === this.password_repeat();
    }
    sendForm() {
        this.Server
            .Request('change_pass', {
                password: this.password(),
                phone: this.truePhone().replace(/\D/g, ''),
                sms: this.sms(),
                email: this.email(),
                mail_code: this.mail_code()
            })
            .then((res) => {
                let result = JSON.parse(res);
                if(result.success){
                    this.successResult(true);
                    document.querySelector(".main-container").className = 'main main-container success_page';
                    document.querySelector(".modal_success").className = 'modal_success visible';
                }
            })
            .catch((error)=>{
                this.checkMail(false);
                this.errorEmail(this.i18next.t(error))
            })
    }

}
