import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class user extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
            //this.param = dataInitComponent.data;
            this.textBtn = ko.observable(this.param.caption);

        //console.log(this.param);

        // Server
        //     .Request('profile')
        //     .then(res=>{
        //         console.log(res)
        //     })
        //     .catch(e=>console.log(e))
        //
        // this.Server.on('message',(message)=>{
        //     console.log('message',message);
        // })

    }

}