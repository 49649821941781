import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class constructor_header extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.unsubscribe = Store.subscribe(()=>{
            this.lang(Store.getState().lang.value);
        });

        this.lang = ko.observable(Store.getState().lang.value);

        this.isShowLeftMenu = ko.observable(false);
        this.isShowRightMenu = ko.observable(false);
        this.isShowNotification = ko.observable(false);
        this.theme = ko.observable('light');
    }

    closeMenu() {
        this.isShowLeftMenu(false);
        this.isShowRightMenu(false);
        this.isShowNotification(false);
    }

    changeTheme(theme) {
        this.theme(theme);
    }

    showPostEditor() {
        this.Store.dispatch({type: 'handler/set', payload: {
                name: 'post-editor'
            }});
    }

    showImageEditor() {
        this.Store.dispatch({type: 'handler/set', payload: {
                name: 'image-editor'
            }});
    }

    changeLang(payload) {
        this.Server.Request('add_settings_device', {
            lang: payload
        }).then((res) => {
            res = JSON.parse(res);
            if (res.success) {
                this.i18next.changeLanguage(payload);
                this.Store.dispatch({ type: 'lang/set', payload });
                document.querySelector('#dropdown').click();
            }
        }).catch(err => console.log(err));
    }

    logOut() {
        this.Server.Request('remove_device')
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.Router.navigate('/login');
                } else {
                    this.textBtn(result.error);
                }
            }).catch(error=>this.textBtn(error));
    }
}
