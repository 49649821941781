export const TextElement = ({editor}) => {
    const prefix = 'text_element';

    editor.Blocks.add(prefix, {
        media: `
            <svg width="45" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_15337_2123)">
                    <path d="M25 34H63M25 46H63M25 58H63M25 26H63M25 38H63M25 50H63M25 62H49M25 30H63M25 42H63M25 54H63" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                </g>
                <defs>
                <filter id="filter0_i_15337_2123" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_innerShadow_15337_2123"/>
                                <feOffset/>
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_15337_2123"/>
                </filter>
                </defs>
            </svg>
        `,
        prefix,
        category: 'Elements',
        select: true,
        content: {type: prefix, name: 'Text'},
        label: 'Text'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                name: 'Текст',
                draggable: '[data-gjs-type=container],[data-gjs-type=one_column]',
                components: [
                    {
                        tagName: 'p',
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        type: 'text',
                        attributes: {
                            class: prefix
                        },
                        content: 'Впишите текст для описания',
                    }
                ],
                styles: `
                    .${prefix} {
                        font-size: 16px;
                        font-weight: 400;
                        color: #0000008A;
                    }
                `
            }
        }
    });
};
