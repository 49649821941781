import Store from './redux/store';
import { applyBindingsToNode, cleanNode } from 'knockout';
import 'Pages';
import 'Components';
import 'bootstrap';

import 'styles/custom.css';
import 'styles/intlTelInput.css';
import {createRouter} from 'routerjs';

import  network  from 'engine/network';
import config from './src/config.json';
import i18next from 'i18next';
import langRu from './src/lang/ru.json';
import langEn from './src/lang/en.json';
import 'styles/style.min.css';

document.body.setAttribute('data-theme', 'dark');

// import RouterHandler from "engine/RouteHandlers";
config.url = 'https://'+location.hostname+':933';
i18next.init({ lng: Store.getState().lang.value,debug: false,  resources: { ru: { translation : langRu }, en : { translation : langEn } }} ).then(()=>Store.subscribe(()=>i18next.changeLanguage(Store.getState().lang.value)));

const Server = new network(config.url);
Server.connect().then(async ()=> {

    Store.dispatch({ type: 'auth/set', payload:Server.User.auth });
    Store.dispatch({ type: 'lang/set', payload:Server.User.lng });

    const root = document.getElementsByTagName('body')[0];

    const Router = createRouter();

    const Page = (name,Request)=> applyBindingsToNode(root, { component: { name , params : { Store, Router, Server, i18next, Request } } });

    const AuthMiddleware = (name, isAuthCheck = true) => (Request) => {
        cleanNode(root);

        if (!Store.getState().auth.value && isAuthCheck) return Router.navigate('/login');

        return Page(name, Request);
    };

    Router
        .get('/', AuthMiddleware('my_page'))
        .get('/home', AuthMiddleware('home'))
        .get('/chat', AuthMiddleware('chat',false))
        .get('/note', AuthMiddleware('note',false))
        .get('/notes_deleted', AuthMiddleware('notes_deleted',false))
        .get('/notes_remind', AuthMiddleware('notes_remind',false))
        .get('/group_chat', AuthMiddleware('group_chat',false))
        //.get('/company_reg', AuthMiddleware('company_reg',false))
        //.get('/reg_account_choose',  AuthMiddleware('reg_account_choose',false))
        .get('/company_reg', AuthMiddleware('user_reg',false))
        .get('/reg_account_choose',  AuthMiddleware('user_reg',false))
        .get('/remember',  AuthMiddleware('remember',false))
        .get('/login', AuthMiddleware('login',false))
        .get('/user_reg',  AuthMiddleware('user_reg',false))
        .get('/web_constructor/:id', AuthMiddleware('web_constructor'))
        .get('/web_constructors', AuthMiddleware('web_constructors'))
        .get('/profile', AuthMiddleware('profile'))
        .get('/community_layout', AuthMiddleware('community_layout',false))
        .get('/community_edit', AuthMiddleware('community_edit',false))
        .get('/company_layout', AuthMiddleware('company_layout',false))
        .get('/community_page', AuthMiddleware('community_page',false))
        .get('/my_page', AuthMiddleware('my_page',false))
        .get('/career', AuthMiddleware('career',false))
        .get('/election', AuthMiddleware('election',false))
        .get('/event', AuthMiddleware('event',false))
        .get('/petition_item', AuthMiddleware('petition_item',false))
        .get('/petitions', AuthMiddleware('petitions',false))
        .get('/polls', AuthMiddleware('polls',false))
        .get('/referendum', AuthMiddleware('referendum',false))
        .get('/sample_party', AuthMiddleware('sample_party',false))
        .get('/voting', AuthMiddleware('voting',false))
        .get('/articles_page', AuthMiddleware('articles_page',false))
        .get('/videos_page', AuthMiddleware('videos_page',false))
        .get('/my_videos_page', AuthMiddleware('my_videos_page',false))
        .get('/video_single', AuthMiddleware('video_single',false))
        .get('/social_main', AuthMiddleware('social_main',false))
        .get('/bookmarks', AuthMiddleware('bookmarks',false))
        .get('/playlist_video', AuthMiddleware('playlist_video',false))
        .get('/playlists_video', AuthMiddleware('playlists_video',false))
        .get('/music', AuthMiddleware('music',false))
        .get('/communities', AuthMiddleware('communities',false))
        .get('/communities_category', AuthMiddleware('communities_category',false))
        .get('/communities_control', AuthMiddleware('communities_control',false))
        .get('/music_albums', AuthMiddleware('music_albums',false))
        .get('/music_playlists', AuthMiddleware('music_playlists',false))
        .get('/music_recently_listener', AuthMiddleware('music_recently_listener',false))
        .get('/music_saved', AuthMiddleware('music_saved',false))
        .get('/profile_setting', AuthMiddleware('profile_setting',false))
        .get('/profile_verification', AuthMiddleware('profile_verification',false))
        .get('/profile_security', AuthMiddleware('profile_security',false))
        .get('/profile_personal', AuthMiddleware('profile_personal',false))
        .get('/profile_notices', AuthMiddleware('profile_notices',false))
        .get('/profile_general', AuthMiddleware('profile_general',false))
        .get('/profile_blacklist', AuthMiddleware('profile_blacklist',false))
        .get('/profile_private', AuthMiddleware('profile_private',false))
        .get('/video_clip_search_page', AuthMiddleware('video_clip_search_page',false))
        .get('/video_clip_main_page', AuthMiddleware('video_clip_main_page',false))
        .get('/balance_page', AuthMiddleware('balance_page',false))
        .get('/wallet_page', AuthMiddleware('wallet_page',false))
        .get('/wallet_cards', AuthMiddleware('wallet_cards',false))
        .get('/wallet_operation', AuthMiddleware('wallet_operation',false))
        .get('/create_video_clip_page', AuthMiddleware('create_video_clip_page',false))

        .get('/statistic_attendance', AuthMiddleware('statistic_attendance',false))
        .get('/statistic_activity', AuthMiddleware('statistic_activity',false))
        .get('/statistic_coverage', AuthMiddleware('statistic_coverage',false))
        .get('/statistic_post', AuthMiddleware('statistic_post',false))
        .get('/statistic_messages', AuthMiddleware('statistic_messages',false))
        .get('/statistic_review', AuthMiddleware('statistic_review',false))
        .get('/statistic_records', AuthMiddleware('statistic_records',false))
        .get('/profile_more', AuthMiddleware('profile_more',false))
        .get('/statistic_referendum', AuthMiddleware('statistic_referendum',false))
        .get('/statistic_petition', AuthMiddleware('statistic_petition',false))
        .get('/statistic_petition_single', AuthMiddleware('statistic_petition_single',false))
        .get('/statistic_projects', AuthMiddleware('statistic_projects',false))
        .get('/company_page', AuthMiddleware('company_page',false))
        .get('/companies', AuthMiddleware('companies',false))
        .error(404, (/*err, context*/) => {
            throw Error('Page not found');
        })
        .run();

    //старая версия
    // const Router = new RouterHandler();
    // const Page = (name)=>applyBindingsToNode(root, { component: { name , params : { Store, Router, Server, i18next } } });
    //
    // // Router.change = () => cleanNode(root);
    // Router
    //     .on('/', () => Page('home'))
    //     .on('/home', () => Page('home'))
    //     .on('/company_reg', () => Page('company_reg'))
    //     .on('/reg_account_choose', () => Page('reg_account_choose'))
    //     .on('/remember', () => Page('remember'))
    //     .on('/web_constructor', () => Page('web_constructor'))
    //     .on('/web_constructors', () => Page('web_constructors'))
    //     .on('/login', () => Page('login'))
    //     .on('/user_reg', () => Page('user_reg'))
    //     .notFound(() =>{throw Error('Page not found');})
    //     .init();
 });


