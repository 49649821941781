import ko from 'knockout';
import Handler from 'engine/Handler';
import 'knockout-mapping';
import GLightbox from "glightbox";
export default class chat extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.messages = Array.from([
            {
                message: 'Привет',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: true,
                action: 'incoming',
                name: 'vasia',
                date: '14:30',
                reaction: [
                    './../../img/emoji/clown-face.gif'
                ]
            },
            {
                message: 'Текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: false,
                action: 'incoming',
                reaction: [
                    './../../img/emoji/face-symbols-on-mouth.gif'
                ]
            },
            {
                message: 'Текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'read',
                isChecked: false,
                isFirstMessage: true,
                action: 'outgoing',
                name: 'dima',
                date: '12:30',
                reaction: []
            },
            {
                message: 'Содержательный текст сообщения интересного человека',
                attachments: {video: [], image: [], recording: []},
                status: 'unread',
                isChecked: false,
                isFirstMessage: false,
                action: 'outgoing',
                reaction: [
                    './../../img/emoji/clueless-icon-v1-resized.gif',
                    './../../img/emoji/angry-icon-v1-resized.gif'
                ]
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [], recording: [],
                    image: ['https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D']
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing',
                reaction: []
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [
                        'https://cdn.pixabay.com/video/2022/12/10/142376-779987436_large.mp4',
                        'https://cdn.pixabay.com/video/2022/12/10/142376-779987436_large.mp4',
                    ],
                    image: [], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing',
                reaction: [
                    './../../img/emoji/clown-face.gif',
                    './../../img/emoji/discovered-new-emoji.gif'
                ]
            },
            {
                message: 'bla bla bla',
                isFirstMessage: false,
                attachments: {
                    image: [
                        'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                    ],
                    video: [], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing',
                reaction: []
            },
            {
                message: 'На Планете 3 природа – это уникальное произведение искусства. Горы величественны, океаны манят своей бескрайностью.',
                isFirstMessage: false,
                attachments: {
                    video: [],
                    image: [
                        // 'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        // 'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        // 'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        // 'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                        'https://images.unsplash.com/photo-1630839437035-dac17da580d0?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBsYW5ldHxlbnwwfHwwfHx8MA%3D%3D',
                    ], recording: []
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing',
                reaction: []
            },
            {
                message: '',
                isFirstMessage: false,
                attachments: {
                    video: [],
                    image: [],
                    recording: ['bla bla']
                },
                status: 'read',
                isChecked: false,
                action: 'outgoing',
                reaction: []
            },
        ], message => ko.mapping.fromJS(message));

        this.isNotify = ko.observable(false);
        this.isSearchContainer = ko.observable(false);
        this.tabItem = ko.observable('members');

        this.messageText = ko.observable("");
        setTimeout(()=>{
            GLightbox({
                touchNavigation: false,
                loop: false,
                autoplayVideos: true
            });
        },0);

    }
    changeNotifyStatus() {
        this.isNotify(!this.isNotify());
        return true;
    }

    getDeviceType() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

        if (isMobile) {
            return "mobile";
        } else {
            return "desktop";
        }
    }

    checkMessage(position) {
        let self = this;
        return {
            click: (data, event) => {
                if (event.target.classList.length > 0 && !['d-grid', 'nav-link','reaction-item','action-item', 'message-menu-item','speech-synthesis', 'message-text', 'image','select-item-message', 'image-container', 'speech-play-item', 'message-video-play-item'].includes(event.target.classList[0])) {
                    ko.utils.arrayMap(self.messages, (message, index) => {
                        if (index === position) {
                            message.isChecked(!message.isChecked());
                        }
                        return message;
                    });
                }
            }
        };
    }

    resetCheckMessages() {
        ko.utils.arrayMap(this.messages, message => message.isChecked(false));
    }

    checkCountMessage() {
        return ko.utils.arrayFilter(this.messages, (message) => message.isChecked()).length;
    }

    handleRecItem() {
        return {
            mousedown: (data, event) => {
                if (this.getDeviceType() === 'desktop') event.target.closest('#insert-form').classList.add('rec-action');
                if (event.target.closest('#insert-form').classList.contains('rec-action')) document.addEventListener('mouseup', () => event.target.closest('#insert-form').classList.remove('rec-action'));
            },
            mouseup: (data, event) => {
                if (this.getDeviceType() === 'desktop') event.target.closest('#insert-form').classList.remove('rec-action');
            },
            touchstart: (data, event) => {
                event.target.closest('#insert-form').classList.add('rec-action');
            }
        };
    }
    handleSandRec() {
        return {
            touchstart: (data, event) => {
                if (this.getDeviceType() === 'mobile') event.target.closest('#insert-form').classList.remove('rec-action');
            }
        };
    }
    navHandle() {
        return {
            click: (data, event) => {
                if (window.screen.width > 767) return true;

                event.target.closest('.layout-content').querySelector('#dialog-item').classList.toggle('main-shown');
                event.target.closest('.layout-page').querySelector('#main-menu').classList.toggle('d-none');

            }
        };
    }
}
