import { createSlice } from '@reduxjs/toolkit';
export const sample = createSlice({
    name: 'sample',
    initialState: {
        blocks: [],
        sample_style: 'classic'
    },
    reducers: {
        setSampleName: (state, action) => {
            state.sample_style = action.payload.name;
        },
        setSampleBlock: (state, action) => {
            state.blocks.push([...action.payload]);
        }
    }
});
export const { set } = sample.actions;
export default sample.reducer;
