export const StateWidget = ({editor}) => {
    let prefix = 'state';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8324 29.8013C23.9583 29.1747 28 26.926 28 21.1112C28 15.8196 24.1267 12.2959 21.3415 10.6768C20.7235 10.3176 20 10.7901 20 11.5049V13.3334C20 14.7753 19.3938 17.4071 17.7093 18.5018C16.8493 19.0607 15.9205 18.2242 15.816 17.2039L15.7302 16.366C15.6304 15.392 14.6384 14.8008 13.86 15.3946C12.4615 16.4614 11 18.3296 11 21.1112C11 28.2223 16.2889 30.0001 18.9333 30.0001C19.0871 30.0001 19.2488 29.9955 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013Z" fill="#99D6FF"/>
                    <path d="M16 26.4442C16 29.064 18.1113 29.8742 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013C21.871 29.4343 23 28.4922 23 26.4442C23 25.1465 22.1814 24.3459 21.5401 23.9711C21.3439 23.8564 21.1161 24.0008 21.0985 24.2273C21.0429 24.9454 20.3534 25.5174 19.8836 24.9714C19.4685 24.4889 19.2941 23.784 19.2941 23.3331V22.7439C19.2941 22.3887 18.9365 22.1533 18.631 22.3346C17.4951 23.0085 16 24.3949 16 26.4442Z" fill="#008DEB"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix, name: 'Статистика страницы'},
        label: 'Статистика страницы'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_content_state ' + prefix + '_state_1  d-flex align-items-center justify-content-center'
                        },
                        components: [
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>Подписчики</span>`
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold ' +  prefix + '_bold_number'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `124 221`
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_state_label'
                                },
                                content: `человек`
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_content_state ' + prefix + '_state_2  d-flex align-items-center justify-content-center'
                        },
                        components: [
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>Подписки</span>`
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold ' +  prefix + '_bold_number'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `879`
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_state_label'
                                },
                                content: `страниц`
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_content_state ' + prefix + '_state_3  d-flex align-items-center justify-content-center'
                        },
                        components: [
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>Опубликовано</span>`
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold ' +  prefix + '_bold_number'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>6 233</span>`
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_state_label'
                                },
                                content: `постов`
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_content_state ' + prefix + '_state_4  d-flex align-items-center justify-content-center'
                        },
                        components: [
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `Регистрация`
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_state_bold ' +  prefix + '_bold_number'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `2 006`
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_state_label'
                                },
                                content: `октябрь`
                            },
                        ]
                    },
                ],
                styles: `
                    .${prefix}_container {
                        background: white;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        padding: 48px 32px;
                        color: #0000008A;
                        font-weight: 600;
                        font-size: 14px;
                    }
                    .${prefix}_content_state {
                        display: flex;
                        flex-direction: column;
                        padding: 20px;
                    }
                    .${prefix}_state_1 {
                        border-bottom: 1px solid #0000001F;
                        border-right: 1px solid #0000001F;
                    }
                    .${prefix}_state_2 {
                        border-left: 1px solid #0000001F;
                        border-bottom: 1px solid #0000001F;
                    }
                    .${prefix}_state_3 {
                        border-top: 1px solid #0000001F;
                        border-right: 1px solid #0000001F;
                    }
                    .${prefix}_state_4 {
                        border-top: 1px solid #0000001F;
                        border-left: 1px solid #0000001F;
                    }
                    .${prefix}_state_bold {
                        color: black;
                    } 
                    .${prefix}_bold_number {
                        font-size: 28px;
                    }
                `
            }
        }
    });
};
