export const FriendsWidget = (editor) => {
    let prefix = 'friends';

    const obj = editor.I18n;

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8324 29.8013C23.9583 29.1747 28 26.926 28 21.1112C28 15.8196 24.1267 12.2959 21.3415 10.6768C20.7235 10.3176 20 10.7901 20 11.5049V13.3334C20 14.7753 19.3938 17.4071 17.7093 18.5018C16.8493 19.0607 15.9205 18.2242 15.816 17.2039L15.7302 16.366C15.6304 15.392 14.6384 14.8008 13.86 15.3946C12.4615 16.4614 11 18.3296 11 21.1112C11 28.2223 16.2889 30.0001 18.9333 30.0001C19.0871 30.0001 19.2488 29.9955 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013Z" fill="#99D6FF"/>
                    <path d="M16 26.4442C16 29.064 18.1113 29.8742 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013C21.871 29.4343 23 28.4922 23 26.4442C23 25.1465 22.1814 24.3459 21.5401 23.9711C21.3439 23.8564 21.1161 24.0008 21.0985 24.2273C21.0429 24.9454 20.3534 25.5174 19.8836 24.9714C19.4685 24.4889 19.2941 23.784 19.2941 23.3331V22.7439C19.2941 22.3887 18.9365 22.1533 18.631 22.3346C17.4951 23.0085 16 24.3949 16 26.4442Z" fill="#008DEB"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix, name: 'Анкетные данные'},
        label: 'Анкетные данные'
    });

    function script() {}

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                script,
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column align-items-center justify-content-center'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: 'd-flex flex-column align-items-center'
                        },
                        components : [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: prefix + '_friends_list d-flex flex-row justify-content-around'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                components: [
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        attributes: {
                                            class: prefix + '_friend d-flex flex-column align-items-center'
                                        },
                                        components: [
                                            {
                                                tagName: 'img',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                attributes: {
                                                    src: '/../../images/avatar4.jpeg'
                                                }
                                            },
                                            {
                                                tagName: 'span',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                content: 'Вася'
                                            }
                                        ]
                                    },
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        attributes: {
                                            class: prefix + '_friend d-flex flex-column align-items-center'
                                        },
                                        components: [
                                            {
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                tagName: 'img',
                                                attributes: {
                                                    src: '/../../images/avatar1.jpg'
                                                }
                                            },
                                            {
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                tagName: 'span',
                                                content: 'Маруся'
                                            }
                                        ]
                                    },
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        attributes: {
                                            class: prefix + '_friend d-flex flex-column align-items-center'
                                        },
                                        components: [
                                            {
                                                tagName: 'img',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                attributes: {
                                                    src: '/../../images/avatar2.jpg'
                                                }
                                            },
                                            {
                                                tagName: 'span',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                content: 'Jeck'
                                            }
                                        ]
                                    },
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        attributes: {
                                            class: prefix + '_friend d-flex flex-column align-items-center'
                                        },
                                        components: [
                                            {
                                                tagName: 'img',
                                                removable: false,
                                                draggable: false,
                                                droppable: false,
                                                copyable: false,
                                                selectable: false,
                                                hoverable: false,
                                                editable: false,
                                                attributes: {
                                                    src: '/../../images/avatar3.jpeg'
                                                }
                                            },
                                            {
                                                tagName: 'span',
                                                content: 'Люси'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                tagName: 'h5',
                                attributes: {
                                    class: prefix + '_content_title',
                                    ['data-bind']: 'i18nText: test'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: obj.t('phrase.Friends', { params: {count: 123} })
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_content_about'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>128</span> друзей, <span>1</span> общий `
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_content_about'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>124 221</span> подписчиков`
                            },
                            {
                                tagName: 'span',
                                attributes: {
                                    class: prefix + '_content_about'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `<span>Смотреть</span>`
                            }
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        font-family: sans-serif;
                        color: #0000008A;
                        height: 100%;
                     }
                     .${prefix}_content_title {
                        font-size: 38px;
                        font-weight: 500;
                        line-height: 40px;
                        text-align: center;
                        margin-top: 56px;
                        margin-bottom: 16px;
                        color: black;
                     }
                     .${prefix}_content_about > span {
                        font-weight: 600;
                     }
                     .${prefix}_friend > img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                     }
                     .${prefix}_friend:not(:last-child) {
                        padding: 0 10px;
                     }
                     .${prefix}_friends_list {
                        gap: 10px;
                     }
                `
            }
        }
    });
};
