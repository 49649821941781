import ko from 'knockout';
import Handler from 'engine/Handler';
import Highcharts from 'highcharts/highmaps.js';
import TiledWebMap from 'highcharts/modules/tiledwebmap.js';

//import 'knockout-mapping';
export default class petitions extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.initializationMap(position.coords.longitude, position.coords.latitude);
            });
        } else {
            this.initializationMap(10.73028454146517, 59.91261204279989);
        }

        TiledWebMap(Highcharts);
    }

    initializationMap(lat, lon) {
        Highcharts.mapChart('petitions-map', {
            chart: {
                margin: 0,
                backgroundColor: '#efefef'
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            navigation: {
                buttonOptions: {
                    align: 'left',
                    theme: {
                        stroke: '#e6e6e6'
                    }
                }
            },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    alignTo: 'spacingBox'
                }
            },

            mapView: {
                center: [lat, lon],
                zoom: 13
            },

            tooltip: {
                pointFormat: '{point.name}'
            },

            legend: {
                enabled: false,
                title: {
                    text: 'Attractions in Oslo'
                },
                align: 'left',
                symbolWidth: 20,
                symbolHeight: 20,
                itemStyle: {
                    textOutline: '1 1 1px rgba(255,255,255)'
                },
                backgroundColor: 'rgba(255,255,255,0.8)',
                float: true,
                borderColor: '#e6e6e6',
                borderWidth: 1,
                borderRadius: 2,
                itemMarginBottom: 5
            },

            plotOptions: {
                mappoint: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },

            credits: {
                enabled: false,
            },

            series: [{
                type: 'tiledwebmap',
                name: 'Basemap Tiles',
                provider: {
                    type: 'OpenStreetMap'
                },
                showInLegend: true
            }, {
                type: 'mappoint',
                name: 'Museums',
                marker: {
                    symbol: 'url(https://www.highcharts.com/samples/graphics/museum.svg)',
                    width: 24,
                    height: 24
                },
                data: [{
                    name: 'Fram Museum',
                    lon: 10.69299,
                    lat: 59.90199
                }, {
                    name: 'Vigeland Museum',
                    lon: 10.70013,
                    lat: 59.92285
                }, {
                    name: 'Norwegian Museum of Cultural History',
                    lon: 10.68490,
                    lat: 59.90414
                }, {
                    name: 'The Viking Ship Museum (Vikingskipshuset)',
                    lon: 10.68446,
                    lat: 59.90475
                }, {
                    name: 'Museum of Cultural History',
                    lon: 10.73547,
                    lat: 59.91680
                }, {
                    name: 'The Astrup Fearnley Museum of Modern Art',
                    lon: 10.72086,
                    lat: 59.90706
                }, {
                    name: 'Munch Museum',
                    lon: 10.75565,
                    lat: 59.90616
                }, {
                    name: 'Natural History Museum at the University of Oslo',
                    lon: 10.77170,
                    lat: 59.9198
                }]
            }, {
                type: 'mappoint',
                name: 'Parks',
                marker: {
                    symbol: 'url(https://www.highcharts.com/samples/graphics/tree.svg)',
                    width: 24,
                    height: 24
                },
                data: [{
                    name: 'The Vigeland Park',
                    lon: 10.70514,
                    lat: 59.92448
                }, {
                    name: 'Frogner Park',
                    lon: 10.70347,
                    lat: 59.92645
                }, {
                    name: 'The University\'s Botanical Garden',
                    lon: 10.76990,
                    lat: 59.91740
                }]
            }, {
                type: 'mappoint',
                name: 'Your are here',
                marker: {
                    symbol: 'url(data:image/svg+xml,%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2231%22%20height%3D%2231%22%20rx%3D%2215.5%22%20fill%3D%22white%22%2F%3E%0A%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2231%22%20height%3D%2231%22%20rx%3D%2215.5%22%20stroke%3D%22%235E00D9%22%2F%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M15.5397%2026.351C15.57%2026.3685%2015.5937%2026.3821%2015.6105%2026.3915L15.6384%2026.4071C15.8613%2026.5294%2016.1378%2026.5285%2016.3608%2026.4075L16.3895%2026.3915C16.4063%2026.3821%2016.43%2026.3685%2016.4603%2026.351C16.5207%2026.316%2016.607%2026.265%2016.7155%2026.1982C16.9325%2026.0646%2017.2388%2025.8676%2017.6046%2025.6091C18.3351%2025.0931%2019.3097%2024.3274%2020.2865%2023.3273C22.2307%2021.3368%2024.25%2018.3462%2024.25%2014.5C24.25%209.94365%2020.5563%206.25%2016%206.25C11.4437%206.25%207.75%209.94365%207.75%2014.5C7.75%2018.3462%209.76932%2021.3368%2011.7135%2023.3273C12.6903%2024.3274%2013.6649%2025.0931%2014.3954%2025.6091C14.7612%2025.8676%2015.0675%2026.0646%2015.2845%2026.1982C15.393%2026.265%2015.4793%2026.316%2015.5397%2026.351ZM16%2017.5C17.6569%2017.5%2019%2016.1569%2019%2014.5C19%2012.8431%2017.6569%2011.5%2016%2011.5C14.3431%2011.5%2013%2012.8431%2013%2014.5C13%2016.1569%2014.3431%2017.5%2016%2017.5Z%22%20fill%3D%22%235E00D9%22%2F%3E%0A%3C%2Fsvg%3E%0A)',
                    width: 24,
                    height: 24,
                    radius: 50,

                },
                data: [{
                    // name: 'Your',
                    lon: lat,
                    lat: lon
                }]
            }, {
                type: 'mappoint',
                name: 'Great buildings',
                marker: {
                    symbol: 'url(https://www.highcharts.com/samples/graphics/building.svg)',
                    width: 24,
                    height: 24
                },
                data: [{
                    name: 'The Norwegian National Opera & Ballet',
                    lon: 10.75182,
                    lat: 59.90766
                }, {
                    name: 'Akershus Fortress',
                    lon: 10.73601,
                    lat: 59.90766
                }, {
                    name: 'Royal Palace in Oslo',
                    lon: 10.72750,
                    lat: 59.91694
                }, {
                    name: 'Oslo City Hall',
                    lon: 10.73358,
                    lat: 59.91176
                }, {
                    name: 'Akrobaten bru',
                    lon: 10.75965,
                    lat: 59.90971
                }]
            }, {
                type: 'mappoint',
                name: 'Restaurants',
                marker: {
                    symbol: 'url(https://www.highcharts.com/samples/graphics/eat.svg)',
                    width: 24,
                    height: 24
                },
                data: [{
                    name: 'Elias mat & sånt',
                    lon: 10.73868,
                    lat: 59.91631
                }, {
                    name: 'Østbanehallen renovated train station & food court',
                    lon: 10.75109,
                    lat: 59.91085
                }]
            }]
        });
    }
}
