export const ProfileReferendumWidget = ({editor}) => {
    const prefix = 'referendum';

    const news = {
        title: 'Строительство новой школы на 1000-1500мест в микрорайоне Ольгино, го Балашиха, МО, Россия',
        subtitle: 'Правительство МО',
        author: 'Марина Алексеева',
        view: 948044,
        image: 'https://balashiha.ru/files/image/60/70/63/lg!ljd.jpg'
    };

    let resultNewsHtml = {
        tagName: 'div',
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        editable: false,
        attributes: {
            class: prefix + '_body text-white'
        },
        components: [
            {
                tagName: 'span',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    class: prefix + '_subtitle'
                },
                content: news.subtitle
            },
            {
                tagName: 'p',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    class: prefix + '_title'
                },
                content: news.title
            },
            {
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    class: prefix + '_footer d-flex flex-row justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_footer d-flex flex-row align-items-center'
                        },
                        content: `
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                                <path d="M13.8334 5.5C13.8334 7.433 12.2664 9 10.3334 9C8.40038 9 6.83337 7.433 6.83337 5.5C6.83337 3.567 8.40038 2 10.3334 2C12.2664 2 13.8334 3.567 13.8334 5.5Z" fill="white" fill-opacity="0.87"/>
                                <path d="M17.3334 14.5C17.3334 16.433 17.3334 18 10.3334 18C3.33337 18 3.33337 16.433 3.33337 14.5C3.33337 12.567 6.46738 11 10.3334 11C14.1994 11 17.3334 12.567 17.3334 14.5Z" fill="white" fill-opacity="0.54"/>
                            </svg>
                            <span>${news.author}</span>
                        `
                    },
                    {
                        tagName: 'span',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: ` <span>${news.view} (88%)</span>`
                    }
                ]
            }
        ]
    };

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3796 11.1551C13.874 11.641 13.2426 13.1653 11.9798 16.214C10.717 19.2626 10.0856 20.7869 10.8066 22.1952C11.5276 23.6034 13.3195 24.3456 16.9032 25.8301L18.3286 26.4205C21.9123 27.9049 23.7042 28.6471 25.2098 28.1612C26.7154 27.6752 27.3468 26.1509 28.6096 23.1023C29.8724 20.0536 30.5038 18.5293 29.7828 17.1211C29.0618 15.7128 27.2699 14.9706 23.6862 13.4862L22.2608 12.8957C18.6771 11.4113 16.8852 10.6691 15.3796 11.1551Z" fill="#99D6FF"/>
                    <path d="M15.3334 20L16.8334 21.5L19.3334 19" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <line x1="29.3334" y1="28" x2="11.3334" y2="28" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Референдум'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_header d-flex align-items-center'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                             <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-lg-3">
                                <path d="M0.333374 20C0.333374 8.95431 9.28768 0 20.3334 0C31.3791 0 40.3334 8.95431 40.3334 20C40.3334 31.0457 31.3791 40 20.3334 40C9.28768 40 0.333374 31.0457 0.333374 20Z" fill="#E0F3FF"/>
                                <path d="M15.3796 11.1551C13.874 11.641 13.2426 13.1653 11.9798 16.214C10.717 19.2626 10.0856 20.7869 10.8066 22.1952C11.5276 23.6034 13.3195 24.3456 16.9032 25.8301L18.3286 26.4205C21.9123 27.9049 23.7042 28.6471 25.2098 28.1612C26.7154 27.6752 27.3468 26.1509 28.6096 23.1023C29.8724 20.0536 30.5038 18.5293 29.7828 17.1211C29.0618 15.7128 27.2699 14.9706 23.6862 13.4862L22.2608 12.8957C18.6771 11.4113 16.8852 10.6691 15.3796 11.1551Z" fill="#99D6FF"/>
                                <path d="M15.3334 20L16.8334 21.5L19.3334 19" stroke="#008DEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <line x1="29.3334" y1="28" x2="11.3334" y2="28" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <h5 class="main__item-title m-0">Референдум</h5>
                        `
                    },
                    resultNewsHtml
                ],
                styles: `
                    .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family:SF Pro Text, sans-serif;
                        background-image: url(${news.image});
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                    .${prefix}_header {
                        width: 180px;
                        background: #E0F3FF;
                        border-radius: 10px; 
                        color: hsla(204, 100%, 37%, 1);
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .${prefix}_body {
                        background: rgb(0 0 0 / 44%);
                        padding: 10px;
                        border-radius: 10px;
                    }
                    .${prefix}_subtitle, .${prefix}_footer {
                        font-size: 12px;
                        font-weight: 400;
                        color: hsla(0, 0%, 100%, 0.87);
                    }
                     .${prefix}_title {
                        font-size: 16px;
                        font-weight: 600;
                        color: white;
                        margin: .5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 6px 0 8px;
                    }
                `
            }
        }
    });
};
