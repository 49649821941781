export const CommunitiesWidget = ({editor}) => {
    const prefix = 'communities';

    editor.Blocks.add(prefix, {
        media: `<svg width="60" height="80" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                    <path d="M10 18.05C10 15.2025 10 13.7788 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.7788 10 15.2025 10 18.05 10H21.95C24.7975 10 26.2212 10 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C30 13.7788 30 15.2025 30 18.05V21.95C30 24.7975 30 26.2212 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.2212 30 24.7975 30 21.95 30H18.05C15.2025 30 13.7788 30 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10 26.2212 10 24.7975 10 21.95V18.05Z" fill="#99D6FF"/>
                    <path d="M14 29.8447C13.4929 29.7614 13.0733 29.6335 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10.3665 26.9267 10.2386 26.5071 10.1553 26H14V29.8447Z" fill="#008DEB"/>
                    <path d="M18.05 30H19V26H16V29.9883C16.5906 30 17.2664 30 18.05 30Z" fill="#008DEB"/>
                    <path d="M21 30H21.95C22.7336 30 23.4094 30 24 29.9883V26H21V30Z" fill="#008DEB"/>
                    <path d="M29.8447 26C29.7614 26.5071 29.6335 26.9267 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.9267 29.6335 26.5071 29.7614 26 29.8447V26H29.8447Z" fill="#008DEB"/>
                    <path d="M26 10.1553C26.5071 10.2386 26.9267 10.3665 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C29.6335 13.0733 29.7614 13.4929 29.8447 14H26V10.1553Z" fill="#008DEB"/>
                    <path d="M21.95 10H21V14H24V10.0117C23.4094 10 22.7336 10 21.95 10Z" fill="#008DEB"/>
                    <path d="M19 10H18.05C17.2664 10 16.5906 10 16 10.0117V14H19V10Z" fill="#008DEB"/>
                    <path d="M14 10.1553V14H10.1553C10.2386 13.4929 10.3665 13.0733 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.0733 10.3665 13.4929 10.2386 14 10.1553Z" fill="#008DEB"/>
                    <path d="M22 20C22 19.4722 21.4704 19.1162 20.4112 18.4043C19.3375 17.6827 18.8006 17.3219 18.4003 17.5868C18 17.8517 18 18.5678 18 20C18 21.4322 18 22.1483 18.4003 22.4132C18.8006 22.6781 19.3375 22.3173 20.4112 21.5957C21.4704 20.8838 22 20.5278 22 20Z" fill="#008DEB"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Сообщества'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_header d-flex flex-row align-items-center justify-content-between'
                        },
                        components: [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: 'd-flex align-items-center'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                        <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                        <path d="M10 18.05C10 15.2025 10 13.7788 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.7788 10 15.2025 10 18.05 10H21.95C24.7975 10 26.2212 10 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C30 13.7788 30 15.2025 30 18.05V21.95C30 24.7975 30 26.2212 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.2212 30 24.7975 30 21.95 30H18.05C15.2025 30 13.7788 30 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10 26.2212 10 24.7975 10 21.95V18.05Z" fill="#99D6FF"/>
                                        <path d="M14 29.8447C13.4929 29.7614 13.0733 29.6335 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10.3665 26.9267 10.2386 26.5071 10.1553 26H14V29.8447Z" fill="#008DEB"/>
                                        <path d="M18.05 30H19V26H16V29.9883C16.5906 30 17.2664 30 18.05 30Z" fill="#008DEB"/>
                                        <path d="M21 30H21.95C22.7336 30 23.4094 30 24 29.9883V26H21V30Z" fill="#008DEB"/>
                                        <path d="M29.8447 26C29.7614 26.5071 29.6335 26.9267 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.9267 29.6335 26.5071 29.7614 26 29.8447V26H29.8447Z" fill="#008DEB"/>
                                        <path d="M26 10.1553C26.5071 10.2386 26.9267 10.3665 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C29.6335 13.0733 29.7614 13.4929 29.8447 14H26V10.1553Z" fill="#008DEB"/>
                                        <path d="M21.95 10H21V14H24V10.0117C23.4094 10 22.7336 10 21.95 10Z" fill="#008DEB"/>
                                        <path d="M19 10H18.05C17.2664 10 16.5906 10 16 10.0117V14H19V10Z" fill="#008DEB"/>
                                        <path d="M14 10.1553V14H10.1553C10.2386 13.4929 10.3665 13.0733 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.0733 10.3665 13.4929 10.2386 14 10.1553Z" fill="#008DEB"/>
                                        <path d="M22 20C22 19.4722 21.4704 19.1162 20.4112 18.4043C19.3375 17.6827 18.8006 17.3219 18.4003 17.5868C18 17.8517 18 18.5678 18 20C18 21.4322 18 22.1483 18.4003 22.4132C18.8006 22.6781 19.3375 22.3173 20.4112 21.5957C21.4704 20.8838 22 20.5278 22 20Z" fill="#008DEB"/>
                                     </svg>
                                    <h5 class="main__item-title m-0">Сообщесва</h5>
                                `
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_go_to_page',
                                    type: 'button'
                                },
                                content: `
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.69699 0.46967C1.40409 0.176777 0.92922 0.176777 0.636326 0.46967C0.343433 0.762563 0.343433 1.23744 0.636326 1.53033L3.106 4L0.636326 6.46967C0.343433 6.76256 0.343433 7.23744 0.636326 7.53033C0.92922 7.82322 1.40409 7.82322 1.69699 7.53033L4.69699 4.53033C4.98988 4.23744 4.98988 3.76256 4.69699 3.46967L1.69699 0.46967Z" fill="black" fill-opacity="0.38"/>
                                    </svg>
                                `
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: 'position-relative w-100 h-100 d-flex align-items-center justify-content-center'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_items_content',
                                },
                                content: `
                                    <div class="d-flex flex-column align-items-center mr-4" type="button">
                                        <svg width="100" height="100" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.333328" width="96" height="96" rx="21" fill="#E0F3FF"/>
                                            <circle cx="57.3333" cy="33" r="9" fill="#99D6FF"/>
                                            <path d="M78.3333 58.5C78.3333 64.299 78.3333 69 57.3333 69C36.3333 69 36.3333 64.299 36.3333 58.5C36.3333 52.701 45.7353 48 57.3333 48C68.9313 48 78.3333 52.701 78.3333 58.5Z" fill="#99D6FF"/>
                                            <circle cx="42.3333" cy="30" r="12" fill="#008DEB"/>
                                            <path d="M66.3333 64.5C66.3333 71.9558 66.3333 78 42.3333 78C18.3333 78 18.3333 71.9558 18.3333 64.5C18.3333 57.0442 29.0785 51 42.3333 51C55.5882 51 66.3333 57.0442 66.3333 64.5Z" fill="#008DEB"/>
                                        </svg>
                                        <span class="${prefix}_item_photo_page ${prefix}_item_page">Группы</span>
                                    </div>
                                    <div class="d-flex flex-column align-items-center" type="button">
                                        <svg width="100" height="100" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.333313" width="96" height="96" rx="21" fill="#E0F3FF"/>
                                            <path d="M27.1039 68.3088C31.017 63.0598 33.3333 56.5504 33.3333 49.5C33.3333 41.0356 29.9948 33.3511 24.5628 27.6912C20.6496 32.9401 18.3333 39.4495 18.3333 46.5C18.3333 54.9643 21.6718 62.6489 27.1039 68.3088Z" fill="#99D6FF"/>
                                            <path d="M71.6424 69.2295C65.9824 74.6615 58.2979 78 49.8335 78C42.7831 78 36.2737 75.6837 31.0247 71.7705C36.6846 66.3385 44.3692 63 52.8335 63C59.884 63 66.3934 65.3163 71.6424 69.2295Z" fill="#99D6FF"/>
                                            <path d="M30.8292 74.9229C27.2416 57.8999 38.1332 41.1917 55.1562 37.6041" stroke="#008DEB" stroke-width="4" stroke-linecap="round"/>
                                            <circle cx="58.8333" cy="37.5" r="19.5" fill="#008DEB"/>
                                            <circle cx="60.3333" cy="36" r="12" fill="#99D6FF"/>
                                            <circle cx="61.8333" cy="34.5" r="7.5" fill="#008DEB"/>
                                        </svg>
                                        <span class="${prefix}_item_photo_page ${prefix}_item_page">Знакомства</span>
                                    </div>
                                `
                            }
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                     }
                     .${prefix}_go_to_page {
                        border-radius: 50%;
                        padding: 10px 11px;
                        background: hsla(0, 0%, 0%, 0.12);
                        display: flex;
                     }
                     .${prefix}_items_content {
                        display: flex;
                        flex-direction: row;
                     }
                     .${prefix}_item_page {
                        color: hsla(0, 0%, 0%, 0.54);
                        font-size: 12px;
                        font-weight: 600;
                     }
                `
            }
        }
    });
};