import {bindingHandlers} from 'knockout';

bindingHandlers.custom_video_player = {
    init: function(element) {

        const player_container = element;

        const player = player_container.querySelector('#custom-video-player');

        const changeSeekbarValue = (elem, value) => elem.style.setProperty('--value', value);

        /*style seekbar*/
        if (player_container.querySelectorAll('input[type="range"].slider-progress')) {
            for (let e of player_container.querySelectorAll('input[type="range"].slider-progress')) {
                changeSeekbarValue(e, e.value);
                e.style.setProperty('--min', e.min === '' ? '0' : e.min);
                e.style.setProperty('--max', e.max === '' ? '100' : e.max);
            }
        }

        /*play/pause video*/
        const play_btn = player_container.querySelector('.planet-btn-play-music');
        play_btn.addEventListener('click', () => play_btn.getAttribute('data-action') === 'play' ? player.play() : player.pause());
        player.addEventListener('playing', () => play_btn.setAttribute('data-action', 'pause'));
        player.addEventListener('pause', () => play_btn.setAttribute('data-action', 'play'));

        /*rewind video*/
        const back_btn = player_container.querySelector('.planet-rewind-back');
        const forward_btn = player_container.querySelector('.planet-rewind-forward');

        if (back_btn && forward_btn) {
            back_btn.addEventListener('click', () => {
                player.currentTime = player.currentTime -= 30;
            });
            forward_btn.addEventListener('click', () => {
                player.currentTime = player.currentTime += 30;
            });
        }

        /*seekbar video*/
        const progressBar = player_container.querySelector('.planet-main-seekbar');

        player.addEventListener('timeupdate', updateProgressBar, false);

        ['click', 'touchmove', 'touchstart'].forEach(event => progressBar.addEventListener(event, seek));

        function updateProgressBar() {
            let percentage = Math.floor((100 / player.duration) * player.currentTime);
            progressBar.value = percentage;
            changeSeekbarValue(progressBar, percentage);
        }

        function seek(e) {
            let percent = 0;
            if (e.type === 'touchmove' || e.type === 'touchstart') {
                percent = e.touches[0].clientX / this.offsetWidth;
            } else {
                percent = e.offsetX / this.offsetWidth;
            }

            player.currentTime = percent * player.duration;
            e.target.value = Math.floor(percent / 100);
        }

        /*seekbar value volume video*/
        const progressBarVolume = player_container.querySelector('.planet-volume-seekbar');
        progressBarVolume.value = player.volume * 100;
        changeSeekbarValue(progressBarVolume, player.volume * 100);
        progressBarVolume.addEventListener('input', (e) => {
            player.volume = e.currentTarget.value / 100;
            changeSeekbarValue(progressBarVolume, e.currentTarget.value);
        });

        /*mute video*/
        const mute_btn = player_container.querySelector('.planet-mute-btn');
        if (mute_btn) {
            mute_btn.addEventListener('click', () => player.muted = mute_btn.getAttribute('data-action') === 'not-sound' ? player.muted = true : player.muted = false);
            player.addEventListener('volumechange', () => mute_btn.setAttribute('data-action', player.muted ? 'sound' : 'not-sound') ,false);
        }

        /*duration video*/
        const currentDuration = player_container.querySelector('.planet-current-time');
        const allDuration = player_container.querySelector('.planet-all-time');
        player.addEventListener('loadeddata', (e) => {
            let videoDuration = e.target.duration;
            let totalMin = Math.floor(videoDuration / 60);
            let totalSec = Math.floor(videoDuration % 60);

            totalSec = totalSec < 10 ? `0${totalSec}` : totalSec;
            allDuration.textContent = `${totalMin}:${totalSec}`;
        });
        player.addEventListener('timeupdate', (e) => {
            let currentVideoTime = e.target.currentTime;
            let currentMin = Math.floor(currentVideoTime / 60);
            let currentSec = Math.floor(currentVideoTime % 60);
            currentSec = currentSec < 10 ? `0${currentSec}` : currentSec;
            currentDuration.textContent = `${currentMin}:${currentSec}`;
        });

        /*fullscreen video*/
        let btn_fullscreen = player_container.querySelector('.planet-fullscreen');
        if (btn_fullscreen) {
            btn_fullscreen.addEventListener('click', () => {
                if (player.requestFullscreen) {
                    player.requestFullscreen();
                }else if (player.mozRequestFullScreen) {
                    player.mozRequestFullScreen();
                }else if (player.webkitRequestFullscreen) {
                    player.webkitRequestFullscreen();
                }else if (player.msRequestFullscreen) {
                    player.msRequestFullscreen();
                }else{
                    console.log("Full screen not supported");
                    return;
                }
            });
        }
    }
};
