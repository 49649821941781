export const OneColumn = ({editor}) => {
    const prefix = 'one_column';

    editor.Blocks.add(prefix, {
        media: `
            <div style="width: 65px; height: 65px;display: flex;justify-content: center;align-items: center;">
                <div style="padding: 24px 8px; width: 40px; height: 25px;background: #D1EDFF;border: 2px dashed #0099FF;border-radius: 5px;"></div>
            </div> 
        `,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: 'Колонка'},
        label: '1 колонка'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                attributes: {
                    class: prefix
                },
                styles: `
                    .${prefix} {
                        position: relative;
                        border-radius: 10px;
                        background: white;
                        width: 100%;
                        height: 100%;
                        padding: 10px;
                        min-height: 400px;
                        display: table;
                    }
                `
            }
        }
    });
};
