export const BusinessAvatarCompanyWidget = ({editor}) => {// jshint ignore:line
    const prefix = 'avatar_company';

    function script() {
        document.querySelector(`.avatar_company_select_content`).addEventListener('click', () => {
            document.querySelector('.avatar_company_input_image').click();
        });

        document.querySelector('.avatar_company_input_image').addEventListener('change', function (event) {
            var target = event.target;

            if (!FileReader) {
                alert('FileReader не поддерживается — облом');
                return;
            }

            if (!target.files.length) {
                alert('Ничего не загружено');
                return;
            }

            var fileReader = new FileReader();
            fileReader.onload = () => {
                document.querySelector('.avatar_company_card').style.backgroundImage = `url(${fileReader.result})`;
                document.querySelector('.avatar_company_card_clear').style.display = 'block';
                document.querySelector(`.avatar_company_select_content`).style.display = 'none';
            };

            fileReader.readAsDataURL(target.files[0]);
        });

        document.querySelector('.avatar_company_card_clear').addEventListener('click', function() {
            document.querySelector(`.avatar_company_select_content`).style.display = 'flex';
            document.querySelector('.avatar_company_card_clear').style.display = 'none';
            document.querySelector('.avatar_company_card').style.backgroundImage = `url(https://www.shutterstock.com/image-photo/happy-middle-aged-business-man-600nw-2306186897.jpg)`;
        });
    }

    editor.Blocks.add(prefix, {
        media: `
            <svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9426 9.25H20.0574C22.3658 9.24999 24.1748 9.24998 25.5863 9.43975C27.031 9.63399 28.1711 10.0393 29.0659 10.9341C29.9607 11.8289 30.366 12.969 30.5603 14.4137C30.75 15.8252 30.75 17.6342 30.75 19.9426V20.0309C30.75 21.9397 30.75 23.5023 30.6463 24.7745C30.5422 26.0531 30.3287 27.1214 29.8509 28.0087C29.6401 28.4001 29.3812 28.7506 29.0659 29.0659C28.1711 29.9607 27.031 30.366 25.5863 30.5603C24.1748 30.75 22.3658 30.75 20.0574 30.75H19.9426C17.6342 30.75 15.8252 30.75 14.4137 30.5603C12.969 30.366 11.8289 29.9607 10.9341 29.0659C10.1409 28.2726 9.7312 27.2852 9.51335 26.0604C9.29935 24.8573 9.2602 23.3603 9.25207 21.5015C9.25 21.0287 9.25 20.5286 9.25 20.001L9.25 19.9426C9.24999 17.6342 9.24998 15.8252 9.43975 14.4137C9.63399 12.969 10.0393 11.8289 10.9341 10.9341C11.8289 10.0393 12.969 9.63399 14.4137 9.43975C15.8252 9.24998 17.6342 9.24999 19.9426 9.25ZM14.6136 10.9264C13.3352 11.0982 12.5645 11.4251 11.9948 11.9948C11.4251 12.5645 11.0982 13.3352 10.9264 14.6136C10.7516 15.9136 10.75 17.6218 10.75 20C10.75 20.5287 10.75 21.0257 10.7521 21.4949C10.7603 23.369 10.8021 24.7406 10.9902 25.7978C11.1744 26.8333 11.4877 27.4981 11.9948 28.0052C12.5645 28.5749 13.3352 28.9018 14.6136 29.0736C15.9136 29.2484 17.6218 29.25 20 29.25C22.3782 29.25 24.0864 29.2484 25.3864 29.0736C26.6648 28.9018 27.4355 28.5749 28.0052 28.0052C28.2151 27.7953 28.3872 27.5631 28.5302 27.2976C28.8619 26.6816 29.0531 25.8578 29.1513 24.6527C29.2494 23.4482 29.25 21.9459 29.25 20C29.25 17.6218 29.2484 15.9136 29.0736 14.6136C28.9018 13.3352 28.5749 12.5645 28.0052 11.9948C27.4355 11.4251 26.6648 11.0982 25.3864 10.9264C24.0864 10.7516 22.3782 10.75 20 10.75C17.6218 10.75 15.9136 10.7516 14.6136 10.9264Z" fill="#008DEB"/>
                <path d="M29.2487 21.2804L29.0266 21.2497C26.1828 20.8559 23.5805 22.3343 22.2554 24.5626C20.5459 20.2376 16.0284 17.2881 10.9807 18.0129L10.755 18.0454C10.7501 18.6421 10.75 19.2913 10.75 20C10.75 20.5287 10.75 21.0257 10.7521 21.4949C10.7603 23.369 10.8021 24.7406 10.9902 25.7978C11.1744 26.8333 11.4877 27.4981 11.9948 28.0052C12.5645 28.5749 13.3352 28.9018 14.6136 29.0736C15.9136 29.2484 17.6218 29.25 20 29.25C22.3782 29.25 24.0864 29.2484 25.3864 29.0736C26.6648 28.9018 27.4355 28.5749 28.0052 28.0052C28.2151 27.7953 28.3872 27.5631 28.5302 27.2976C28.8619 26.6816 29.0531 25.8578 29.1513 24.6527C29.2267 23.7267 29.2445 22.6247 29.2487 21.2804Z" fill="#99D6FF"/>
                <path d="M26 16C26 17.1046 25.1046 18 24 18C22.8954 18 22 17.1046 22 16C22 14.8954 22.8954 14 24 14C25.1046 14 26 14.8954 26 16Z" fill="#008DEB"/>
            </svg>
        `,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Основная фотография'
    });
    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_card'
                },
                components: [
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_card_content ' + prefix + '_select_content'
                        },
                        content: `
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9426 9.25H20.0574C22.3658 9.24999 24.1748 9.24998 25.5863 9.43975C27.031 9.63399 28.1711 10.0393 29.0659 10.9341C29.9607 11.8289 30.366 12.969 30.5603 14.4137C30.75 15.8252 30.75 17.6342 30.75 19.9426V20.0309C30.75 21.9397 30.75 23.5023 30.6463 24.7745C30.5422 26.0531 30.3287 27.1214 29.8509 28.0087C29.6401 28.4001 29.3812 28.7506 29.0659 29.0659C28.1711 29.9607 27.031 30.366 25.5863 30.5603C24.1748 30.75 22.3658 30.75 20.0574 30.75H19.9426C17.6342 30.75 15.8252 30.75 14.4137 30.5603C12.969 30.366 11.8289 29.9607 10.9341 29.0659C10.1409 28.2726 9.7312 27.2852 9.51335 26.0604C9.29935 24.8573 9.2602 23.3603 9.25207 21.5015C9.25 21.0287 9.25 20.5286 9.25 20.001L9.25 19.9426C9.24999 17.6342 9.24998 15.8252 9.43975 14.4137C9.63399 12.969 10.0393 11.8289 10.9341 10.9341C11.8289 10.0393 12.969 9.63399 14.4137 9.43975C15.8252 9.24998 17.6342 9.24999 19.9426 9.25ZM14.6136 10.9264C13.3352 11.0982 12.5645 11.4251 11.9948 11.9948C11.4251 12.5645 11.0982 13.3352 10.9264 14.6136C10.7516 15.9136 10.75 17.6218 10.75 20C10.75 20.5287 10.75 21.0257 10.7521 21.4949C10.7603 23.369 10.8021 24.7406 10.9902 25.7978C11.1744 26.8333 11.4877 27.4981 11.9948 28.0052C12.5645 28.5749 13.3352 28.9018 14.6136 29.0736C15.9136 29.2484 17.6218 29.25 20 29.25C22.3782 29.25 24.0864 29.2484 25.3864 29.0736C26.6648 28.9018 27.4355 28.5749 28.0052 28.0052C28.2151 27.7953 28.3872 27.5631 28.5302 27.2976C28.8619 26.6816 29.0531 25.8578 29.1513 24.6527C29.2494 23.4482 29.25 21.9459 29.25 20C29.25 17.6218 29.2484 15.9136 29.0736 14.6136C28.9018 13.3352 28.5749 12.5645 28.0052 11.9948C27.4355 11.4251 26.6648 11.0982 25.3864 10.9264C24.0864 10.7516 22.3782 10.75 20 10.75C17.6218 10.75 15.9136 10.7516 14.6136 10.9264Z" fill="#008DEB"/>
                                <path d="M29.2487 21.2804L29.0266 21.2497C26.1828 20.8559 23.5805 22.3343 22.2554 24.5626C20.5459 20.2376 16.0284 17.2881 10.9807 18.0129L10.755 18.0454C10.7501 18.6421 10.75 19.2913 10.75 20C10.75 20.5287 10.75 21.0257 10.7521 21.4949C10.7603 23.369 10.8021 24.7406 10.9902 25.7978C11.1744 26.8333 11.4877 27.4981 11.9948 28.0052C12.5645 28.5749 13.3352 28.9018 14.6136 29.0736C15.9136 29.2484 17.6218 29.25 20 29.25C22.3782 29.25 24.0864 29.2484 25.3864 29.0736C26.6648 28.9018 27.4355 28.5749 28.0052 28.0052C28.2151 27.7953 28.3872 27.5631 28.5302 27.2976C28.8619 26.6816 29.0531 25.8578 29.1513 24.6527C29.2267 23.7267 29.2445 22.6247 29.2487 21.2804Z" fill="#99D6FF"/>
                                <path d="M26 16C26 17.1046 25.1046 18 24 18C22.8954 18 22 17.1046 22 16C22 14.8954 22.8954 14 24 14C25.1046 14 26 14.8954 26 16Z" fill="#008DEB"/>
                            </svg>
                            <span>Установите основную фотографию бизнес-страницы</span>
                            <input type="file" hidden="hidden" accept="image/*" class="${prefix}_input_image">
                        `
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_card_clear'
                        },
                        content: `&#215;`
                    }
                ],
                styles: `
                     .${prefix}_card {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-image: url(https://www.shutterstock.com/image-photo/happy-middle-aged-business-man-600nw-2306186897.jpg);
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 10px;
                        background-size: cover;
                        
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .${prefix}_card_content {
                         display: flex;
                         align-items: center;
                         flex-direction: column;
                         cursor: pointer;
                    }
                    .${prefix}_card_content span {
                        color: hsla(204, 100%, 37%, 1);
                        background: #E0F3FF;
                        border-radius: 10px;
                        width: 80%;
                        text-align: center;
                        padding: 8px 10px 20px;
                        font-size: 16px;
                        font-weight: 600;
                        margin-top: 16px;
                    }
                    .${prefix}_card_clear {
                        color: hsla(204, 100%, 37%, 1);
                        background: #E0F3FF;
                        border-radius: 50%;
                        padding: 4px 12px 5px;
                        font-size: 20px;
                        font-weight: 800;
                        cursor: pointer;
                        display: none;
                        
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                `
            }
        }
    });
};
