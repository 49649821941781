export const Spacer = ({editor}) => {
    const prefix = 'spacer';

    editor.Blocks.add(prefix, {
        media: `
            <svg width="65" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 26H63" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                <path d="M25 30H63" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                <path d="M25 62H63" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                <path d="M25 58H63" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                <path d="M40.6084 38C40.0675 38 39.7966 37.432 40.1791 37.0998L43.5707 34.1544C43.8078 33.9485 44.1922 33.9485 44.4293 34.1544L47.8209 37.0998C48.2034 37.432 47.9325 38 47.3916 38H45V41C45 41.5523 44.5523 42 44 42C43.4477 42 43 41.5523 43 41V38H40.6084Z" fill="#0099FF"/>
                <path d="M40.6084 50C40.0675 50 39.7966 50.568 40.1791 50.9002L43.5707 53.8456C43.8078 54.0515 44.1922 54.0515 44.4293 53.8456L47.8209 50.9002C48.2034 50.568 47.9325 50 47.3916 50H45V47C45 46.4477 44.5523 46 44 46C43.4477 46 43 46.4477 43 47V50H40.6084Z" fill="#0099FF"/>
            </svg> 
        `,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix, name: 'Пробел'},
        label: 'Пробел'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=container]',
                attributes: {
                    class: prefix + '_'
                },
                styles: `
                    .${prefix}_ {
                        background: #f3f5f5;
                        height: 20px;
                        width: 100%;
                        margin: 30px 0;
                    }
                `
            }
        }
    });
};
