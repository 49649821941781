import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import intlTelInput from 'intl-tel-input';
import 'styles/style_old.min.css';

export default class company_reg extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        if (Store.getState().auth.value)Router.navigate('/my_page');
        this.numberBlock = ko.observable('start');
        this.showPassword = ko.observable(false);
        this.showPasswordRepeat = ko.observable(false);
        this.checkSMS = ko.observable(false);
        this.checkMail = ko.observable(false);
        this.errorAdd = ko.observable('');
        this.SectionOPF = ko.observable('');
        this.tin = ko.observable('');
        this.name = ko.observable('');
        this.address = ko.observable('');
        this.phone = ko.observable('');
        this.truePhone = ko.observable('');
        this.email = ko.observable('');
        this.errorSMS = ko.observable('');
        this.errorEmail = ko.observable('');
        this.errorPhone = ko.observable('');
        this.sms = ko.observable('');
        this.mail_code = ko.observable('');
        this.password = ko.observable('');
        this.password_repeat = ko.observable('');
        this.passwordEmpty = ko.observable(false);
        this.passwordLength = ko.observable(true);
        this.passwordLat = ko.observable(true);
        this.passwordNumber = ko.observable(true);
        this.passwordSymbol = ko.observable(true);
        this.validatePhone = ko.observable(false);
        this.showsecMail = ko.observable(0);
        this.showsecSMS = ko.observable(0);
        this.stopsend = ko.observable(0);
        this.companies = ko.observable([]);
        this.successResult = ko.observable(false);
        this.timer = false;

        this.passwordRequired = ko.computed(function() {
            if(this.str_rot13()!== ''){
                this.passwordEmpty(false);
            }else{
                this.passwordEmpty(true);
            }

            if(this.str_rot13().length < 8){
                this.passwordLength(true);
            }else{
                this.passwordLength(false);
            }

            if(!/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*[a-zA-Z]+[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/.test(this.str_rot13())){
                this.passwordLat(true);
            }else{
                this.passwordLat(false);
            }

            if(!/\d/.test(this.str_rot13())){
                this.passwordNumber(true);
            }else{
                this.passwordNumber(false);
            }

            if(!/[!@#$%^&*(),.?":{}|<>]/.test(this.str_rot13())){
                this.passwordSymbol(true);
            }else{
                this.passwordSymbol(false);
            }

            return !(this.passwordEmpty()===false && this.passwordLength()===false && this.passwordLat()===false && this.passwordNumber()===false && this.passwordSymbol()===false);

        }, this);

        this.first_point = document.querySelector(".first_point");
        this.second_point = document.querySelector(".second_point");
        this.last_point = document.querySelector(".last_point");
        this.progress__container = document.querySelector(".progress__container");
        this.progress__container_sm = document.querySelector(".sm_only .progress__container");
        this.type_info = document.querySelector(".type_info");
        this.user_info = document.querySelector(".user_info");
        this.contact_info = document.querySelector(".contact_info");
        this.pass_info = document.querySelector(".pass_info");
        this.progress_lg = document.querySelector(".progress.sm_hidden");

        this.smsRevText = ko.observable(true);
        document.addEventListener( 'keyup', event => {
            if( event.code === 'Enter' ) {
                document.querySelector(" .next_btn").click();
            }
        });
        const inputPhone = document.querySelector('#phone');
        const iti = intlTelInput(inputPhone, {
            preferredCountries: ['ru', 'us'],
            autoPlaceholder: 'polite',
            nationalMode: false,
            initialCountry: 'RU',
            placeholderNumberType: 'MOBILE',
            utilsScript:
                'js/utils.js',
        });
        inputPhone.addEventListener('keyup',  () => {
            if (iti.isValidNumber()) {
                this.truePhone(window.intlTelInputGlobals.getInstance(inputPhone).getNumber());
                this.validatePhone(true);
            } else {
                this.truePhone('');
                this.validatePhone(false);
            }
        });
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    Event(fieldName) {
        const _self = this;

        return {
            keyup: (data, event) => {
                let value = event.target.value;
                if (fieldName === 'validateTIN') {

                    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
                    const token = "c29ef8de4b26793f2ceb49e75d6c140fa5eaaae0";
                    const query = value.toString();

                    const options = {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Token " + token
                        },
                        body: JSON.stringify({query: query})
                    };

                    this.debounce(0, () => {
                        fetch(url, options)
                            .then(response => response.json())
                            .then(result =>  {
                                if (result.suggestions.length > 1) {
                                    _self.companies(result.suggestions);
                                } else if(result.suggestions.length === 1 && Number(result.suggestions[0].data.inn) === Number(event.target.value)) {
                                    _self.name(result.suggestions[0].value);
                                    _self.address(result.suggestions[0].data.address.value);
                                } else {
                                    _self.name('');
                                    _self.address('');
                                }

                            })
                            .catch(error => console.log("error", error));
                    });

                }
            },
            change(data, event) {
                let value = event.target.value;
                if (fieldName === 'validateTIN') {
                    let company = _self.companies().filter(com => parseInt(com.data.inn) === parseInt(value))[0];
                    if (company) {
                        _self.name(company.value);
                        _self.address(company.data.address.value);
                    }
                }
            }
        };
    }


    showStartStep() {
        this.numberBlock('start');
        this.changeStateFunction('steps_start');
        this.first_point.className = "progress__points__item first_point";
        this.second_point.className = "progress__points__item second_point";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info";
        this.contact_info.className = "progress_name__item contact_info";
        this.type_info.className = "progress_name__item type_info showed active";
        this.pass_info.className = "progress_name__item pass_info";

        this.progress__container.style.width = "0";
        this.progress__container_sm.style.width = "0";
        this.progress_lg.style.display = 'none';
    }
    showFirstStep() {
        this.numberBlock('first');
        this.changeStateFunction('steps_first');
        this.first_point.className = "progress__points__item first_point showed active";
        this.second_point.className = "progress__points__item second_point";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info active showed";
        this.contact_info.className = "progress_name__item contact_info";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info";

        this.progress__container.style.width = "0%";
        this.progress__container_sm.style.width = "30%";
        this.progress_lg.style.display = 'block';
    }
    showPhoneStep() {
        this.numberBlock('phone');
        this.changeStateFunction('phone');

        this.first_point.className = "progress__points__item first_point active checked";
        this.second_point.className = "progress__points__item second_point  active showed";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info active";
        this.contact_info.className = "progress_name__item contact_info showed active";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info";


        this.progress__container.style.width = "50%";
        this.progress__container_sm.style.width = "70%";
        this.progress_lg.style.display = 'block';
    }
    showSMSStep() {
        this.numberBlock('sms');

        this.first_point.className = "progress__points__item first_point active checked";
        this.second_point.className = "progress__points__item second_point  active showed";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info active";
        this.contact_info.className = "progress_name__item contact_info showed active";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info";


        this.progress__container.style.width = "50%";
        this.progress__container_sm.style.width = "70%";
        this.progress_lg.style.display = 'block';
    }
    showEmailStep() {
        this.numberBlock('email');
        this.changeStateFunction('email');

        this.first_point.className = "progress__points__item first_point active checked";
        this.second_point.className = "progress__points__item second_point  active showed";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info active";
        this.contact_info.className = "progress_name__item contact_info showed active";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info";


        this.progress__container.style.width = "50%";
        this.progress__container_sm.style.width = "70%";
        this.progress_lg.style.display = 'block';
    }
    showCheckEmailStep() {
        this.numberBlock('check_email');

        this.first_point.className = "progress__points__item first_point active checked";
        this.second_point.className = "progress__points__item second_point  active showed";
        this.last_point.className = "progress__points__item last_point";

        this.user_info.className = "progress_name__item user_info active";
        this.contact_info.className = "progress_name__item contact_info showed active";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info";


        this.progress__container.style.width = "50%";
        this.progress__container_sm.style.width = "70%";
        this.progress_lg.style.display = 'block';
    }
    showLastStep() {
        this.numberBlock('last');
        this.changeStateFunction('steps_last');


        this.first_point.className = "progress__points__item first_point checked active";
        this.second_point.className = "progress__points__item second_point checked active";
        this.last_point.className = "progress__points__item last_point showed active";

        this.user_info.className = "progress_name__item user_info active";
        this.contact_info.className = "progress_name__item contact_info";
        this.type_info.className = "progress_name__item type_info";
        this.pass_info.className = "progress_name__item pass_info showed";


        this.progress__container.style.width = "100%";
        this.progress__container_sm.style.width = "100%";
        this.progress_lg.style.display = 'block';
    }

    changeState(param) {

        let activeItem = document.querySelector(".steps_block .active");

        if (activeItem) {
            let nextItem = param === 'prev' ? activeItem.previousElementSibling : document.querySelector("." + param);

            if (nextItem) {
                activeItem.classList.remove("active");
                nextItem.classList.add("active");

                switch (true) {
                    case nextItem.classList.contains("steps_start"):
                        this.showStartStep();
                        break;
                    case nextItem.classList.contains("steps_first"):
                        this.showFirstStep();
                        break;
                    case nextItem.classList.contains("step_phone"):
                        this.showPhoneStep();
                        break;
                    case nextItem.classList.contains("step_sms"):
                        this.showSMSStep();
                        break;
                    case nextItem.classList.contains("step_email"):
                        this.showEmailStep();
                        break;
                    case nextItem.classList.contains("step_check_email"):
                        this.showCheckEmailStep();
                        break;
                    case nextItem.classList.contains("steps_last"):
                        this.showLastStep();
                        break;
                }
            }
        }

    }
    changeStateFunction (number) {
        switch (number) {
            case "start":
                return !!this.SectionOPF();
            case "first":
                return !!this.tin() && this.validateTIN() && !!this.name() && !!this.address();
            case "phone":
                return !!this.truePhone() && this.validatePhone();
            case "email":
                return !!this.email() && this.validateEmail();
            case "default":
                return !!this.truePhone() && this.validatePhone() && !!this.email() && this.validateEmail() && !!this.checkSMS() && !!this.checkMail();
            case "last":
                return !this.passwordRequired()  && !!this.password() && this.password() === this.password_repeat();
        }
    }

    sendSMSRequest() {
        this.Server
            .Request('add_phone_sms', { phone: this.truePhone().replace(/\D/g, '') })
            .then(res=>{
                let result = JSON.parse(res);
                if(result.success){
                    this.errorPhone('');
                    this.smsRevText(false);
                    this.changeState('step_sms');

                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");
                    this.smsRevText(false);

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecSMS( );
                    this.SMSCountdown.call(this,seconds);
                }
            }).catch(error=>{
            this.errorPhone(this.i18next.t(error));
            return false;
        });
    }
    sendCALLRequest() {
        this.Server
            .Request('add_phone_call', { phone: this.truePhone().replace(/\D/g, '') })
            .then(res=>{

                let result = JSON.parse(res);

                if(result.success){
                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecSMS( );
                    this.SMSCountdown.call(this,seconds);
                    this.changeState('step_sms');
                    this.errorPhone('');
                }

            }).catch(error=>{
            if (error === 'get_sms') {
                return this.sendSMSRequest();
            } else {
                this.errorPhone(this.i18next.t(error));
                return false;
            }
        });
    }
    validateSMS () {
        if( this.sms() === '') {
            this.errorSMS(this.i18next.t('code cannot be empty'));
            return false;
        }
        const _self = this;
        this.Server
            .Request('code_check', { phone: this.truePhone().replace(/\D/g, ''), code: this.sms() })
            .then(res=>{
                res = JSON.parse(res);

                if(res.success){
                    this.checkSMS(true);
                    this.errorSMS('');
                    _self.changeState('step_email');
                }
            })
            .catch(e=>{
                this.checkSMS(false);
                this.errorSMS(this.i18next.t(e));
            });
    }

    sendEmailRequest() {
        this.Server
            .Request('add_email', {email: this.email()})
            .then(res=>{
                let result = JSON.parse(res);

                if(result.success){
                    let sendEmail = document.getElementById("sendEmail");
                    let waitSendEmail = document.getElementById("waitSendEmail");

                    if (sendEmail !== null && waitSendEmail !== null) {
                        sendEmail.style.display = 'none';
                        waitSendEmail.style.display = 'block';
                    }
                    this.errorEmail('');
                    this.stopsend(0);
                    this.showsecMail(60);
                    const seconds = Math.floor(new Date().getTime()/1000) + this.showsecMail( );
                    this.mailCountdown.call(this,seconds);
                    this.changeState('step_check_email');
                }
            }).catch(error=>{
            this.errorEmail(this.i18next.t(error));
            return false;
        });
    }

    SMSCountdown(stopsec){
        let seconds = Math.round(new Date().getTime()/1000);
        let sendSMS = document.getElementById("sendSMS");
        let waitSendSMS = document.getElementById("waitSendSMS");
        let waitSecSMS = document.getElementById("waitSecSMS");

        this.showsecSMS(stopsec - seconds);
        if ((this.showsecSMS() <= 0 || this.stopsend() === 1) && (sendSMS !== null && waitSendSMS !== null) ){
            sendSMS.style.display = 'block';
            waitSendSMS.style.display = 'none';
            return;
        }
        if (waitSecSMS !== null) {
            waitSecSMS.innerHTML = this.showsecSMS();
        }
        const _self  = this;
        setTimeout(function(){
            _self.SMSCountdown.call(this,stopsec);
        }.bind(_self), 1000);
    }
    mailCountdown(stopsec){
        let seconds = Math.round(new Date().getTime()/1000);
        let sendEmail = document.getElementById("sendEmail");
        let waitSendEmail = document.getElementById("waitSendEmail");
        let waitSecEmail = document.getElementById("waitSecEmail");
        this.showsecMail(stopsec - seconds);
        if ((this.showsecMail() <= 0 || this.stopsend() === 1) &&(sendEmail !== null && waitSendEmail !== null)) {
            sendEmail.style.display = 'block';
            waitSendEmail.style.display = 'none';
            return;
        }
        if (waitSecEmail !== null) {
            waitSecEmail.innerHTML = this.showsecMail();
        }
        const _self  = this;
        setTimeout(function(){
            _self.mailCountdown.call(this,stopsec);
        }.bind(_self), 1000);
    }

    EventPassword() {
        const _self = this;
        return {
            keyup: function(d, event){
                _self.password(event.currentTarget.value);
            }
        };
    }
    str_rot13(){
        return this.password().replace(/[a-zA-Z]/g, function(c){
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }
    checkPasswordRepeat() {
        return this.password() && this.password() === this.password_repeat();
    }

    validateTIN () {
        return /^[\d+]{10,12}$/.test(this.tin());
    }

    validateEmail () {
        return /@[^.]+\.\w/.test(this.email());
    }

    validateMail () {
        if( this.mail_code() === '') {
            this.errorEmail(this.i18next.t('code cannot be empty'));
            return false;
        }
        const _self = this;
        this.Server
            .Request('check_email', {email: this.email(),code: this.mail_code()})
            .then(res=>{
                res = JSON.parse(res);
                if (res.success){
                    this.checkMail(true);
                    this.errorEmail('');
                    _self.changeState('steps_last');
                }
            }).catch(error=>{
            this.errorEmail(this.i18next.t(error));
            this.checkMail(false);
        });
    }

    sendForm() {
        this.Server
            .Request('add_auth', { pass: this.password() })
                .then(()=>Promise.all([
                    this.Server.Request('confirm_phone', {
                        phone:this.truePhone().replace(/\D/g, ''),
                        code:this.sms()
                    }),
                    this.Server.Request('confirm_email', {
                        email: this.email(),
                        code:this.mail_code()
                    }),
                    this.Server.Request('company_registration', {
                        opf: this.SectionOPF(),
                        tin: this.tin(),
                        name: this.name(),
                        address: this.address()
                    }),
                    this.Server.Request('add_settings', {
                        lng: localStorage.getItem('lng')?localStorage.getItem('lng'):'ru',
                        type_profile: 2
                    })
                ]).then((result) => {
                    result.forEach((element) => {
                        let res = JSON.parse(element);
                        if (res.error) {
                            this.errorAdd(this.i18next.t('save_company_error'));
                        }
                    });
                    if (!this.errorAdd()) {
                        this.Store.dispatch({ type: 'auth/set', payload:true });
                        this.successResult(true);
                        document.querySelector(".main-container").className = 'main main-container success_page';
                        document.querySelector(".modal_success").className = 'modal_success visible';
                    } else {
                        alert(this.errorAdd());
                    }
                })).catch(e=> {
                    console.log(e);
                    this.errorAdd(this.i18next.t(e));
                });
    }


}
