import home from 'PagesTwigs/home.twig';
import company_reg from 'PagesTwigs/company_reg.twig';
import login from 'PagesTwigs/login.twig';
import reg_account_choose from 'PagesTwigs/reg_account_choose.twig';
import remember from 'PagesTwigs/remember.twig';
import user_reg from 'PagesTwigs/user_reg.twig';
import web_constructor from 'PagesTwigs/web_constructor.twig';
import web_constructors from 'PagesTwigs/web_constructors.twig';
import profile from 'PagesTwigs/profile.twig';
import chat from 'PagesTwigs/chat.twig';
import group_chat from 'PagesTwigs/group_chat.twig';
import chat_link_modal from 'PagesTwigs/chat_link_modal.twig';
import note from 'PagesTwigs/note.twig';
import notes_deleted from 'PagesTwigs/notes_deleted.twig';
import notes_remind from 'PagesTwigs/notes_remind.twig';
import community_layout from 'PagesTwigs/community_layout.twig';
import company_layout from 'PagesTwigs/company_layout.twig';
import community_edit from 'PagesTwigs/community_edit.twig';
import community_page from 'PagesTwigs/community_page.twig';
import my_page from 'PagesTwigs/my_page.twig';
import career from 'PagesTwigs/career.twig';
import election from 'PagesTwigs/election.twig';
import event from 'PagesTwigs/event.twig';
import petition_item from 'PagesTwigs/petition_item.twig';
import petitions from 'PagesTwigs/petitions.twig';
import polls from 'PagesTwigs/polls.twig';
import referendum from 'PagesTwigs/referendum.twig';
import sample_party from 'PagesTwigs/sample_party.twig';
import voting from 'PagesTwigs/voting.twig';
import articles_page from "PagesTwigs/articles_page.twig";
import videos_page from "PagesTwigs/videos_page.twig";
import my_videos_page from "PagesTwigs/my_videos_page.twig";
import video_single from "PagesTwigs/video_single.twig";
import social_main from "PagesTwigs/social_main.twig";
import bookmarks from "PagesTwigs/bookmarks.twig";
import playlist_video from "PagesTwigs/playlist_video.twig";
import playlists_video from "PagesTwigs/playlists_video.twig";
import music from "PagesTwigs/music.twig";
import communities from "PagesTwigs/communities.twig";
import communities_category from "PagesTwigs/communities_category.twig";
import communities_control from "PagesTwigs/communities_control.twig";
import music_albums from "PagesTwigs/music_albums.twig";
import music_playlists from "PagesTwigs/music_playlists.twig";
import music_recently_listener from "PagesTwigs/music_recently_listener.twig";
import music_saved from "PagesTwigs/music_saved.twig";
import profile_setting from "PagesTwigs/profile_setting.twig";
import profile_verification from "PagesTwigs/profile_verification.twig";
import profile_security from "PagesTwigs/profile_security.twig";
import profile_personal from "PagesTwigs/profile_personal.twig";
import profile_notices from "PagesTwigs/profile_notices.twig";
import profile_general from "PagesTwigs/profile_general.twig";
import profile_blacklist from "PagesTwigs/profile_blacklist.twig";
import profile_private from "PagesTwigs/profile_private.twig";
import video_clip_search_page from "PagesTwigs/video_clip_search_page.twig";
import video_clip_main_page from "PagesTwigs/video_clip_main_page.twig";
import create_video_clip_page from "PagesTwigs/create_video_clip_page.twig";
import balance_page from "PagesTwigs/balance_page.twig";
import wallet_page from "PagesTwigs/wallet_page.twig";
import wallet_cards from "PagesTwigs/wallet_cards.twig";
import wallet_operation from "PagesTwigs/wallet_operation.twig";
import statistic_attendance from "PagesTwigs/statistic_attendance.twig";
import statistic_coverage from "PagesTwigs/statistic_coverage.twig";
import statistic_messages from "PagesTwigs/statistic_messages.twig";
import statistic_activity from "PagesTwigs/statistic_activity.twig";
import statistic_review from "PagesTwigs/statistic_review.twig";
import statistic_records from "PagesTwigs/statistic_records.twig";
import statistic_post from "PagesTwigs/statistic_post.twig";
import statistic_referendum from "PagesTwigs/statistic_referendum.twig";
import statistic_petition from "PagesTwigs/statistic_petition.twig";
import statistic_petition_single from "PagesTwigs/statistic_petition_single.twig";
import statistic_projects from "PagesTwigs/statistic_projects.twig";
import profile_more from "PagesTwigs/profile_more.twig";
import company_page from "PagesTwigs/company_page.twig";
import companies from "PagesTwigs/companies.twig";

export default { home, company_reg, login, reg_account_choose, remember, user_reg, web_constructor, web_constructors, profile, chat, group_chat, chat_link_modal, note, notes_deleted,notes_remind, community_layout, company_layout, community_edit,my_page, community_page,career,election, event,petition_item,petitions,polls,music,referendum,sample_party,voting,articles_page,videos_page,my_videos_page,social_main,video_single,bookmarks,playlist_video,playlists_video,communities, communities_category,communities_control,music_albums, music_playlists, music_recently_listener, music_saved,profile_verification,profile_setting,profile_security,profile_personal,profile_notices,profile_general,profile_blacklist,profile_private,video_clip_search_page,video_clip_main_page, balance_page,wallet_page,wallet_cards,wallet_operation,create_video_clip_page,statistic_attendance,statistic_coverage,statistic_messages,statistic_activity,statistic_review,statistic_records,statistic_post,statistic_projects,statistic_petition_single,statistic_petition,statistic_referendum,profile_more,company_page,companies};
