export const MapElement = ({ editor }) => {
    const prefix = 'test_map';


    editor.Blocks.add(prefix, {
        media: `<svg width="45" height="65" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 17.4188V33.6755C6 35.7626 6 36.8062 6.56627 37.5919C7.13255 38.3775 8.12258 38.7076 10.1026 39.3676L12.4322 40.1441C15.1664 41.0555 16.5335 41.5112 17.9099 41.3269C17.94 41.3228 17.97 41.3185 18 41.3139V13.3141C17.7742 13.3478 17.5466 13.3687 17.3182 13.3765C16.2398 13.4133 15.1725 13.0575 13.038 12.346C10.2422 11.4141 8.84425 10.9481 7.78059 11.4213C7.40294 11.5893 7.06409 11.8336 6.78528 12.1387C6 12.9981 6 14.4717 6 17.4188Z" fill="#D1EDFF"/>
                <path d="M42 30.5813V14.3246C42 12.2374 42 11.1938 41.4337 10.4082C40.8675 9.62252 39.8774 9.29251 37.8974 8.63249L35.5678 7.85598C32.8336 6.94457 31.4665 6.48887 30.0901 6.67319C30.06 6.67723 30.03 6.68156 30 6.68618V34.686C30.2258 34.6522 30.4534 34.6314 30.6818 34.6236C31.7602 34.5868 32.8275 34.9425 34.962 35.654C37.7578 36.586 39.1558 37.052 40.2194 36.5787C40.5971 36.4107 40.9359 36.1665 41.2147 35.8614C42 35.0019 42 33.5284 42 30.5813Z" fill="#D1EDFF"/>
                <path d="M18.4937 13.2191C18.3304 13.2576 18.1657 13.2893 18 13.3141V41.3139C19.3393 41.1072 20.5377 40.3082 22.8833 38.7445L25.6481 36.9013C27.5201 35.6533 28.4562 35.0292 29.5063 34.781C29.6696 34.7424 29.8343 34.7107 30 34.686V6.68619C28.6607 6.8929 27.4623 7.69187 25.1167 9.25557L22.3519 11.0987C20.4799 12.3468 19.5438 12.9708 18.4937 13.2191Z" fill="#0099FF"/>
                <path d="M34.9621 35.6541C35.1368 35.7123 35.306 35.7687 35.4701 35.8232L34.9621 35.6541Z" fill="#0099FF"/>
            </svg>`,
        prefix,
        category: 'Elements',
        select: true,
        content: { type: prefix, name: 'Map' },
        label: 'Map'
    });

    // Adding the component to the editor
    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix
                },
                components: [
                    {
                        type: 'default',
                        attributes: {
                            class: prefix + '_map'
                        },
                        content: `<div class="${prefix}_address"><input type="text" id="address-input" placeholder="Введите адрес" style=" padding: 5px 10px;
                                    border: 1px solid #ccc;
                                    border-radius: 3px;
                                    flex: 1;">
                                  <button id="address-search" style="padding: 5px 10px;  border: 1px solid #ccc; border-radius: 5px;">Найти</button></div>`
                    },

                ],
                styles: `
                    .${prefix} {
                        background: #f3f5f5;
                        height: 100%;
                        width: 100%;
                        position: relative;
                        border-radius: 10px;
                        overflow: hidden;
                    }
                    .${prefix}_map { 
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                    }
                    .${prefix}_address {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 10;
                        width: 100%;
                        order: 2;
                        gap: 20px;
                    }
                    #adress-input:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    #address-input:::selection {
                        background: transparent;
                    }
                    
                    #address-input::-moz-selection {
                        background: transparent;
                    } 
                    #address-input::-moz-selection {
                        background: transparent;
                    }
                    :focus-visible {
                        outline: -webkit-focus-ring-color auto 0;
                    }
                    `,
                script: function() {
                    const loadYandexMapsScript = () => {
                        return new Promise((resolve, reject) => {
                            if (document.querySelector(`script[src="https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=7cd4ea95-1e1c-4ead-828f-a83aae67945f&load=Map,Placemark,geocode"]`)) {
                                resolve();
                                return;
                            }

                            const script = document.createElement('script');
                            script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=7cd4ea95-1e1c-4ead-828f-a83aae67945f&load=Map,Placemark,geocode';
                            script.type = 'text/javascript';
                            script.onload = () => resolve();
                            script.onerror = () => reject(new Error('Failed to load Yandex Maps script'));
                            document.head.appendChild(script);
                        });
                    };
                    const initializeMap = (mapContainer) => {
                        ymaps.ready(() => {
                            const myMap = new ymaps.Map(mapContainer, {
                                center: [55.76, 37.64], // Центр по умолчанию (Москва)
                                zoom: 17
                            });

                            const input = document.getElementById('address-input');
                            const searchButton = document.getElementById('address-search');

                            const performSearch = () => {
                                const address = input.value;
                                ymaps.geocode(address).then((res) => {
                                    const firstGeoObject = res.geoObjects.get(0);
                                    const coords = firstGeoObject.geometry.getCoordinates();
                                    myMap.setCenter(coords);
                                });
                            };

                            if (input && searchButton) {
                                searchButton.addEventListener('click', performSearch);
                                input.addEventListener('keypress', (event) => {
                                    if (event.key === 'Enter') {
                                        performSearch();
                                    }
                                });
                            } else {
                                console.warn('Elements with id "address-input" or "address-search" not found.');
                            }
                        });
                    };
                    const setupMap = (container, prefix) => {
                        const mapContainer = document.createElement('div');
                        mapContainer.style.width = '100%';
                        mapContainer.style.height = '100%';
                        container.querySelector(`.${prefix}_map`).appendChild(mapContainer);

                        loadYandexMapsScript().then(() => {
                            initializeMap(mapContainer);
                        }).catch(error => {
                            console.error('Ошибка загрузки скрипта Яндекс.Карт:', error);
                        });
                    };

                    setupMap(this, 'test_map');

                }
            }
        }
    });

};
