export const MarketPlaceWidget = ({editor}) => {
    const prefix = 'marketplace';
    function script() {
        const carouselList = document.querySelectorAll('.marketplace_carousel__list');
        const carouselItems = document.querySelectorAll('.marketplace_carousel__item');
        const elems = Array.from(carouselItems);

        carouselList.forEach(item => {
            item.addEventListener('click', function (event) {
                var newActive = event.target;
                var isItem = newActive.closest('.marketplace_carousel__item');

                if (!isItem || newActive.classList.contains('carousel__item_active')) {
                    return;
                }

                update(newActive);
            });
        });

        const update = function(newActive) {
            const newActivePos = newActive.dataset.pos;

            const current = elems.find((elem) => parseInt(elem.dataset.pos) === 0);
            const prev = elems.find((elem) => parseInt(elem.dataset.pos) === -1);
            const next = elems.find((elem) => parseInt(elem.dataset.pos) === 1);

            current.classList.remove('carousel__item_active');

            [current, prev, next].forEach(item => {
                var itemPos = item.dataset.pos;

                item.dataset.pos = getPos(itemPos, newActivePos);
            });
        };

        const getPos = function (current, active) {
            const diff = current - active;

            if (Math.abs(current - active) > 1) {
                return -current;
            }

            return diff;
        };
    }

    editor.Blocks.add(prefix, {
        media: `<svg width="60" height="80" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                    <path d="M10 18.05C10 15.2025 10 13.7788 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.7788 10 15.2025 10 18.05 10H21.95C24.7975 10 26.2212 10 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C30 13.7788 30 15.2025 30 18.05V21.95C30 24.7975 30 26.2212 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.2212 30 24.7975 30 21.95 30H18.05C15.2025 30 13.7788 30 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10 26.2212 10 24.7975 10 21.95V18.05Z" fill="#99D6FF"/>
                    <path d="M14 29.8447C13.4929 29.7614 13.0733 29.6335 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10.3665 26.9267 10.2386 26.5071 10.1553 26H14V29.8447Z" fill="#008DEB"/>
                    <path d="M18.05 30H19V26H16V29.9883C16.5906 30 17.2664 30 18.05 30Z" fill="#008DEB"/>
                    <path d="M21 30H21.95C22.7336 30 23.4094 30 24 29.9883V26H21V30Z" fill="#008DEB"/>
                    <path d="M29.8447 26C29.7614 26.5071 29.6335 26.9267 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.9267 29.6335 26.5071 29.7614 26 29.8447V26H29.8447Z" fill="#008DEB"/>
                    <path d="M26 10.1553C26.5071 10.2386 26.9267 10.3665 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C29.6335 13.0733 29.7614 13.4929 29.8447 14H26V10.1553Z" fill="#008DEB"/>
                    <path d="M21.95 10H21V14H24V10.0117C23.4094 10 22.7336 10 21.95 10Z" fill="#008DEB"/>
                    <path d="M19 10H18.05C17.2664 10 16.5906 10 16 10.0117V14H19V10Z" fill="#008DEB"/>
                    <path d="M14 10.1553V14H10.1553C10.2386 13.4929 10.3665 13.0733 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.0733 10.3665 13.4929 10.2386 14 10.1553Z" fill="#008DEB"/>
                    <path d="M22 20C22 19.4722 21.4704 19.1162 20.4112 18.4043C19.3375 17.6827 18.8006 17.3219 18.4003 17.5868C18 17.8517 18 18.5678 18 20C18 21.4322 18 22.1483 18.4003 22.4132C18.8006 22.6781 19.3375 22.3173 20.4112 21.5957C21.4704 20.8838 22 20.5278 22 20Z" fill="#008DEB"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Маркетплейс'
    });
    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_header d-flex flex-row align-items-center justify-content-between'
                        },
                        components: [
                            {
                                tagName: 'div',
                                attributes: {
                                    class: 'd-flex align-items-center'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                     <svg width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                        <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                        <path d="M10 18.05C10 15.2025 10 13.7788 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.7788 10 15.2025 10 18.05 10H21.95C24.7975 10 26.2212 10 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C30 13.7788 30 15.2025 30 18.05V21.95C30 24.7975 30 26.2212 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.2212 30 24.7975 30 21.95 30H18.05C15.2025 30 13.7788 30 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10 26.2212 10 24.7975 10 21.95V18.05Z" fill="#99D6FF"/>
                                        <path d="M14 29.8447C13.4929 29.7614 13.0733 29.6335 12.6951 29.4371C11.7819 28.9627 11.0373 28.2181 10.5629 27.3049C10.3665 26.9267 10.2386 26.5071 10.1553 26H14V29.8447Z" fill="#008DEB"/>
                                        <path d="M18.05 30H19V26H16V29.9883C16.5906 30 17.2664 30 18.05 30Z" fill="#008DEB"/>
                                        <path d="M21 30H21.95C22.7336 30 23.4094 30 24 29.9883V26H21V30Z" fill="#008DEB"/>
                                        <path d="M29.8447 26C29.7614 26.5071 29.6335 26.9267 29.4371 27.3049C28.9627 28.2181 28.2181 28.9627 27.3049 29.4371C26.9267 29.6335 26.5071 29.7614 26 29.8447V26H29.8447Z" fill="#008DEB"/>
                                        <path d="M26 10.1553C26.5071 10.2386 26.9267 10.3665 27.3049 10.5629C28.2181 11.0373 28.9627 11.7819 29.4371 12.6951C29.6335 13.0733 29.7614 13.4929 29.8447 14H26V10.1553Z" fill="#008DEB"/>
                                        <path d="M21.95 10H21V14H24V10.0117C23.4094 10 22.7336 10 21.95 10Z" fill="#008DEB"/>
                                        <path d="M19 10H18.05C17.2664 10 16.5906 10 16 10.0117V14H19V10Z" fill="#008DEB"/>
                                        <path d="M14 10.1553V14H10.1553C10.2386 13.4929 10.3665 13.0733 10.5629 12.6951C11.0373 11.7819 11.7819 11.0373 12.6951 10.5629C13.0733 10.3665 13.4929 10.2386 14 10.1553Z" fill="#008DEB"/>
                                        <path d="M22 20C22 19.4722 21.4704 19.1162 20.4112 18.4043C19.3375 17.6827 18.8006 17.3219 18.4003 17.5868C18 17.8517 18 18.5678 18 20C18 21.4322 18 22.1483 18.4003 22.4132C18.8006 22.6781 19.3375 22.3173 20.4112 21.5957C21.4704 20.8838 22 20.5278 22 20Z" fill="#008DEB"/>
                                    </svg>
                                    <h5 class="main__item-title m-0">Маркетплейс</h5>
                                `
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_go_to_page',
                                    type: 'button'
                                },
                                content: `
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.69699 0.46967C1.40409 0.176777 0.92922 0.176777 0.636326 0.46967C0.343433 0.762563 0.343433 1.23744 0.636326 1.53033L3.106 4L0.636326 6.46967C0.343433 6.76256 0.343433 7.23744 0.636326 7.53033C0.92922 7.82322 1.40409 7.82322 1.69699 7.53033L4.69699 4.53033C4.98988 4.23744 4.98988 3.76256 4.69699 3.46967L1.69699 0.46967Z" fill="black" fill-opacity="0.38"/>
                                    </svg>
                                `
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_items_content',
                        },
                        content: `
                              <ul class="${prefix}_carousel__list">
                                <li class="${prefix}_carousel__item" data-pos="-1">1</li>
                                <li class="${prefix}_carousel__item" data-pos="0">2</li>
                                <li class="${prefix}_carousel__item" data-pos="1">3</li>
                              </ul>
                        `
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                     }
                     .${prefix}_go_to_page {
                        border-radius: 50%;
                        padding: 10px 11px;
                        background: hsla(0, 0%, 0%, 0.12);
                        display: flex;
                     }
                     .${prefix}_items_content {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                     }
                        
                     .${prefix}_carousel__list {
                        display: flex;
                        list-style: none;
                        position: relative;
                        width: 90%;
                        height: 270px;
                        justify-content: center;
                        perspective: 300px;
                     } 
                     .${prefix}_carousel__item {
                        border: 10px solid white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 0px;
                        width: 220px;
                        height: 270px;
                        border-radius: 12px;
                        box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5);
                        position: absolute;
                        transition: all .3s ease-in;
                        cursor: pointer;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                     }  
                     .${prefix}_carousel__item:nth-child(1) {
                        background-image: url('https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg');
                     }
                     .${prefix}_carousel__item:nth-child(2) {
                        background-image: url('https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg');
                     }
                     .${prefix}_carousel__item:nth-child(3) {
                        background-image: url('https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg');
                     }
                     .${prefix}_carousel__item[data-pos="0"] {
                        z-index: 5;
                     }
                     .${prefix}_carousel__item[data-pos="-1"],
                     .${prefix}_carousel__item[data-pos="1"] {
                        opacity: 0.7;
                        filter: blur(1px) grayscale(10%);
                     }
                     .${prefix}_carousel__item[data-pos="-1"] {
                        transform: translateX(-40%) scale(.9);
                        z-index: 4;
                     }
                     .${prefix}_carousel__item[data-pos="1"] {
                        transform: translateX(40%) scale(.9);
                        z-index: 4;
                     }
                     
                `
            }
        }
    });
};

export default MarketPlaceWidget;
