import ko from 'knockout';
import Chart from "chart.js/auto";
ko.bindingHandlers.custom_chart = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        "use strict";
        let value = valueAccessor();
        // console.log(element);
        // console.log(value);
        // console.log(value.type)
        // console.log(value.data)



        let scales = {};
        if (!value.showLeftContent) {
            scales = {
                x: {
                    ticks: {
                        display: false
                    }
                },
                y: {
                    ticks: {
                        display: false
                    }
                }
            };
        }
        if (value.type === 'mix') {
            new Chart(element, {
                data: {
                    labels: [
                        'January', 'February', 'March', 'April', 'May', 'June', 'July'
                    ],
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Female',
                            data: [100, 50, 45, 5, 25, 25, 45],
                            borderColor: 'rgb(245,31,74)',
                            backgroundColor: 'rgba(255,99,132,0.45)'
                        },
                        {
                            label: 'Male',
                            data: [150, 90, 115, 70, 40, 35, 58],
                            tension: 0.1,
                            borderColor: '#318bda',
                            pointRadius: 1.5,
                            spanGaps: true,
                            borderWidth: 3,
                            type: 'line',
                        },

                    ],
                },
            });
        } else if(value.type === 'double_bar') {
            new Chart(element, {
                data: {
                    labels: [
                        'January', 'February', 'March', 'April', 'May', 'June', 'July'
                    ],
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Female',
                            data: [100, 50, 45, 5, 25, 25, 45],
                            borderColor: 'rgb(245,31,74)',
                            backgroundColor: 'rgba(255,99,132,0.45)'
                        },
                        {
                            label: 'Male',
                            data: [150, 90, 115, 70, 40, 35, 58],
                            tension: 0.1,
                            borderColor: '#318bda',
                            backgroundColor: '#318bda',
                            pointRadius: 1.5,
                            spanGaps: true,
                            borderWidth: 3,
                            type: 'bar',
                        },

                    ],
                },
            });
        } else if(value.type === 'bar') {
            new Chart(element, {
                data: {
                    labels: value.labels,
                    datasets: [
                        {
                            type: value.type,
                            label: 'Bar Dataset',
                            data: value.data,
                            borderColor: value.baseColor,
                            backgroundColor: value.baseColor,
                            aspectRatio: 1|1,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: 0,  // Устанавливаем нулевые отступы
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales
                },
                tooltips: {
                    mode: false,
                    callbacks: {
                        title: function() {},
                        label: function() {}
                    }
                },
            });
        } else {
            new Chart(element, {
                data: {
                    labels: value.labels,
                    datasets: [
                        {
                            type: value.type,
                            label: 'Bar Dataset',
                            data: value.data,
                            borderColor: value.baseColor,
                            backgroundColor: value.labels,
                            aspectRatio: 1|1,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: 0,  // Устанавливаем нулевые отступы
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales
                },
                tooltips: {
                    mode: false,
                    callbacks: {
                        title: function() {},
                        label: function() {}
                    }
                },
            });
        }

    }
};
