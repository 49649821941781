import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class emoji_block extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});
        this.showIconBlock = ko.observable('first');

        const reactionButtons = document.querySelectorAll(".reaction-item_hovered");
        if (reactionButtons) {
            reactionButtons.forEach(button => {
                button.addEventListener("mouseenter", function() {
                    const hiddenImg = this.querySelector('.hover_hidden');
                    const visibleImg = this.querySelector('.hover_visible');

                    hiddenImg.style.display = 'none';
                    visibleImg.style.display = 'block';

                    setTimeout(function() {
                        hiddenImg.style.display = 'block';
                        visibleImg.style.display = 'none';
                    }, 2500);
                });
            });
        }

    }

    saveReaction(name, data) {
        return true;
    }
}
