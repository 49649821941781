

export const album_personal = (params) => {
    const prefix = 'album-personal';
    const name = params.surname ? params.surname + ' ' + params.name : params.name;
    return {
        tagName: 'div',
        type: 'page',
        attributes: {
            class: 'sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        hoverable: false,
        components: [
            {
                type: 'header',
                tagName: 'div',

                attributes: {
                    class: 'header__require_content',
                },
                removable: false,
                layerable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                components: [
                    {
                        type: 'image',
                        tagName: 'img',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        type: 'text',
                        tagName: 'h1',
                        attributes: {

                        },
                        content: name
                    }
                ]
            },
            {
                type: '',
                tagName: 'div',
                attributes: {
                    class: 'locals-page-menu'
                },
                layerable: false,
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                components: [
                    {
                        type: 'personal_local_menu',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                    {
                        type: 'main_submit',
                        tagName: 'div',
                        attributes: {
                            class: 'main__require_content'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                        <button class="main__item" id='album__btn-show-nav-menu'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                     
                        <button class="main__subscribe_item" id='album__btn-add_friends'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z" fill="white"/>
                                <path d="M18 17.5C18 19.9853 18 22 10 22C2 22 2 19.9853 2 17.5C2 15.0147 5.58172 13 10 13C14.4183 13 18 15.0147 18 17.5Z" fill="white"/>
                                <circle cx="17" cy="17" r="5" fill="#0068AD"/>
                                <path d="M17 19L17 15M15 17H19" stroke="white" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <span class="main__item-title">Добавить в друзья</span>
                        </button>
                    `
                    },
                ]
            },
            {
                tagName: 'main',
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        tagName: 'div',
                        type: 'six_column',
                        attributes: {
                            class: prefix + '_cards_list'
                        },
                        components: [
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'about_me'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'photo_video'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'personal_data'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'career'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'friends'
                                    },
                                ]
                            },
                            {
                                type: 'one_column',
                                components: [
                                    {
                                        type: 'state'
                                    },
                                ]
                            },
                        ]
                    }
                    // {
                    //     tagName: 'div',
                    //     attributes: {
                    //         class: prefix + '_cards_list'
                    //     },
                    //     components: [


                        // ]
                    // }
                ]
            },
            {
                type: 'feed_post_list',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                editable: false,
                hoverable: false,
            }
        ],
        styles: `
            body {
              background-color: #F3F5F5; 
            }
            .sample__container {
                height: 100%
                padding: 0 40px;
            }
            .header__require_content {
                width: 100%;
                min-height: 250px;
                background-color: #0099ff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 25px;
            }
            .header__require_content > img {
                border-radius: 50%;
                border: none;
                width: 100px;
                height: 100px;
                object-fit: cover;
                position: relative;
            }
            .header__require_content > h1 {
                background: transparent;
                color: white;
            }
            .main__require_content {
                display: flex;
                position: relative;
            }
            .${prefix}_body__content {
                margin: 24px 5rem;
            }
            .main__subscribe_item {
                cursor: pointer;
                border-radius: 10px;
                border: none;
                color: white;
                background: #0099ff;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                align-items: center;
                font-family: FixelText-SemiBold, sans-serif;
                 padding: 12px 16px;
            }
            .require_content-right {
                display: flex;
                position: relative;
            }
            .posts-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .locals-page-menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
                 margin: 24px 5rem;
            }
            .dropdown-post-menu {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 10px;
                border: none;
                padding: 20px;
                text-align: start;
                left: -110px;
            }
            .dropdown-post-menu > button {
                margin-bottom: 5px;
                border-radius: 5px;
                padding: 10px
            }
            .dropdown-toggle {
                margin-right: 10px;
            }
            .dropdown-toggle::after {
                display: none;
            }
             .${prefix}_cards_list {
                min-height: 400px;
                gap: 1rem;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                padding: 0 17px;
                margin-bottom: 18px;
            }
        `,
    };
};
