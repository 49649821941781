import ko from 'knockout';
import Handler from 'engine/Handler';
import Highcharts from "highcharts/highmaps";
import map_rus from '@highcharts/map-collection/countries/ru/ru-all.topo.json'

//import 'knockout-mapping';
export default class statistic_projects extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
    }
}
