export const CommentariesFriendsWidget = ({editor}) => {

    const prefix = 'comments_friends';

    editor.Blocks.add(prefix, {
        media: `<svg width="60" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                    <rect x="13" y="9" width="18" height="15" rx="4" fill="#008DEB"/>
                    <path d="M9.46494 16.1283C9 17.0064 9 18.1643 9 20.48V22.52C9 24.8357 9 25.9936 9.46494 26.8717C9.84014 27.5803 10.4197 28.1599 11.1283 28.5351C12.0064 29 13.1643 29 15.48 29H16.2L17.1425 30.5708C17.5309 31.2182 18.4691 31.2182 18.8575 30.5708L19.8 29H20.52C22.8357 29 23.9936 29 24.8717 28.5351C25.5803 28.1599 26.1599 27.5803 26.5351 26.8717C27 25.9936 27 24.8357 27 22.52V20.48C27 18.1643 27 17.0064 26.5351 16.1283C26.1599 15.4197 25.5803 14.8401 24.8717 14.4649C23.9936 14 22.8357 14 20.52 14H15.48C13.1643 14 12.0064 14 11.1283 14.4649C10.4197 14.8401 9.84014 15.4197 9.46494 16.1283Z" fill="#99D6FF"/>
                    <path d="M19 22C19 22.5523 18.5523 23 18 23C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21C18.5523 21 19 21.4477 19 22Z" fill="#008DEB"/>
                    <path d="M15 22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22C13 21.4477 13.4477 21 14 21C14.5523 21 15 21.4477 15 22Z" fill="#008DEB"/>
                    <path d="M23 22C23 22.5523 22.5523 23 22 23C21.4477 23 21 22.5523 21 22C21 21.4477 21.4477 21 22 21C22.5523 21 23 21.4477 23 22Z" fill="#008DEB"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Комментарии друзей'
    });

    const comments = [
        {
            author: 'Elenka Alisa',
            text: 'И смешно и грустно',
            time: '21:21',
            answerAuthor: 'Борис Горшков',
            id: 0,
            avatar: 'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
        },
        {
            author: 'Александр Попов',
            text: 'Главное, пришла к правильному выводу. Хоть и долго плутала в своих полутора извилинах.',
            time: '21:07',
            answerAuthor: 'Полина Семенова',
            id: 1,
            avatar: 'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
        },
        {
            author: 'Мария Иванова',
            text: 'Пусть потерпит. Есть шанс, что он вернется не таким уже и красивым. И с недостающими «деталями».',
            time: '05:37',
            answerAuthor: 'Софья Боброва',
            id: 2,
            avatar: 'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'
        },
    ];

    let result_comments = [];

    comments.map(comment => {
        return result_comments.push({
            tagName: 'div',
            removable: false,
            draggable: false,
            droppable: false,
            copyable: false,
            selectable: false,
            hoverable: false,
            editable: false,
            attributes: {
                class: prefix + '_comment_container'
            },
            components: [
                {
                    tagName: 'img',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    hoverable: false,
                    editable: false,
                    attributes: {
                        src: comment.avatar,
                        class: prefix + '_comment_avatar'
                    }
                },
                {
                    tagName: 'div',
                    removable: false,
                    draggable: false,
                    droppable: false,
                    copyable: false,
                    selectable: false,
                    hoverable: false,
                    editable: false,
                    attributes: {
                        class: prefix + '_comment_content'
                    },
                    components: [
                        {
                            tagName: 'span',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            hoverable: false,
                            editable: false,
                            content: comment.author,
                            attributes: {
                                class: prefix + '_comment_author'
                            },
                        },
                        {
                            tagName: 'p',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            hoverable: false,
                            editable: false,
                            content: comment.text,
                            attributes: {
                                class: prefix + '_comment_message'
                            },
                        },
                        {
                            tagName: 'div',
                            removable: false,
                            draggable: false,
                            droppable: false,
                            copyable: false,
                            selectable: false,
                            hoverable: false,
                            editable: false,
                            attributes: {
                                class: prefix + '_comment_info'
                            },
                            content: `
                                <span>${comment.time}</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4V9C4 9.79565 4.31607 10.5587 4.87868 11.1213C5.44129 11.6839 6.20435 12 7 12H16M16 12L12 16M16 12L12 8" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>${comment.answerAuthor}</span>
                            `
                        },
                    ]
                }
            ]
        });
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: 'd-flex align-items-center mb-4'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                             <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                <rect x="13" y="9" width="18" height="15" rx="4" fill="#008DEB"/>
                                <path d="M9.46494 16.1283C9 17.0064 9 18.1643 9 20.48V22.52C9 24.8357 9 25.9936 9.46494 26.8717C9.84014 27.5803 10.4197 28.1599 11.1283 28.5351C12.0064 29 13.1643 29 15.48 29H16.2L17.1425 30.5708C17.5309 31.2182 18.4691 31.2182 18.8575 30.5708L19.8 29H20.52C22.8357 29 23.9936 29 24.8717 28.5351C25.5803 28.1599 26.1599 27.5803 26.5351 26.8717C27 25.9936 27 24.8357 27 22.52V20.48C27 18.1643 27 17.0064 26.5351 16.1283C26.1599 15.4197 25.5803 14.8401 24.8717 14.4649C23.9936 14 22.8357 14 20.52 14H15.48C13.1643 14 12.0064 14 11.1283 14.4649C10.4197 14.8401 9.84014 15.4197 9.46494 16.1283Z" fill="#99D6FF"/>
                                <path d="M19 22C19 22.5523 18.5523 23 18 23C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21C18.5523 21 19 21.4477 19 22Z" fill="#008DEB"/>
                                <path d="M15 22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22C13 21.4477 13.4477 21 14 21C14.5523 21 15 21.4477 15 22Z" fill="#008DEB"/>
                                <path d="M23 22C23 22.5523 22.5523 23 22 23C21.4477 23 21 22.5523 21 22C21 21.4477 21.4477 21 22 21C22.5523 21 23 21.4477 23 22Z" fill="#008DEB"/>
                             </svg>
                             <h5 class="main__item-title m-0">Комментарии друзей</h5>
                        `
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        components: [result_comments]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family:SF Pro Text, sans-serif;
                     }
                     .${prefix}_comment_container {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 15px;
                     }  
                     .${prefix}_comment_avatar {
                        margin-right: 14px;
                     } 
                     .${prefix}_comment_author {
                        font-size: 12px;
                     }
                     .${prefix}_comment_message {
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        margin: 6px 0 8px;
                     }
                     .${prefix}_comment_info {
                         color: #0000008A;
                         display: flex;
                     }
                     .${prefix}_comment_info > span, .${prefix}_comment_info > svg  {
                        margin-right: 10px;
                     }
                     .${prefix}_comment_info > span {
                        font-size: 12px;
                     }
                `
            }
        }
    });
};