import ko from 'knockout';
import Handler from 'engine/Handler';

//import 'knockout-mapping';
export default class statistic_attendance extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.stat_uniqu_views = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 88 },
                { year: '', count: 20 },
                { year: '', count: 35 },
                { year: '', count: 62 },
                { year: '', count: 28 }
            ]
        };

        this.stat_geo_country = {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
        this.stat_geo_city= {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
        this.stat_devices= {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
        this.stat_transition= {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
    }
}
