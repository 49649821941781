import ko from 'knockout';
import Handler from 'engine/Handler';
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";
import 'knockout-mapping';
export default class note extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.notes = Array.from([
            {
                title: 'Эксперт “Народного фронта. Аналитика”: сейчас в сфере ЖКХ больше политики, чем экономики',
                isPinned: true,
                isDeleted: false,
                isArchived: false,
                withImage: true,
                co_authors_list: [
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                ],
                notify: 'пн, 20:00',
                id: 0
            },
            {
                title: 'Эксперт “Народного фронта. Аналитика”: сейчас в сфере ЖКХ больше политики, чем экономики',
                isPinned: false,
                isDeleted: false,
                isArchived: false,
                withImage: true,
                co_authors_list: [],
                notify: '',
                id: 1
            },
            {
                title: 'Эксперт “Народного фронта. Аналитика”: сейчас в сфере ЖКХ больше политики, чем экономики',
                isPinned: true,
                isDeleted: false,
                isArchived: false,
                withImage: false,
                co_authors_list: [],
                notify: '',
                id: 2
            },
            {
                title: 'Эксперт “Народного фронта. Аналитика”: сейчас в сфере ЖКХ больше политики, чем экономики',
                isPinned: false,
                isDeleted: false,
                isArchived: false,
                withImage: true,
                co_authors_list: [
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                ],
                notify: '11 мая',
                id: 3
            },
            {
                title: 'Эксперт “Народного фронта. Аналитика”: сейчас в сфере ЖКХ больше политики, чем экономики',
                isPinned: true,
                isDeleted: false,
                isArchived: false,
                withImage: false,
                co_authors_list: [
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                    'https://kartinki.pics/uploads/posts/2022-03/1647966002_1-kartinkin-net-p-devushki-kartinki-risunki-1.jpg',
                ],
                notify: '11 мая',
                id: 4
            }
        ], note => ko.mapping.fromJS(note));

        this.pinnedNotes = ko.computed(function() {
            return this.notes.filter(note => note.isPinned());
        }, this);

        this.otherNotes = ko.computed(function() {
            return this.notes.filter(note => !note.isPinned());
        }, this);

        /*notes more chooses*/
        const tools_container = document.querySelector('.planet_layout .notes-tools');
        const btn_close = document.querySelector('.planet_layout .notes-tools .icon-close');
        const notes = document.querySelectorAll('.planet_layout .note .note__select');
        const notes_menu = document.querySelectorAll('.planet_layout .note .note__float-head .menu');

        notes.forEach(checkbox => {
            checkbox.addEventListener('click', () => {
                const countCheckedNote = Array.from(notes, note => note.checked);
                document.querySelector('.planet_layout .notes-tools .count').textContent = countCheckedNote.filter(item => item).length.toString();
                countCheckedNote.includes(true) ? tools_container.classList.remove('is-hidden') : tools_container.classList.add('is-hidden');
                notes_menu.forEach(menu => menu.style.display = countCheckedNote.includes(true) ? 'none' : 'flex');
            });
        });
        if (tools_container && notes) {
            btn_close.addEventListener('click', () => {
                notes.forEach(checkbox => checkbox.checked = false);
                tools_container.classList.add('is-hidden');
                notes_menu.forEach(menu => menu.style.display = 'flex');
            });
        }

        /* choose background label */
        const color_list = document.querySelectorAll('.background-styles .item-color');
        // const image_list = document.querySelectorAll('.background-styles .item-image');

        color_list.forEach(item => {
            item.addEventListener('click', () => {
                color_list.forEach(checkbox => checkbox.classList.remove('is-selected'));
                color_list.forEach(checkbox => checkbox.querySelector('.select-item').checked = false);
                item.classList.add('is-selected');
                item.querySelector('.select-item').checked = true;
            });
        });
        // image_list.forEach(item => {
        //     item.addEventListener('click', () => {
        //         image_list.forEach(checkbox => checkbox.classList.remove('is-selected'));
        //         image_list.forEach(checkbox => checkbox.querySelector('.select-item').checked = false);
        //         item.classList.add('is-selected');
        //         item.querySelector('.select-item').checked = true;
        //     });
        // });

        /* datetime-input */
        const containers = document.querySelectorAll('.get-date');
        containers.forEach(container => {
            const input = container.querySelector('.datetime-input');
            const placeholder = container.querySelector('.date-placeholder');

            if (input.classList.contains('is_time')) {
                flatpickr(input, {
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    locale: Russian
                });
            } else {
                flatpickr(input, {
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    locale: Russian
                });
            }


            placeholder.addEventListener('click', function() {
                input._flatpickr.open();
            });
        });

        /* modal dropdown toggles */

        const dropdownToggles = document.querySelectorAll('.outline-drop .dropdown-toggle');

        if (dropdownToggles) {
            dropdownToggles.forEach(function (toggle) {
                toggle.addEventListener('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    let dropdownMenu = toggle.nextElementSibling;

                    // Close all other dropdown menus
                    const allDropdownMenus = document.querySelectorAll('.outline-drop .dropdown-menu');
                    allDropdownMenus.forEach(function (menu) {
                        if (menu !== dropdownMenu && !menu.contains(event.target)) {
                            menu.style.display = 'none';
                        }
                    });

                    // Temporarily show the dropdown to measure its height and width
                    dropdownMenu.style.display = 'flex';
                    let menuHeight = dropdownMenu.offsetHeight;
                    let menuWidth = dropdownMenu.offsetWidth;
                    dropdownMenu.style.display = 'none';

                    let rect = toggle.getBoundingClientRect();
                    let viewportHeight = window.innerHeight;
                    let viewportWidth = window.innerWidth;

                    // Check if there's enough space below the button
                    if (rect.bottom + menuHeight > viewportHeight) {
                        // If not enough space, display menu above the button
                        dropdownMenu.style.top = rect.top - menuHeight + 'px';
                    } else {
                        // Otherwise, display menu below the button
                        dropdownMenu.style.top = rect.bottom + 'px';
                    }
                    // Check if there's enough space on the left of the button
                    if (rect.right + menuWidth < viewportWidth) {
                        // If not enough space, display menu on the right of the button
                        dropdownMenu.style.left = rect.right + 'px';
                    } else {
                        // Otherwise, display menu on the left of the button
                        dropdownMenu.style.left = viewportWidth - menuWidth - 20 + 'px';
                    }

                    dropdownMenu.style.display = 'flex';
                });
            });

            document.addEventListener('click', function (event) {
                const dropdownMenus = document.querySelectorAll('.outline-drop .dropdown-menu');
                dropdownMenus.forEach(function (menu) {
                    if (!menu.contains(event.target) || event.target.parentElement.classList.contains('close')) {
                        menu.style.display = 'none';
                    }
                });
            });
        }
    }

    togglePinned(id) {
        ko.utils.arrayMap(this.notes, note => note.id() === id ? note.isPinned(!note.isPinned()) : note);
    }

    chooseBg() {
        return {
            click: (data, event) => {
                if (event.target.getAttribute('data-color-number')) {
                    const title_container = event.target.closest('.note');
                    switch (parseInt(event.target.getAttribute('data-color-number'))) {
                        case 1 : title_container.style.background = 'linear-gradient(135deg, #9747FF 7.14%, #00D7E1 50.4%, #320073 91.96%)';
                            break;
                        case 2 : title_container.style.background = 'linear-gradient(0deg, #601EB4, #601EB4),linear-gradient(96.65deg, #18AAF2 -10.68%, #B984FF 110.84%)';
                            break;
                        case 3 : title_container.style.background = 'radial-gradient(75.11% 67.88% at 24.89% 32.12%, #C69AFF 0%, #9341FE 100%)';
                            break;
                        case 4 : title_container.style.background = 'linear-gradient(270deg, #02D6E1 0%, #237EDD 100%)';
                            break;
                        default : title_container.style.background = 'transparent';
                    }
                }
            }
        };
    }
}
