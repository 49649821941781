import {CardCategory} from "./CardCategory";
import {CardProduct} from "./CardProduct";
import {Categories} from "./Categories";
import {ColumnOneTwo} from "./ColumnOneTwo";
import {ColumnTwoOne} from "./ColumnTwoOne";
import {Container} from "./Container";
import {DoubleColumn} from "./DoubleColumn";
import {Net} from "./Net";
import {OneColumn} from "./OneColumn";
import {QuarterСolumn} from "./QuarterСolumn";
import {Spacer} from "./Spacer";
import {TextBlock} from "./TextBlock";
import {TextBlockWithImage} from "./TextBlockWithImage";
import {TripleColumn} from "./TripleColumn";
import {ChapterElement} from "./Сhapter";

export default [
    CardCategory,
    CardProduct,
    Categories,
    ColumnTwoOne,
    ColumnOneTwo,
    Container,
    DoubleColumn,
    Net,
    OneColumn,
    QuarterСolumn,
    Spacer,
    TextBlock,
    TextBlockWithImage,
    TripleColumn,
    ChapterElement
];
