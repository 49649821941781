import {AboutMeWidget} from "./AboutMeWidget";
import {BusinessAvatarCompanyWidget} from "./BusinessAvatarCompanyWidget";
import {BusinessDescriptionWidget} from "./BusinessDescriptionWidget";
import {BusinessLocalMenu} from "./BusinessLocalMenu";
import {BusinessNewsWidget} from "./BusinessNewsWidget";
import {CareerWidget} from "./CareerWidget";
import {CloudStorageWidget} from "./CloudStorageWidget";
import {CommentariesFriendsWidget} from "./CommentariesFriendsWidget";
import {CommunitiesWidget} from "./CommunitiesWidget";
import {ConnectionComponent} from "./ConnectionComponent";
import {ContactsWidget} from "./ContactsWidget";
import {FooterWidget} from "./FooterWidget";
import {FriendsWidget} from "./FriendsWidget";
import {LastPhotoWidget} from "./LastPhotoWidget";
import {LastSubscribesPostWidget} from "./LastSubscribesPostWidget";
import {MainFeedWidget} from "./MainFeedWidget";
import {MarketPlaceWidget} from "./MarketPlaceWidget";
import {MediaWidget} from "./MediaWidget";
import {NewsWidget} from "./NewsWidget";
import {OrganizerWidget} from "./OrganizerWidget";
import {PersonalDataWidget} from "./PersonalDataWidget";
import {PersonalLocalMenu} from "./PersonalLocalMenu";
import {PopularPostsWidget} from "./PopularPostsWidget";
import {ProfileReferendumWidget} from "./ProfileReferendumWidget";
import {PurseWidget} from "./PurseWidget";
import {StateWidget} from "./StateWidget";
import {SubscriptionPostsListWidget} from "./SubscriptionPostsListWidget";
import {TrendingWidget} from "./TrendingWidget";
import {VideoTheDayWidget} from "./VideoTheDayWidget";
import {PhotoAndVideo} from "./PhotoAndVideo";

export {
    AboutMeWidget,
    BusinessNewsWidget,
    BusinessDescriptionWidget,
    BusinessLocalMenu,
    BusinessAvatarCompanyWidget,
    CareerWidget,
    CloudStorageWidget,
    CommentariesFriendsWidget,
    CommunitiesWidget,
    ConnectionComponent,
    ContactsWidget,
    FooterWidget,
    FriendsWidget,
    LastPhotoWidget,
    LastSubscribesPostWidget,
    MainFeedWidget,
    MarketPlaceWidget,
    MediaWidget,
    NewsWidget,
    OrganizerWidget,
    PersonalLocalMenu,
    PersonalDataWidget,
    PopularPostsWidget,
    ProfileReferendumWidget,
    PurseWidget,
    StateWidget,
    SubscriptionPostsListWidget,
    TrendingWidget,
    VideoTheDayWidget,
    PhotoAndVideo
};