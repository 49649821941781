export const CardProduct = ({editor}) => {
    const prefix = 'card_product';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="24" y="16" width="40" height="56" rx="4" fill="#D1EDFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9426 24.25H44.0574C46.3658 24.25 48.1748 24.25 49.5863 24.4397C51.031 24.634 52.1711 25.0393 53.0659 25.9341C53.9607 26.8289 54.366 27.969 54.5603 29.4137C54.75 30.8252 54.75 32.6342 54.75 34.9426V35.0309C54.75 36.9397 54.75 38.5023 54.6463 39.7745C54.5422 41.0531 54.3287 42.1214 53.8509 43.0087C53.6401 43.4001 53.3812 43.7506 53.0659 44.0659C52.1711 44.9607 51.031 45.366 49.5863 45.5603C48.1748 45.75 46.3658 45.75 44.0574 45.75H43.9426C41.6342 45.75 39.8252 45.75 38.4137 45.5603C36.969 45.366 35.8289 44.9607 34.9341 44.0659C34.1409 43.2726 33.7312 42.2852 33.5134 41.0604C33.2993 39.8573 33.2602 38.3603 33.2521 36.5015C33.25 36.0287 33.25 35.5286 33.25 35.001L33.25 34.9426C33.25 32.6342 33.25 30.8252 33.4397 29.4137C33.634 27.969 34.0393 26.8289 34.9341 25.9341C35.8289 25.0393 36.969 24.634 38.4137 24.4397C39.8252 24.25 41.6342 24.25 43.9426 24.25ZM38.6136 25.9264C37.3352 26.0982 36.5645 26.4251 35.9948 26.9948C35.4251 27.5645 35.0982 28.3352 34.9264 29.6136C34.7516 30.9136 34.75 32.6218 34.75 35C34.75 35.5287 34.75 36.0257 34.7521 36.4949C34.7603 38.369 34.8021 39.7406 34.9902 40.7978C35.1744 41.8333 35.4877 42.4981 35.9948 43.0052C36.5645 43.5749 37.3352 43.9018 38.6136 44.0736C39.9136 44.2484 41.6218 44.25 44 44.25C46.3782 44.25 48.0864 44.2484 49.3864 44.0736C50.6648 43.9018 51.4355 43.5749 52.0052 43.0052C52.2151 42.7953 52.3872 42.5631 52.5302 42.2976C52.8619 41.6816 53.0531 40.8578 53.1513 39.6527C53.2494 38.4482 53.25 36.9459 53.25 35C53.25 32.6218 53.2484 30.9136 53.0736 29.6136C52.9018 28.3352 52.5749 27.5645 52.0052 26.9948C51.4355 26.4251 50.6648 26.0982 49.3864 25.9264C48.0864 25.7516 46.3782 25.75 44 25.75C41.6218 25.75 39.9136 25.7516 38.6136 25.9264Z" fill="#0099FF"/>
                    <path d="M53.2487 36.2804L53.0266 36.2497C50.1828 35.8559 47.5805 37.3343 46.2554 39.5626C44.5459 35.2376 40.0284 32.2881 34.9807 33.0129L34.755 33.0454C34.7501 33.6421 34.75 34.2913 34.75 35C34.75 35.5287 34.75 36.0257 34.7521 36.4949C34.7603 38.369 34.8021 39.7406 34.9902 40.7978C35.1744 41.8333 35.4877 42.4981 35.9948 43.0052C36.5645 43.5749 37.3352 43.9018 38.6136 44.0736C39.9136 44.2484 41.6218 44.25 44 44.25C46.3782 44.25 48.0864 44.2484 49.3864 44.0736C50.6648 43.9018 51.4355 43.5749 52.0052 43.0052C52.2151 42.7953 52.3872 42.5631 52.5302 42.2976C52.8619 41.6816 53.0531 40.8578 53.1513 39.6527C53.2267 38.7267 53.2445 37.6247 53.2487 36.2804Z" fill="#99D6FF"/>
                    <path d="M50 31C50 32.1046 49.1046 33 48 33C46.8954 33 46 32.1046 46 31C46 29.8954 46.8954 29 48 29C49.1046 29 50 29.8954 50 31Z" fill="#0099FF"/>
                    <path d="M34 54H54" stroke="#0099FF" stroke-width="4" stroke-linecap="round"/>
                    <path d="M33 59H55" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                    <path d="M33 63H49" stroke="#0099FF" stroke-width="2" stroke-linecap="round"/>
                </svg>`,
        prefix,
        category: 'Структуры',
        select: true,
        content: {type: prefix},
        label: 'Карточка товара'
    });

    function script() {

    }

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_card d-flex flex-column justify-content-between'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_card_image'
                        },
                        components: [
                            {
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    src: 'https://zlato.ua/upload/iblock/52e/zolotoe_koltso_gardeniya_s_fianitami_000055247.jpg'
                                }
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_'
                        },
                        components: [
                            {
                                tagName: 'span',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                type: 'text',
                                attributes: {
                                    class: prefix + '_card_info_eyebrow',
                                },
                                content: 'Новинка'
                            },
                            {
                                tagName: 'p',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                type: 'text',
                                attributes: {
                                    class: prefix + '_card_info_title',
                                },
                                content: 'Используйте такие карточки для  представления ваших товаров и услуг'
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_card_info'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_card_info_price d-flex flex-row align-items-center',
                                },
                                components: [
                                    {
                                        tagName: 'p',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        type: 'text',
                                        attributes: {
                                            class: prefix + '_price_new',
                                        },
                                        content: '82 000 &#x20bd'
                                    },
                                    {
                                        tagName: 'span',
                                        type: 'text',
                                        attributes: {
                                            class: prefix + '_price_old'
                                        },
                                        content: '85 000 &#x20bd'
                                    }
                                ]
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_card {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                        color: hsla(0, 0%, 0%, 0.54);
                        font-size: 12px;
                    }
                    .${prefix}_card_image {
                        border-radius: 8px;
                        border: 1px solid hsla(0, 0%, 0%, 0.12);
                        padding: 10px;
                        position: relative;
                        height: 140px;
                    }
                     .${prefix}_card_image > img {
                        width: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                        background: white;
                     }
                     .${prefix}_price_new {
                        color: black;
                        font-size: 16px;
                        margin: 0;
                        margin-right: 8px;
                     }
                     .${prefix}_price_old {
                        font-size: 14px;
                        text-decoration: line-through;
                     }
                     .${prefix}_card_info_title {
                        font-weight: 600;
                        color: black;
                        font-size: 16px;
                        margin: 0;
                        margin-top: 5px;
                     }
                `
            }
        }
    });
};
