export const ecommerce = (params) => {
    const name = params.surname ? params.surname + ' ' + params.name : params.name;

    const prefix = 'ecommerce_business';
    return {
        tagName: 'div',
        type: 'page',
        attributes: {
            class: prefix + '_sample__container'
        },
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        selectable: false,
        components: [
            {
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                attributes: {
                    class: prefix + '_hero_container'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        attributes: {
                            class: prefix + '_hero_images flex-1'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                layerable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_hero_bg'
                                }
                            },
                            {
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    class: prefix + '_hero_img ' + prefix + '_hero_img_1',
                                    src: 'https://www.cnet.com/a/img/resize/7dc61a88052f656e89de965da1bd3c3f5b71e58b/hub/2017/06/06/349b7e65-a855-44f0-ae0a-6610ebca0809/apple-macbook-pro-12-inch-2017-4181.jpg?auto=webp&fit=crop&height=900&width=1200'
                                }
                            },
                            {
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    class: prefix + '_hero_img ' + prefix + '_hero_img_2',
                                    src: 'https://i.ebayimg.com/images/g/TcUAAOSwDoNeMNaH/s-l1200.webp'
                                }
                            },
                            {
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    class: prefix + '_hero_img ' + prefix + '_hero_img_3',
                                    src: 'https://imageio.forbes.com/specials-images/imageserve/64fce5dee53f0537a173b638/IMG-0046/960x0.jpg?format=jpg&width=960'
                                }
                            },
                            {
                                tagName: 'span',
                                removable: false,
                                layerable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_hero_discount',
                                },
                                content: '5%'
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_hero_content d-flex flex-column align-items-start flex-1'
                        },
                        components: [
                            {
                                tagName: 'img',
                                type: 'image',
                                attributes: {
                                    class: prefix + '_content_avatar'
                                }
                            },
                            {
                                tagName: 'h1',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_title'
                                },
                                content: name
                            },
                            {
                                tagName: 'p',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_subtitle'
                                },
                                content: 'Краткое описание ассортимента. Укажите свои приоритетные направления и целевые аудитории'
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                layerable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    class: prefix + '_content_action_items'
                                },
                                components: [
                                    {
                                        type: 'Main Submits',
                                        removable: false,
                                        layerable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        tagName: 'div',
                                        attributes: {
                                            class: prefix + '_action_items'
                                        },
                                        content: `
                                            <button class="${prefix}_item main__subscribe_item" id='album__btn-add_friends'>
                                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.83C2 8.12153 2 7.26729 2.33776 6.61708C2.62239 6.06915 3.06915 5.62239 3.61708 5.33776C4.26729 5 5.12153 5 6.83 5H9V22H2V9.83ZM4 8C4 7.44772 4.44772 7 5 7H8V9H5C4.44772 9 4 8.55228 4 8ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H8V10H5ZM4 14C4 13.4477 4.44772 13 5 13H8V15H5C4.44772 15 4 14.5523 4 14Z" fill="#0068AD"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.83C7 5.12153 7 4.26729 7.33776 3.61708C7.62239 3.06915 8.06915 2.62239 8.61708 2.33776C9.26729 2 10.1215 2 11.83 2H12.17C13.8785 2 14.7327 2 15.3829 2.33776C15.9309 2.62239 16.3776 3.06915 16.6622 3.61708C17 4.26729 17 5.12153 17 6.83V21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H7V6.83ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H10ZM9 9C9 8.44771 9.44772 8 10 8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10C9.44772 10 9 9.55229 9 9ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H10ZM9 15C9 14.4477 9.44772 14 10 14H14C14.5523 14 15 14.4477 15 15C15 15.5523 14.5523 16 14 16H10C9.44772 16 9 15.5523 9 15ZM12 18C11.4477 18 11 18.4477 11 19V21H13V19C13 18.4477 12.5523 18 12 18Z" fill="white"/>
                                                    <circle cx="18" cy="14" r="5" fill="#0068AD"/>
                                                    <path d="M18 16L18 12M16 14H20" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                                </svg>
                                                <span class="main__item-title">Подписаться</span>
                                            </button>
                                            <div>
                                                <button class="${prefix}_item ${prefix}_nav_menu" id='album__btn-show-nav-menu' data-toggle="dropdown" aria-expanded="false">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        `
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'Main Submits',
                tagName: 'div',
                attributes: {
                    class: prefix + '_locals-page-menu'
                },
                removable: false,
                layerable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                components: [
                    {
                        type: 'business_local_menu',
                        hoverable: false,
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                    },
                ]
            },
            {
                tagName: 'main',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                attributes: {
                    class: prefix + '_body__content',
                },
                type: 'container',
                components: [
                    {
                        type: 'chapter'
                    },
                    {
                        type: 'categories'
                    },
                    {
                        type: 'chapter'
                    },
                ]
            },
            {
                tagName: 'hr',
                layerable: false,
            },
            {
                type: 'footer'
            }
        ],
        styles: `
            body {
                    background-color: #F3F5F5; 
            } 
            .${prefix}_hero_container {
                height: 100%;
                background: #E0F3FF;
                padding: 75px 40px;
                font-family: sans-serif;
                display: flex;
                justify-content: space-around;
            }
            .${prefix}_hero_images {
                width: 650px;
                height: 100%;
                position: relative;
            }
            .${prefix}_hero_img {
                object-fit: cover;
                border-radius: 10px;
                border: 10px solid #fff;
                position: absolute;
            }
            .${prefix}_content_avatar {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
            .${prefix}_hero_bg {
                border-radius: 50%;
                background: #008DEB;
                width: 500px;
                height: 500px;
                position: absolute;
                top: 7%;
                left: 0;
            }
            .${prefix}_hero_img_1 {
                width: 350px;
                height: 575px;
                border: 24px solid #0000000A;
                left: 5%;
                top: 0;
                position: relative;
            }
            .${prefix}_hero_img_2 {
                border-radius: 50%;
                width: 320px;
                height: 320px;
                right: 5%;
                top: 12%;
            }
            .${prefix}_hero_img_3 {
                border-radius: 50%;
                width: 200px;
                height: 200px;
                left: -15%;
                bottom: 0px;
            }
            .${prefix}_hero_discount {
               width: 125px;
               height: 125px;
               position: absolute;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 50%;
               bottom: 15%;
               right: 27%;
               background: #0099FF;
               font-size: 50px;
               color: #FFFFFF8A;
               font-wight: 500;
            }
            .${prefix}_hero_content {
                min-width: 400px;
                max-width: 600px;
                color: #008DEB;
            } 
            .${prefix}_hero_content > .${prefix}_content_avatar {
                border-radius: 50%;
            } 
            .${prefix}_hero_content > .${prefix}_content_title {
               font-size: 50px;
               font-weight: 500;
               letter-spacing: -1px;
               padding: 18px 0px 6px 0px;
            }
             .${prefix}_hero_content > .${prefix}_content_subtitle {
                font-size: 21px;
                font-wight: 400px;
                padding: 13px 0 27px 0;
                max-width: 400px;
             }
             .${prefix}_action_items {
                display: flex;
                position: relative;
             } 
             .${prefix}_nav_menu {
                padding: 5px 15px;
                margin-left: 10px;
                border: none;
                background: none;
                height: 100%;
                border-radius: 10px;
                transition: all .2s ease;
             }
             .${prefix}_nav_menu:hover {
                background: white;
             }
             .main__subscribe_item {
                cursor: pointer;
                border-radius: 10px;
                border: none;
                color: white;
                background: #0099ff;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                align-items: center;
                padding: 12px 16px;
            }
            .${prefix}_locals-page-menu {
                display: flex;
                justify-content: center;
                margin: 24px 5rem 35px;
            }
         
            .${prefix}_body__content {
                padding-bottom: 55px;
            }
        `
    };
};
