import { createSlice } from "@reduxjs/toolkit";

export const handler = createSlice({
    name: 'handler',
    initialState: {
        name: 'initial',
        params: {}
    },
    reducers: {
        set: (state, action) => {
            state.name = action.payload.name;
            state.params = action.payload.params;
        }
    }
});

export const { set } = handler.actions;
export default handler.reducer;