export const BusinessNewsWidget = ({editor}) => {
    const prefix = 'business_news';

    editor.Blocks.add(prefix, {
        media: `
            <svg width="65" height="65" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5049 11.1716C11.3334 12.3431 11.3334 14.2288 11.3334 18V22C11.3334 25.7712 11.3334 27.6569 12.5049 28.8284C13.6765 30 15.5621 30 19.3334 30H21.3334C25.1046 30 26.9902 30 28.1618 28.8284C29.3334 27.6569 29.3334 25.7712 29.3334 22V18C29.3334 14.2288 29.3334 12.3431 28.1618 11.1716C26.9902 10 25.1046 10 21.3334 10H19.3334C15.5621 10 13.6765 10 12.5049 11.1716Z" fill="#99D6FF"/>
                <path d="M14.3334 20C14.3334 18.5858 14.3334 17.8787 14.7727 17.4393C15.2121 17 15.9192 17 17.3334 17H23.3334C24.7476 17 25.4547 17 25.894 17.4393C26.3334 17.8787 26.3334 18.5858 26.3334 20V24C26.3334 25.4142 26.3334 26.1213 25.894 26.5607C25.4547 27 24.7476 27 23.3334 27H17.3334C15.9192 27 15.2121 27 14.7727 26.5607C14.3334 26.1213 14.3334 25.4142 14.3334 24V20Z" fill="#008DEB"/>
                <path d="M15.3334 13.25C14.9192 13.25 14.5834 13.5858 14.5834 14C14.5834 14.4142 14.9192 14.75 15.3334 14.75H20.3334C20.7476 14.75 21.0834 14.4142 21.0834 14C21.0834 13.5858 20.7476 13.25 20.3334 13.25H15.3334Z" fill="#008DEB"/>
            </svg>
        `,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type:prefix},
        label: 'Бизнес новости'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_card'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_card-header',
                                },
                                content: `
                                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.333374 20C0.333374 8.95431 9.28768 0 20.3334 0C31.3791 0 40.3334 8.95431 40.3334 20C40.3334 31.0457 31.3791 40 20.3334 40C9.28768 40 0.333374 31.0457 0.333374 20Z" fill="#E0F3FF"/>
                                        <path d="M12.5049 11.1716C11.3334 12.3431 11.3334 14.2288 11.3334 18V22C11.3334 25.7712 11.3334 27.6569 12.5049 28.8284C13.6765 30 15.5621 30 19.3334 30H21.3334C25.1046 30 26.9902 30 28.1618 28.8284C29.3334 27.6569 29.3334 25.7712 29.3334 22V18C29.3334 14.2288 29.3334 12.3431 28.1618 11.1716C26.9902 10 25.1046 10 21.3334 10H19.3334C15.5621 10 13.6765 10 12.5049 11.1716Z" fill="#99D6FF"/>
                                        <path d="M14.3334 20C14.3334 18.5858 14.3334 17.8787 14.7727 17.4393C15.2121 17 15.9192 17 17.3334 17H23.3334C24.7476 17 25.4547 17 25.894 17.4393C26.3334 17.8787 26.3334 18.5858 26.3334 20V24C26.3334 25.4142 26.3334 26.1213 25.894 26.5607C25.4547 27 24.7476 27 23.3334 27H17.3334C15.9192 27 15.2121 27 14.7727 26.5607C14.3334 26.1213 14.3334 25.4142 14.3334 24V20Z" fill="#008DEB"/>
                                        <path d="M15.3334 13.25C14.9192 13.25 14.5834 13.5858 14.5834 14C14.5834 14.4142 14.9192 14.75 15.3334 14.75H20.3334C20.7476 14.75 21.0834 14.4142 21.0834 14C21.0834 13.5858 20.7476 13.25 20.3334 13.25H15.3334Z" fill="#008DEB"/>
                                    </svg>
                                `,
                                components: []
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_card_body'
                                },
                                components: [
                                    {
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        // selectable: false,
                                        type: 'text',
                                        tagName: 'p',
                                        content: '&#8226; Здесь будут выводится ссылки на новости в вашей ленте. '
                                    },
                                    {
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        // selectable: false,
                                        type: 'text',
                                        tagName: 'p',
                                        content: '&#8226; Пока новостей нет.'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_card_footer'
                        },
                        components: [
                            {
                                tagName: 'a',
                                attributes: {
                                    href: '#'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: 'Все новости'
                            },
                        ]
                    }
                ],
                styles: `
                    .${prefix}_card {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                         display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    .${prefix}_card_body {
                         margin-top: 20px;
                    }
                    .${prefix}_card_body p {
                        padding-left: 10px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: hsla(0, 0%, 0%, 0.54);
                    }
                    .${prefix}_card_body p::before {
                        content: '.',
                        position: absolute,
                        width: 10px;
                        height: 10px;
                    }
                     .${prefix}_card_footer a {
                        color: hsla(204, 100%, 37%, 1);
                        font-size: 14px;
                        text-decoration: none;
                        cursor: pointer;
                        font-weight: 600;
                    }
                `
            }
        }
    });
};
