import ko from 'knockout';
import Handler from 'engine/Handler';
//import 'knockout-mapping';
export default class community_layout extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.tab_sample = ko.observable(this.Store.getState().sample.sample_style);

        console.log(this.Store.getState().sample.sample_style)
        this.Store.subscribe(() => {
            this.tab_sample(this.Store.getState().sample.sample_style);
        });

    }
}
