export const CareerWidget = ({editor}) => {
    let prefix = 'career_widget';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8324 29.8013C23.9583 29.1747 28 26.926 28 21.1112C28 15.8196 24.1267 12.2959 21.3415 10.6768C20.7235 10.3176 20 10.7901 20 11.5049V13.3334C20 14.7753 19.3938 17.4071 17.7093 18.5018C16.8493 19.0607 15.9205 18.2242 15.816 17.2039L15.7302 16.366C15.6304 15.392 14.6384 14.8008 13.86 15.3946C12.4615 16.4614 11 18.3296 11 21.1112C11 28.2223 16.2889 30.0001 18.9333 30.0001C19.0871 30.0001 19.2488 29.9955 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013Z" fill="#99D6FF"/>
                    <path d="M16 26.4442C16 29.064 18.1113 29.8742 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013C21.871 29.4343 23 28.4922 23 26.4442C23 25.1465 22.1814 24.3459 21.5401 23.9711C21.3439 23.8564 21.1161 24.0008 21.0985 24.2273C21.0429 24.9454 20.3534 25.5174 19.8836 24.9714C19.4685 24.4889 19.2941 23.784 19.2941 23.3331V22.7439C19.2941 22.3887 18.9365 22.1533 18.631 22.3346C17.4951 23.0085 16 24.3949 16 26.4442Z" fill="#008DEB"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix, name: 'Карьера'},
        label: 'Карьера'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container d-flex flex-column align-items-center justify-content-center'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: ' d-flex flex-column align-items-center'
                        },
                        components : [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_icon'
                                },
                                content: `
                                 <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42.6666 24.5997V22.5996L6.66675 22.5996V24.5997C6.66855 31.9504 6.73652 39.3758 9.30274 41.6569C11.9388 44 16.1814 44 24.6667 44C33.152 44 37.3946 44 40.0307 41.6569C42.5969 39.3758 42.6648 31.9504 42.6666 24.5997Z" fill="black" fill-opacity="0.12"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4952 4C18.8285 4 16.6668 6.16176 16.6668 8.82843C16.6668 9.04814 16.5795 9.25885 16.4241 9.41421L13.8383 12.0001C10.7568 12.0013 9.16219 12.0296 7.9321 12.6594C6.81159 13.2332 5.89993 14.1448 5.32618 15.2654C4.66675 16.5532 4.66675 18.2405 4.66675 21.6153C4.66675 22.4732 4.66675 22.9022 4.81348 23.2639C4.94161 23.5797 5.14833 23.8575 5.414 24.071C5.71823 24.3154 6.12911 24.4387 6.95087 24.6852L17.6667 27.9V30.3254C17.6667 31.8566 18.5855 33.2681 20.0404 33.8593L21.1606 34.3144C23.4105 35.2285 25.923 35.2285 28.1729 34.3144L29.2931 33.8593C30.748 33.2681 31.6667 31.8566 31.6667 30.3254V27.9L42.3826 24.6852C43.2044 24.4387 43.6153 24.3154 43.9195 24.071C44.1852 23.8575 44.3919 23.5797 44.52 23.2639C44.6667 22.9022 44.6667 22.4732 44.6667 21.6153C44.6667 18.2405 44.6667 16.5532 44.0073 15.2654C43.4336 14.1448 42.5219 13.2332 41.4014 12.6594C40.1713 12.0296 38.5767 12.0013 35.4953 12.0001L32.9094 9.41421C32.7541 9.25885 32.6668 9.04814 32.6668 8.82843C32.6668 6.16176 30.505 4 27.8383 4H21.4952ZM29.8545 12C29.0911 11.1238 28.6668 9.99797 28.6668 8.82843C28.6668 8.3709 28.2959 8 27.8383 8H21.4952C21.0377 8 20.6668 8.3709 20.6668 8.82843C20.6668 9.99797 20.2425 11.1238 19.4791 12H29.8545Z" fill="black" fill-opacity="0.38"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8667 24H21.4667C21.0249 24 20.6667 24.3638 20.6667 24.8126V28.3254C20.6667 28.6577 20.8659 28.9565 21.1696 29.0799L22.3542 29.535C23.8801 30.155 25.1409 30.155 26.6667 29.535L28.1639 29.0799C28.4676 28.9565 28.6667 28.6577 28.6667 28.3254V24.8126C28.6667 24.3638 28.3086 24 27.8667 24Z" fill="black" fill-opacity="0.12"/>
                                </svg>
                                `
                            },
                            {
                                tagName: 'h5',
                                type: 'text',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_content_title'
                                },
                                content: 'Карьера'
                            },
                            {
                                tagName: 'span',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_about'
                                },
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                content: `<span>Автор цифрового контента</span>`
                            },
                            {
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                tagName: 'span',
                                type: 'text',
                                attributes: {
                                    class: prefix + '_content_about'
                                },
                                content: `Нет предыдущих мест работы`
                            },
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        font-family: sans-serif;
                        color: #0000008A;
                        height: 100%;
                     }
                     .${prefix}_content_title {
                        font-size: 38px;
                        font-weight: 500;
                        line-height: 40px;
                        text-align: center;
                        margin-top: 56px;
                        margin-bottom: 16px;
                        color: black;
                     }
                     .${prefix}_content_about > span {
                        font-weight: 600;
                     }
                `
            }
        }
    });
};
