import ko from 'knockout';
import Handler from 'engine/Handler';

//import 'knockout-mapping';
export default class statistic_activity extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.stat_feedback = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 88 },
                { year: '', count: 20 },
                { year: '', count: 35 },
                { year: '', count: 62 },
                { year: '', count: 28 }
            ]
        };

        this.stat_click_event = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 88 },
                { year: '', count: 20 },
                { year: '', count: 35 },
                { year: '', count: 62 },
                { year: '', count: 28 }
            ]
        };

        this.stat_transition = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 88 },
                { year: '', count: 20 },
                { year: '', count: 35 },
                { year: '', count: 62 },
                { year: '', count: 28 }
            ]
        };
    }
}
