 import ko from 'knockout';
 import 'knockout-mapping';
 import Handler from "engine/Handler";
  import 'styles/style_old.min.css';

 export default class reg_account_choose extends Handler{
     constructor({ Store, Router, Server, i18next }) {
         super({ Store, Router, Server, i18next });
        if (Store.getState().auth.value)
            Router.navigate('/my_page');
        this.type = ko.observable('company');
    }

}
