export const PurseWidget = ({editor}) => {
    const prefix = 'purse';

    editor.Blocks.add(prefix, {
        media: ` <svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.1394 16.0015C29.1394 14.8209 29.0965 13.5545 28.3418 12.6466C28.2689 12.5589 28.1914 12.4738 28.1088 12.3912C27.3604 11.6429 26.4114 11.3108 25.239 11.1531C24.0998 11 22.6442 11 20.8064 11H18.6936C16.8558 11 15.4002 11 14.261 11.1531C13.0886 11.3108 12.1396 11.6429 11.3912 12.3912C10.6429 13.1396 10.3108 14.0886 10.1531 15.261C9.99997 16.4002 9.99999 17.8558 10 19.6936V19.8064C9.99999 21.6442 9.99997 23.0998 10.1531 24.239C10.3108 25.4114 10.6429 26.3604 11.3912 27.1088C12.1396 27.8571 13.0886 28.1892 14.261 28.3469C15.4002 28.5 16.8558 28.5 18.6935 28.5H20.8064C22.6442 28.5 24.0998 28.5 25.239 28.3469C26.4114 28.1892 27.3604 27.8571 28.1088 27.1088C28.3133 26.9042 28.487 26.6844 28.6346 26.4486C29.0851 25.7291 29.1394 24.8473 29.1394 23.9985C29.0912 24 29.0404 24 28.9882 24L26.2149 24C23.9435 24 22 22.2639 22 20C22 17.7361 23.9435 16 26.2149 16L28.9881 16C29.0403 16 29.0912 16 29.1394 16.0015Z" fill="#99D6FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1884 16.0038C29.1262 16 29.0584 16 28.9881 16L28.9706 16H26.2149C23.9435 16 22 17.7361 22 20C22 22.2639 23.9435 24 26.2149 24H28.9706L28.9881 24C29.0584 24 29.1262 24 29.1884 23.9962C30.111 23.9397 30.927 23.2386 30.9956 22.2594C31.0001 22.1952 31 22.126 31 22.0619L31 22.0444V17.9556L31 17.9381C31 17.874 31.0001 17.8048 30.9956 17.7406C30.927 16.7614 30.111 16.0603 29.1884 16.0038ZM25.9706 21.0667C26.5554 21.0667 27.0294 20.5891 27.0294 20C27.0294 19.4109 26.5554 18.9333 25.9706 18.9333C25.3858 18.9333 24.9118 19.4109 24.9118 20C24.9118 20.5891 25.3858 21.0667 25.9706 21.0667Z" fill="#008DEB"/>
                    <line x1="18" y1="15" x2="14" y2="15" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Кошелек'
    });

    const scripts = () => {
        document.querySelectorAll('.item_top_up').forEach(item => {
            item.addEventListener('click', () => {
                alert("Пополнить счет");
            });
        });
        document.querySelectorAll('.item_withdraw').forEach(item => {
            item.addEventListener('click', () => {
                alert("Вывести деньги");
            });
        });
    };

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script: scripts,
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + 'title_section'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        components: [
                            {
                                content: `
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                        <path d="M29.1394 16.0015C29.1394 14.8209 29.0965 13.5545 28.3418 12.6466C28.2689 12.5589 28.1914 12.4738 28.1088 12.3912C27.3604 11.6429 26.4114 11.3108 25.239 11.1531C24.0998 11 22.6442 11 20.8064 11H18.6936C16.8558 11 15.4002 11 14.261 11.1531C13.0886 11.3108 12.1396 11.6429 11.3912 12.3912C10.6429 13.1396 10.3108 14.0886 10.1531 15.261C9.99997 16.4002 9.99999 17.8558 10 19.6936V19.8064C9.99999 21.6442 9.99997 23.0998 10.1531 24.239C10.3108 25.4114 10.6429 26.3604 11.3912 27.1088C12.1396 27.8571 13.0886 28.1892 14.261 28.3469C15.4002 28.5 16.8558 28.5 18.6935 28.5H20.8064C22.6442 28.5 24.0998 28.5 25.239 28.3469C26.4114 28.1892 27.3604 27.8571 28.1088 27.1088C28.3133 26.9042 28.487 26.6844 28.6346 26.4486C29.0851 25.7291 29.1394 24.8473 29.1394 23.9985C29.0912 24 29.0404 24 28.9882 24L26.2149 24C23.9435 24 22 22.2639 22 20C22 17.7361 23.9435 16 26.2149 16L28.9881 16C29.0403 16 29.0912 16 29.1394 16.0015Z" fill="#99D6FF"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1884 16.0038C29.1262 16 29.0584 16 28.9881 16L28.9706 16H26.2149C23.9435 16 22 17.7361 22 20C22 22.2639 23.9435 24 26.2149 24H28.9706L28.9881 24C29.0584 24 29.1262 24 29.1884 23.9962C30.111 23.9397 30.927 23.2386 30.9956 22.2594C31.0001 22.1952 31 22.126 31 22.0619L31 22.0444V17.9556L31 17.9381C31 17.874 31.0001 17.8048 30.9956 17.7406C30.927 16.7614 30.111 16.0603 29.1884 16.0038ZM25.9706 21.0667C26.5554 21.0667 27.0294 20.5891 27.0294 20C27.0294 19.4109 26.5554 18.9333 25.9706 18.9333C25.3858 18.9333 24.9118 19.4109 24.9118 20C24.9118 20.5891 25.3858 21.0667 25.9706 21.0667Z" fill="#008DEB"/>
                                        <line x1="18" y1="15" x2="14" y2="15" stroke="#008DEB" stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                `
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                content: `
                                   <div class="${prefix}_subtitle">
                                        <h4 class="main__item-title">В кошельке</h4>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 18L15 12L9 6" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <h3 class="${prefix}_value">32 400 016,00 &#8381;</h3>
                                `
                            },
                        ],
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_action_items'
                        },
                        components: [
                            {
                                tagName: 'button',
                                attributes: {
                                    class: prefix + '_item item_top_up'
                                },
                                content: `
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z" fill="#C7CCD1"/>
                                        <path d="M11.5 15C11.5 15.5523 11.9477 16 12.5 16C13.0523 16 13.5 15.5523 13.5 15V13H15.5C16.0523 13 16.5 12.5523 16.5 12C16.5 11.4477 16.0523 11 15.5 11H13.5V9C13.5 8.44772 13.0523 8 12.5 8C11.9477 8 11.5 8.44772 11.5 9V11H9.5C8.94772 11 8.5 11.4477 8.5 12C8.5 12.5523 8.94772 13 9.5 13H11.5V15Z" fill="black" fill-opacity="0.38"/>
                                    </svg>
                                    <span>Пополнить</span>
                                `
                            },
                            {
                                tagName: 'button',
                                attributes: {
                                    class: prefix + '_item item_withdraw'
                                },
                                content: `
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4697 8.46969C17.6842 8.25519 18.0068 8.19103 18.287 8.30711C18.5673 8.4232 18.75 8.69668 18.75 9.00002V18C18.75 18.4142 18.4142 18.75 18 18.75L9.00002 18.75C8.69668 18.75 8.4232 18.5673 8.30711 18.287C8.19103 18.0068 8.25519 17.6842 8.46969 17.4697L17.4697 8.46969Z" fill="black" fill-opacity="0.38"/>
                                        <path d="M5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L13.5 12.4393L12.4393 13.5L5.46967 6.53033Z" fill="#C7CCD1"/>
                                    </svg>
                                    <span>Вывести</span>
                                `
                            }
                        ]
                    }
                ],
                styles: `
                    .${prefix}_title_section {
                        display: flex;
                        flex-direction: column;
                    }
                    .${prefix}_subtitle > .main__item-title {
                        margin-left: 0;
                         color: #0000008A;
                    }
                    .${prefix}_container {
                     position: absolute;
                        top: 0;
                        left: 0;
                       background: linear-gradient(159.19deg, rgba(0, 153, 255, 0.501961) 0%, rgba(0, 153, 255, 0.470588) 1.85%, rgba(0, 153, 255, 0.4) 6.77%, rgba(0, 153, 255, 0.301961) 14.76%, rgba(0, 153, 255, 0.2) 24.6%, rgba(0, 153, 255, 0.1) 35.68%, rgba(0, 153, 255, 0.03) 48.59%, rgba(0, 153, 255, 0.0001) 61.51%),
linear-gradient(0deg, #FCFDFD, #FCFDFD);
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-family: sans-serif;
                    }
                    .${prefix}_subtitle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                        margin-top: 24px;
                    }
                    .${prefix}_value {
                        color: #008DEB;
                        font-size: 38px;
                        font-weight: 500;
                        line-height: 40px;
                        margin: 0;
                    }
                    .${prefix}_action_items {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                    }
                    .${prefix}_action_items > button {
                        background: #0000000A;
                        border: none;
                        display: flex;
                        align-items: center;
                        padding: 15px 25px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-right: 8px;
                    }
                    .${prefix}_action_items > button:hover {
                         background: hsl(0, 0%, 92%);
                    }
                    .${prefix}_item > span {
                        margin-left: 8px;
                        color: #0000008A;
                        font-weight: 600;
                        font-size: 16px;
                    }
                `
            },
        }
    });
};
