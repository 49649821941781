export const VideoElement = ({editor}) => {
    const prefix = 'video_element';

    editor.Blocks.add(prefix, {
        media: `
            <svg width="45" height="65" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 20.1C4 14.4051 4 11.5576 5.12587 9.39026C6.07462 7.56382 7.56382 6.07462 9.39026 5.12587C11.5576 4 14.4051 4 20.1 4H27.9C33.5949 4 36.4424 4 38.6097 5.12587C40.4362 6.07462 41.9254 7.56382 42.8741 9.39026C44 11.5576 44 14.4051 44 20.1V27.9C44 33.5949 44 36.4424 42.8741 38.6097C41.9254 40.4362 40.4362 41.9254 38.6097 42.8741C36.4424 44 33.5949 44 27.9 44H20.1C14.4051 44 11.5576 44 9.39026 42.8741C7.56382 41.9254 6.07462 40.4362 5.12587 38.6097C4 36.4424 4 33.5949 4 27.9V20.1Z" fill="#D1EDFF"/>
                <path d="M11.9999 43.6894C10.9858 43.5228 10.1465 43.267 9.39016 42.8741C7.56373 41.9254 6.07453 40.4362 5.12577 38.6097C4.73286 37.8534 4.47707 37.0142 4.31055 36H11.9999V43.6894Z" fill="#0099FF"/>
                <path d="M20.0999 44H21.9999V36H15.9999V43.9765C17.181 44 18.5326 44 20.0999 44Z" fill="#0099FF"/>
                <path d="M25.9999 44H27.8999C29.4672 44 30.8188 44 31.9999 43.9765V36H25.9999V44Z" fill="#0099FF"/>
                <path d="M43.6893 36C43.5227 37.0142 43.2669 37.8534 42.874 38.6097C41.9253 40.4362 40.4361 41.9254 38.6096 42.8741C37.8533 43.267 37.0141 43.5228 35.9999 43.6894V36H43.6893Z" fill="#0099FF"/>
                <path d="M35.9999 4.31064C37.0141 4.47717 37.8533 4.73296 38.6096 5.12587C40.4361 6.07462 41.9253 7.56382 42.874 9.39026C43.2669 10.1466 43.5227 10.9858 43.6893 12H35.9999V4.31064Z" fill="#0099FF"/>
                <path d="M27.8999 4H25.9999V12H31.9999V4.02347C30.8188 4 29.4672 4 27.8999 4Z" fill="#0099FF"/>
                <path d="M21.9999 4H20.0999C18.5326 4 17.181 4 15.9999 4.02347V12H21.9999V4Z" fill="#0099FF"/>
                <path d="M11.9999 4.31064V12H4.31055C4.47707 10.9858 4.73286 10.1466 5.12577 9.39026C6.07453 7.56382 7.56373 6.07462 9.39016 5.12587C10.1465 4.73296 10.9858 4.47717 11.9999 4.31064Z" fill="#0099FF"/>
                <path d="M28 24C28 22.9444 26.9408 22.2325 24.8223 20.8087C22.675 19.3654 21.6013 18.6438 20.8006 19.1736C20 19.7035 20 21.1357 20 24C20 26.8643 20 28.2965 20.8006 28.8264C21.6013 29.3562 22.675 28.6346 24.8224 27.1913C26.9408 25.7675 28 25.0556 28 24Z" fill="#0099FF"/>
            </svg>
        `,
        prefix,
        category: 'Elements',
        select: true,
        content: {type: prefix, name: 'Video'},
        label: 'Video'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                draggable: '[data-gjs-type=one_column]',
                tagName: 'video',
                attributes: {
                    class: prefix,
                    src: 'https://assets.codepen.io/6093409/hubspot-video-example.mp4',
                    controls: true
                },
                styles: `
                    .${prefix} {
                      height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                    .${prefix}_video {
                       width: 100%;
                       height: 100%;
                       object-fit: cover;
                       border-radius: 10px;
                    }
                `
            }
        }
    });
};
