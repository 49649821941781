import Handler from 'engine/Handler';

//import 'knockout-mapping';
export default class statistic_review extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.stat_unique_visitors = {
            data: [
                { year: '', count: 20 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 45 },
                { year: '', count: 10 },
            ]
        };
        this.stat_new_subscribes = {
            data: [
                { year: '', count: 20 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 45 },
                { year: '', count: 10 },
            ]
        };
        this.stat_views = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 20 },
                { year: '2', count: 35 },
            ]
        };
        this.stat_new_dialogs = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 20 },
                { year: '2', count: 35 },
            ]
        };
        this.stat_all_dialogs = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 20 },
                { year: '2', count: 35 },
            ]
        };
        this.stat_comments = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 20 },
                { year: '2', count: 35 },
            ]
        };

        this.stat_transitions_to_site = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 20 },
                { year: '2', count: 35 },
            ]
        };
    }
}
