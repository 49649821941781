import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Chart from 'chart.js/auto';
export default class wallet_sidebar extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});

        const wallet_balance_chart_container = document.querySelector('#wallet-balance-graphic');
        if (wallet_balance_chart_container) {
            (async function() {
                const data = {
                    labels: [
                        'Red',
                        'Blue',
                        'Yellow'
                    ],
                    datasets: [{
                        label: 'My First Dataset',
                        data: [170, 30, 100],
                        backgroundColor: [
                            '#9747FF',
                            '#4293FF',
                            '#FEDE31'
                        ],
                        hoverOffset: 3
                    }]
                };
                new Chart(wallet_balance_chart_container, {
                        type: 'doughnut',
                        data: data,
                        options: {
                            responsive: false,
                            aspectRatio: 2.5,
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },
                            cutout: 70,
                        },
                    }
                );

            })();
        }

    }

}
