export const PersonalLocalMenu = ({editor}) => {

    const prefix = 'personal_local_menu';

    function script() {
        document.querySelector('#album__btn-next').addEventListener('click', () => {
            alert('Click next!');
        });
        document.querySelector('#album__btn-about_me').addEventListener('click', () => {
            alert('Click About me!');
        });
        document.querySelector('#album__btn-photo_video').addEventListener('click', () => {
            alert('Click Photo and Video!');
        });
        document.querySelector('#album__btn-all_friends').addEventListener('click', () => {
            alert('Click get All Friends!');
        });
        document.querySelector('#album__btn-add_friends').addEventListener('click', () => {
            alert('Click Add to Friends!');
        });
        document.querySelector('#album__btn-show-nav-menu').addEventListener('click', () => {
            document.querySelector('.sample__nav-menu').classList.toggle('active');
        });
    }

    editor.Components.addType('personal_local_menu', {
        model: {
            defaults: {
                script: script,
                type: 'Local Menu',
                tagName: 'div',
                attributes: {
                    class: prefix + '__container'
                },
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                layerable: false,
                components: [
                    {
                        tagName: 'button',
                        attributes: {
                            class: 'main__item',
                            id: 'album__btn-next'
                        },
                        type: 'button_next',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14 6C14 4.11438 14 3.17157 14.5858 2.58579C15.1716 2 16.1144 2 18 2C19.8856 2 20.8284 2 21.4142 2.58579C22 3.17157 22 4.11438 22 6C22 7.88562 22 8.82843 21.4142 9.41421C20.8284 10 19.8856 10 18 10C16.1144 10 15.1716 10 14.5858 9.41421C14 8.82843 14 7.88562 14 6Z" fill="#C7CCD1"/>
                                  <path d="M2 6C2 4.11438 2 3.17157 2.58579 2.58579C3.17157 2 4.11438 2 6 2C7.88562 2 8.82843 2 9.41421 2.58579C10 3.17157 10 4.11438 10 6C10 7.88562 10 8.82843 9.41421 9.41421C8.82843 10 7.88562 10 6 10C4.11438 10 3.17157 10 2.58579 9.41421C2 8.82843 2 7.88562 2 6Z" fill="black" fill-opacity="0.38"/>
                                  <path d="M14 18C14 16.1144 14 15.1716 14.5858 14.5858C15.1716 14 16.1144 14 18 14C19.8856 14 20.8284 14 21.4142 14.5858C22 15.1716 22 16.1144 22 18C22 19.8856 22 20.8284 21.4142 21.4142C20.8284 22 19.8856 22 18 22C16.1144 22 15.1716 22 14.5858 21.4142C14 20.8284 14 19.8856 14 18Z" fill="black" fill-opacity="0.38"/>
                                  <path d="M2 18C2 16.1144 2 15.1716 2.58579 14.5858C3.17157 14 4.11438 14 6 14C7.88562 14 8.82843 14 9.41421 14.5858C10 15.1716 10 16.1144 10 18C10 19.8856 10 20.8284 9.41421 21.4142C8.82843 22 7.88562 22 6 22C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18Z" fill="#C7CCD1"/>
                              </svg>
                              <span class="main__item-title">Начало</span>
                        `
                    },
                    {
                        tagName: 'button',
                        type: 'button_about_me',
                        attributes: {
                            class: 'main__item',
                            id: 'album__btn-about_me'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 10C2 6.22876 2 4.34315 3.17157 3.17157C4.34315 2 6.22876 2 10 2H14C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V10Z" fill="#C7CCD1"/>
                                    <path d="M12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z" fill="black" fill-opacity="0.38"/>
                                    <path d="M14 22C16.978 22 18.7802 22 19.9835 21.4231C20 21.1252 20 20.8164 20 20.5C20 18.0147 16.4183 16 12 16C7.58172 16 4 18.0147 4 20.5C4 20.8164 4 21.1251 4.01651 21.4231C5.21978 22 7.02198 22 10 22H14Z" fill="black" fill-opacity="0.38"/>
                                </svg>
                              <span class="main__item-title">Обо мне</span>
                        `
                    },
                    {
                        tagName: 'button',
                        type: 'button_photo_video',
                        attributes: {
                            class: 'main__item',
                            id: 'album__btn-photo_video'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9426 1.25H12.0574C14.3658 1.24999 16.1748 1.24998 17.5863 1.43975C19.031 1.63399 20.1711 2.03933 21.0659 2.93414C21.9607 3.82895 22.366 4.96897 22.5603 6.41371C22.75 7.82519 22.75 9.63423 22.75 11.9426V12.0309C22.75 13.9397 22.75 15.5023 22.6463 16.7745C22.5422 18.0531 22.3287 19.1214 21.8509 20.0087C21.6401 20.4001 21.3812 20.7506 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.14086 20.2726 1.7312 19.2852 1.51335 18.0604C1.29935 16.8573 1.2602 15.3603 1.25207 13.5015C1.25 13.0287 1.25 12.5286 1.25 12.001L1.25 11.9426C1.24999 9.63423 1.24998 7.82519 1.43975 6.41371C1.63399 4.96897 2.03933 3.82895 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.42514 4.56445 3.09825 5.33517 2.92637 6.61358C2.75159 7.91356 2.75 9.62177 2.75 12C2.75 12.5287 2.75 13.0257 2.75205 13.4949C2.76025 15.369 2.80214 16.7406 2.99017 17.7978C3.17436 18.8333 3.48774 19.4981 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.2151 19.7953 20.3872 19.5631 20.5302 19.2976C20.8619 18.6816 21.0531 17.8578 21.1513 16.6527C21.2494 15.4482 21.25 13.9459 21.25 12C21.25 9.62177 21.2484 7.91356 21.0736 6.61358C20.9018 5.33517 20.5749 4.56445 20.0052 3.9948C19.4355 3.42514 18.6648 3.09825 17.3864 2.92637C16.0864 2.75159 14.3782 2.75 12 2.75C9.62177 2.75 7.91356 2.75159 6.61358 2.92637Z"
                                       fill="black" fill-opacity="0.38"/>
                                 <path d="M21.2487 13.2804L21.0266 13.2497C18.1828 12.8559 15.5805 14.3343 14.2554 16.5626C12.5459 12.2376 8.02844 9.28807 2.98073 10.0129L2.75497 10.0454C2.75014 10.6421 2.75 11.2913 2.75 12C2.75 12.5287 2.75 13.0257 2.75205 13.4949C2.76025 15.369 2.80214 16.7406 2.99017 17.7978C3.17436 18.8333 3.48774 19.4981 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.2151 19.7953 20.3872 19.5631 20.5302 19.2976C20.8619 18.6816 21.0531 17.8578 21.1513 16.6527C21.2267 15.7267 21.2445 14.6247 21.2487 13.2804Z" fill="#C7CCD1"/>
                                 <path d="M18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8Z" fill="black" fill-opacity="0.38"/>
                              </svg>
                              <span class="main__item-title">Фото и Видео</span>
                        `
                    },
                    {
                        tagName: 'button',
                        attributes: {
                            class: 'main__item',
                            id: 'album__btn-all_friends'
                        },
                        type: 'button_friends',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        content: `
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="6" r="3" fill="black" fill-opacity="0.38"/>
                                <circle cx="8" cy="6" r="3" fill="#C7CCD1"/>
                                <path d="M2 15C2 13.1144 2 12.1716 2.58579 11.5858C3.17157 11 4.11438 11 6 11H9C9.94281 11 10.4142 11 10.7071 11.2929C11 11.5858 11 12.0572 11 13V19C11 19.9428 11 20.4142 10.7071 20.7071C10.4142 21 9.94281 21 9 21H4C3.05719 21 2.58579 21 2.29289 20.7071C2 20.4142 2 19.9428 2 19V15Z" fill="#C7CCD1"/>
                                <path d="M22 15C22 13.1144 22 12.1716 21.4142 11.5858C20.8284 11 19.8856 11 18 11H15C14.0572 11 13.5858 11 13.2929 11.2929C13 11.5858 13 12.0572 13 13V19C13 19.9428 13 20.4142 13.2929 20.7071C13.5858 21 14.0572 21 15 21H20C20.9428 21 21.4142 21 21.7071 20.7071C22 20.4142 22 19.9428 22 19V15Z" fill="black" fill-opacity="0.38"/>
                                <path d="M6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12Z" fill="black" fill-opacity="0.38"/>
                                <path d="M22 12C22 13.1046 21.1046 14 20 14C18.8954 14 18 13.1046 18 12C18 10.8954 18.8954 10 20 10C21.1046 10 22 10.8954 22 12Z" fill="#C7CCD1"/>
                              </svg>
                              <span class="main__item-title">Друзья</span>
                        `
                    }
                ],
                styles: `
                    .${prefix}__container {
                       display: flex;
                       align-items: center;
                    }
                    .main__item {
                        border-radius: 10px;
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500; 
                        padding: 12px 16px;
                        font-family: FixelText-SemiBold, sans-serif;
                        color: hsl(0, 0%, 50%);
                        cursor: pointer;
                    }
                    .main__item:hover {
                        background: hsl(0, 0%, 92%);
                    }
                    .main__item-title {
                        margin-left: 15px;
                    }
                `
            }
        }
    });
};
