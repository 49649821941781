export const NewsWidget = ({editor}) => {
    const prefix = 'news';

    editor.Blocks.add(prefix, {
        media: `  <svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="16" r="2" fill="#008DEB"/>
                        <path d="M16.2497 13.693C16.6152 13.2789 16.5758 12.647 16.1617 12.2815C15.7477 11.916 15.1157 11.9554 14.7503 12.3695L16.2497 13.693ZM25.2497 12.3695C24.8843 11.9554 24.2523 11.916 23.8383 12.2815C23.4242 12.647 23.3848 13.2789 23.7503 13.693L25.2497 12.3695ZM15 29L14.0876 28.5906C13.8976 29.0141 14.024 29.5127 14.3928 29.7945C14.7616 30.0764 15.2758 30.0674 15.6346 29.7729L15 29ZM20 17L20.9333 16.641C20.7848 16.2548 20.4138 16 20 16C19.5862 16 19.2152 16.2548 19.0667 16.641L20 17ZM25 29L24.3654 29.7729C24.7242 30.0674 25.2384 30.0764 25.6072 29.7945C25.976 29.5127 26.1024 29.0141 25.9124 28.5906L25 29ZM13.9371 20.5007C14.2136 20.9788 14.8254 21.1421 15.3034 20.8656C15.7815 20.589 15.9448 19.9773 15.6683 19.4993L13.9371 20.5007ZM24.3317 19.4993C24.0552 19.9773 24.2185 20.589 24.6966 20.8656C25.1746 21.1421 25.7864 20.9788 26.0629 20.5007L24.3317 19.4993ZM15 17C15 15.7312 15.4713 14.575 16.2497 13.693L14.7503 12.3695C13.6617 13.6029 13 15.2254 13 17H15ZM23.7503 13.693C24.5287 14.575 25 15.7312 25 17H27C27 15.2254 26.3383 13.6029 25.2497 12.3695L23.7503 13.693ZM15.9124 29.4094L18.6047 23.4094L16.7799 22.5906L14.0876 28.5906L15.9124 29.4094ZM18.6257 23.359L20.9333 17.359L19.0667 16.641L16.759 22.641L18.6257 23.359ZM25.6346 28.2271L20.6346 24.1219L19.3654 25.6676L24.3654 29.7729L25.6346 28.2271ZM20.6346 24.1219L18.3269 22.2271L17.0577 23.7729L19.3654 25.6676L20.6346 24.1219ZM19.0667 17.359L21.3743 23.359L23.241 22.641L20.9333 16.641L19.0667 17.359ZM21.3953 23.4094L24.0876 29.4094L25.9124 28.5906L23.2201 22.5906L21.3953 23.4094ZM15.6346 29.7729L20.6346 25.6676L19.3654 24.1219L14.3654 28.2271L15.6346 29.7729ZM20.6346 25.6676L22.9423 23.7729L21.6731 22.2271L19.3654 24.1219L20.6346 25.6676ZM15.6683 19.4993C15.2435 18.7648 15 17.9124 15 17H13C13 18.2733 13.3409 19.4701 13.9371 20.5007L15.6683 19.4993ZM25 17C25 17.9124 24.7565 18.7648 24.3317 19.4993L26.0629 20.5007C26.6591 19.4701 27 18.2733 27 17H25Z" fill="#008DEB"/>
                    </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix, name: 'Новостной виджет'},
        label: 'Главное в СМИ'
    });

    const newsList = [
        {
            icon: 'https://www.kostroma.net/posts/channels/5f00d3806a623.png',
            text: 'Актера Михаила Боярского госпитализировали с инфарктом в Мариинскую больницу'
        },
        {
            icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/TV78logo.svg/1200px-TV78logo.svg.png',
            text: 'Финляндия полностью закроет сухопутную границу с Россией'
        },
        {
            icon: 'https://www.omkc.ru/uploads/%D0%A0%D0%95%D0%94%20%D0%9C%D0%95%D0%94%D0%98%D0%90/T24.png',
            text: 'МИД РФ уведомил Японию о выходе из соглашения о ликвидации ядерного оружия'
        },
        {
            icon: 'https://www.kostroma.net/posts/channels/5f00d3806a623.png',
            text: 'Крупнейший в мире айсберг вынесло в воды Южного океана: чем это опасно'
        }
    ];

    let result_news_list = [];

    // eslint-disable-next-line array-callback-return
    newsList.map(item => {
        result_news_list.push(
            {
                tagName: 'div',
                removable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                editable: false,
                attributes: {
                    type: 'button',
                    class: prefix + '_article_item d-flex align-items-start mb-2',
                    title: item.text
                },
                components: [
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        tagName: 'img',
                        attributes: {
                            src: item.icon
                        }
                    },
                    {
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        tagName: 'p',
                        content: item.text
                    }
                ]
            }
        );
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_header_section d-flex align-items-center mb-4'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                             <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-lg-3">
                                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                <path d="M12.8586 10C11.0903 11.8038 10 14.2745 10 17C10 19.7255 11.0903 22.1962 12.8586 24M27.1414 10C28.9097 11.8038 30 14.2745 30 17C30 19.7255 28.9097 22.1962 27.1414 24" stroke="#99D6FF" stroke-width="2" stroke-linecap="round"/>
                                <circle cx="20" cy="16" r="2" fill="#008DEB"/>
                                <path d="M16.2497 13.693C16.6152 13.2789 16.5758 12.647 16.1617 12.2815C15.7477 11.916 15.1157 11.9554 14.7503 12.3695L16.2497 13.693ZM25.2497 12.3695C24.8843 11.9554 24.2523 11.916 23.8383 12.2815C23.4242 12.647 23.3848 13.2789 23.7503 13.693L25.2497 12.3695ZM15 29L14.0876 28.5906C13.8976 29.0141 14.024 29.5127 14.3928 29.7945C14.7616 30.0764 15.2758 30.0674 15.6346 29.7729L15 29ZM20 17L20.9333 16.641C20.7848 16.2548 20.4138 16 20 16C19.5862 16 19.2152 16.2548 19.0667 16.641L20 17ZM25 29L24.3654 29.7729C24.7242 30.0674 25.2384 30.0764 25.6072 29.7945C25.976 29.5127 26.1024 29.0141 25.9124 28.5906L25 29ZM13.9371 20.5007C14.2136 20.9788 14.8254 21.1421 15.3034 20.8656C15.7815 20.589 15.9448 19.9773 15.6683 19.4993L13.9371 20.5007ZM24.3317 19.4993C24.0552 19.9773 24.2185 20.589 24.6966 20.8656C25.1746 21.1421 25.7864 20.9788 26.0629 20.5007L24.3317 19.4993ZM15 17C15 15.7312 15.4713 14.575 16.2497 13.693L14.7503 12.3695C13.6617 13.6029 13 15.2254 13 17H15ZM23.7503 13.693C24.5287 14.575 25 15.7312 25 17H27C27 15.2254 26.3383 13.6029 25.2497 12.3695L23.7503 13.693ZM15.9124 29.4094L18.6047 23.4094L16.7799 22.5906L14.0876 28.5906L15.9124 29.4094ZM18.6257 23.359L20.9333 17.359L19.0667 16.641L16.759 22.641L18.6257 23.359ZM25.6346 28.2271L20.6346 24.1219L19.3654 25.6676L24.3654 29.7729L25.6346 28.2271ZM20.6346 24.1219L18.3269 22.2271L17.0577 23.7729L19.3654 25.6676L20.6346 24.1219ZM19.0667 17.359L21.3743 23.359L23.241 22.641L20.9333 16.641L19.0667 17.359ZM21.3953 23.4094L24.0876 29.4094L25.9124 28.5906L23.2201 22.5906L21.3953 23.4094ZM15.6346 29.7729L20.6346 25.6676L19.3654 24.1219L14.3654 28.2271L15.6346 29.7729ZM20.6346 25.6676L22.9423 23.7729L21.6731 22.2271L19.3654 24.1219L20.6346 25.6676ZM15.6683 19.4993C15.2435 18.7648 15 17.9124 15 17H13C13 18.2733 13.3409 19.4701 13.9371 20.5007L15.6683 19.4993ZM25 17C25 17.9124 24.7565 18.7648 24.3317 19.4993L26.0629 20.5007C26.6591 19.4701 27 18.2733 27 17H25Z" fill="#008DEB"/>
                            </svg>
                            <h5 class="main__item-title m-0">Главное в СМИ</h5>
                        `
                    },
                    result_news_list
                ],
                styles: `
                    .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family: sans-serif;
                    }
                    .${prefix}_header_section {
                        padding: 0 0 8px 0;
                    }
                    .${prefix}_article_item {
                        border-radius: 5px;
                        padding: 0 10px;
                    }
                    .${prefix}_article_item:hover {
                        background: hsl(0, 0%, 92%);
                    }
                    .${prefix}_article_item > img {
                        margin-right: 28px;
                        width: 25px;
                        height: 25px;
                        object-fit: cover;
                    }
                    .${prefix}_article_item > p {
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                `
            }
        }
    });
};
