export const Container = ({editor}) => {
    const prefix = 'container';

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                removable: false,
                draggable: false,
                copyable: false,
                editable: false,
                tagName: 'div',
                attributes: {
                    class: prefix + '_'
                },
                styles: `
                    .${prefix}_ {
                        position: relative;
                        border-radius: 10px;
                        background: white;
                        width: 100%;
                        height: 100%;
                        min-height: 400px;
                        background: #f3f5f5;
                    }
                `
            }
        }
    });
};
