import grapesjs from "grapesjs";
import ru from './../../lang/ru.json';
import en from './../../lang/en.json';
import plugin from 'grapesjs-tui-image-editor';
//import rte_extensions from 'grapesjs-rte-extensions';
import grapesjs_plugin_ckeditor from 'grapesjs-plugin-ckeditor';
import Elements from './elements';
import {album_business, album_personal, ecommerce, landing, portfolio, universal, profiles} from './samples';
import {AboutMeWidget,
    BusinessNewsWidget,
    BusinessDescriptionWidget,
    BusinessLocalMenu,
    BusinessAvatarCompanyWidget,
    CareerWidget,
    ContactsWidget,
    FooterWidget,
    FriendsWidget,
    LastSubscribesPostWidget,
    MainFeedWidget,
    MarketPlaceWidget,
    MediaWidget,
    CloudStorageWidget,
    CommentariesFriendsWidget,
    CommunitiesWidget,
    ConnectionComponent,
    LastPhotoWidget,
    OrganizerWidget,
    SubscriptionPostsListWidget,
    NewsWidget,
    PersonalLocalMenu,
    PersonalDataWidget,
    PopularPostsWidget,
    ProfileReferendumWidget,
    PurseWidget,
    StateWidget,
    TrendingWidget,
    VideoTheDayWidget,
    PhotoAndVideo} from './widget';
import Structures from './structure';
import Store from "../../../redux/store";

export class Constructor {
    constructor(params, id) {
        this.editor = null;
        this.id = id ? id : '#gjs';
        this.objParams = params;
        this.plugins = [];
    }

    getCustomTextColorStyle(editor) {
        editor.StyleManager.addType('custom-prop-text-color', {
            // Create UI
            create({props, change}) {
                const el = document.createElement('div');
                el.classList.add('gjs-field');
                el.classList.add('gjs-field-color');
                el.style.width = '100%';
                el.style.height = '34px';
                el.innerHTML = `<div class="gjs-input-holder choose-input-picker">
                                <span class="text-choose-color">${props.value}</span>
                            </div>
                            <div class="gjs-field-colorp choose-text-color">
                                <input type="color" class="my-input" value="${props.value}"/>
                            </div> `;
                const inputEl = el.querySelector('.my-input');
                inputEl.addEventListener('change', event => change({event}));
                inputEl.addEventListener('input', event => change({event, partial: true}));
                el.querySelector('.choose-text-color').addEventListener('click', () => {
                    inputEl.click();
                });
                return el;
            },
            emit({props, updateStyle}, {event, partial}) {
                const {value} = event.target;
                updateStyle(value, {partial});
            },
            update({value, el}) {
                el.querySelector('.my-input').value = value;
                el.querySelector('.choose-text-color').style.backgroundColor = value;
                el.querySelector('.text-choose-color').textContent = value;
            },
            destroy() {},
        });
    }

    getCustomTextBGColorStyles (editor){
        editor.StyleManager.addType('custom-prop-bgcolor', {
            // Create UI
            create({props, change}) {
                const el = document.createElement('div');
                el.classList.add('gjs-field');
                el.classList.add('gjs-field-color');
                el.style.width = '100%';
                el.style.height = '34px';
                el.innerHTML = `<div class="gjs-input-holder choose-input-picker">
                                <span class="text-choose-color">${props.value}</span>
                            </div>
                            <div class="gjs-field-colorp choose-text-color">
                                <input type="color" class="my-input" value="${props.value}"/>
                            </div> `;
                const inputEl = el.querySelector('.my-input');
                inputEl.addEventListener('change', event => change({event}));
                inputEl.addEventListener('input', event => change({event, partial: true}));
                el.querySelector('.choose-text-color').addEventListener('click', () => {
                    inputEl.click();
                });
                return el;
            },
            emit({props, updateStyle}, {event, partial}) {
                const {value} = event.target;
                updateStyle(value, {partial});
            },
            update({value, el}) {
                el.querySelector('.my-input').value = value;
                el.querySelector('.choose-text-color').style.backgroundColor = value;
                el.querySelector('.text-choose-color').textContent = value;
            },
            destroy() {},
        });
    }

    getTranslate (editor) {

        editor.I18n.setLocale(Store.getState().lang.value);

        editor.I18n.addMessages({
            en: {
                domComponents: {
                    names: en,
                },
                styleManager: {
                    sectors: en,
                    properties: en,
                    fileButton: en,
                    layer: en
                },
                phrase:en,
                blockManager: {
                    labels: en,
                    categories: en,
                },
            },
            ru: {
                domComponents: {
                    names: ru,
                },
                styleManager: {
                    sectors: ru,
                    properties: ru,
                    fileButton: ru,
                    layer: ru
                },
                phrase:ru,
                blockManager: {
                    labels: ru,
                    categories: ru,
                },
            }
        });
    }

    instance() {
        const _self = this;
        return new Promise((resolve) => {

            if (this.objParams.type === 'profile') {
                this.plugins.push(
                    LastPhotoWidget,
                    OrganizerWidget,
                    SubscriptionPostsListWidget,
                    MarketPlaceWidget,
                    MediaWidget,
                    CloudStorageWidget,
                    CommentariesFriendsWidget,
                    CommunitiesWidget,
                    ConnectionComponent,
                    PersonalLocalMenu,
                    VideoTheDayWidget,
                    TrendingWidget,
                    MainFeedWidget,
                    StateWidget,
                    PurseWidget,
                    ProfileReferendumWidget,
                    PopularPostsWidget,
                    PersonalDataWidget,
                    NewsWidget,
                    FooterWidget,
                    ContactsWidget,
                    LastSubscribesPostWidget,
                    FriendsWidget,
                    CareerWidget,
                    PhotoAndVideo,

                    profiles
                );
            }
            else {
                this.plugins.push(
                    PersonalLocalMenu,
                    VideoTheDayWidget,
                    TrendingWidget,
                    MainFeedWidget,
                    StateWidget,
                    PurseWidget,
                    ProfileReferendumWidget,
                    PopularPostsWidget,
                    PersonalDataWidget,
                    NewsWidget,
                    FooterWidget,
                    ContactsWidget,
                    BusinessLocalMenu,
                    BusinessAvatarCompanyWidget,
                    BusinessDescriptionWidget,
                    LastSubscribesPostWidget,
                    FriendsWidget,
                    CareerWidget,
                    AboutMeWidget,
                    BusinessNewsWidget,
                    PhotoAndVideo,

                    album_personal,
                    album_business,
                    ecommerce,
                    landing,
                    portfolio,
                    universal
                );
            }

            Elements.map(item => this.plugins.push(item));
            Structures.map(item => this.plugins.push(item));

            this.plugins.push(
                _self.getTranslate,
                _self.getCustomTextColorStyle,
                _self.getCustomTextBGColorStyles,
                grapesjs_plugin_ckeditor,
                //rte_extensions,
                plugin
            );

            const editor = grapesjs.init({
                container: _self.id,
                fromElement: true,
                height: '94vh',
                width: 'auto',
                // storageManager: false,
                storageManager: true,
                // storageManager: {
                //     type: 'remote'
                // },
                panels: {defaults: []},
                canvas: {
                    'styles': [
                        "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css",
                    ]
                },
                plugins: _self.plugins,
                pluginsOpts: {
                    [grapesjs_plugin_ckeditor]: {
                        position: 'center',
                        options: {
                            language: 'ru',
                            toolbarGroups: [
                                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                { name: 'colors', groups: ['colors'] },
                                { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                                { name: 'forms', groups: ['forms'] },
                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                                { name: 'links', groups: ['links'] },
                               // { name: 'insert', groups: ['insert'] },
                                { name: 'styles', groups: ['styles'] },
                                { name: 'tools', groups: ['tools'] },
                                { name: 'others', groups: ['others'] },
                                { name: 'about', groups: ['about'] }
                            ],
                            extraPlugins: 'colorbutton',
                            removeButtons: 'NewPage',
                            colorButton_colors: '000000,4d4d4d,999999,e6e6e6,CCEAEE,ffffff,e64d4d,e6994d,99e64d,4de64d,4de699,4de6e6,4d99e6,4d4de6,994de6',
                            colorButton_enableMore: true
                        },
                    },
                    [plugin]: {
                        config: {
                            includeUI: {
                                initMenu: 'filter',
                                loadImage: {
                                    path: 'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg',
                                    name: 'SampleImage',
                                },
                            },
                            cssMaxWidth: 600,
                            cssMaxHeight: 390,
                        },
                    },

                    // [AboutMeWidget]: {
                    //     'text_param': _self.objParams
                    // },
                },
                blockManager: {
                    appendTo: '.blocks-container',
                    blocks: [
                    ],
                },
                layerManager: {
                    appendTo: '.layers-container',
                    hidable: false
                },
                traitManager: {
                    appendTo: '.traits-container',
                },
                styleManager: {
                    appendTo: '.styles-container',
                    sectors: [
                        {
                            name: 'custom_styles',
                            open: true,
                            buildProps: [/*'box-shadow', */'font-family'],
                            properties: [
                                {
                                    type: 'custom-prop-text-color',
                                    property: 'color',
                                    default: 'gray',
                                    value: 'gray'
                                },
                                {
                                    type: 'custom-prop-bgcolor',
                                    property: 'background-color',
                                    default: 'gray',
                                    value: 'gray'
                                },
                            ],
                        }]
                },
            });

            // editor.Storage.add('remote', {
            //     async store(data) {
            //         return localStorage.setItem('pl-project', JSON.stringify(data));
            //     },
            //     async load() {
            //         return console.log('Конструктор готов!');
            //     }
            // });

            resolve(editor);
        });
    }
}
