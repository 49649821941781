export const FooterWidget = ({editor}) => {

    const prefix = 'footer';

    function script() {}

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                script,
                tagName: 'div',
                attributes: {
                    class: prefix + '__container d-flex flex-column '
                },
                removable: false,
                layerable: false,
                draggable: false,
                droppable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        attributes: {
                            class: prefix + '__meta_nav'
                        },
                        components: [
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    href: '#'
                                },
                                content: 'Справки'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    href: '#'
                                },
                                content: 'Обратная связь'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    href: '#'
                                },
                                content: 'О нас'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    href: '#'
                                },
                                content: 'Соглашение'
                            },
                            {
                                tagName: 'a',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                attributes: {
                                    href: '#'
                                },
                                content: 'Конфиденциальность'
                            },
                        ]
                    },
                    {
                        tagName: 'img',
                        removable: false,
                        layerable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        attributes: {
                            // src: Image.icon_logo,
                            class: prefix + '_logo'
                        },
                    },
                    {
                        tagName: 'span',
                        removable: false,
                        layerable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        attributes: {
                            class: prefix + '__copy_item'
                        },
                        content: 'Copyright © 2024, Planet'
                    }
                ],
                styles: `
                    .${prefix}__container {
                       display: flex;
                       align-items: center;
                       margin: 72px 0;
                       color: #0000008A;
                    }
                    .${prefix}__meta_nav > a {
                        margin-right: 15px;
                        color: #0071BD;
                        cursor: pointer;
                    }
                    .${prefix}_logo {
                        margin: 24px 0;
                        cursor: pointer;
                    }
                `
            }
        }
    });
};
