import ko from 'knockout';
import Handler from 'engine/Handler';
import Highcharts from "highcharts/highmaps";
import map_rus from '@highcharts/map-collection/countries/ru/ru-all.topo.json'

//import 'knockout-mapping';
export default class statistic_referendum extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.mapInitial();
        this.stat_uniqu_views = {
            data: [
                { year: '', count: 10 },
                { year: '', count: 15 },
                { year: '', count: 88 },
                { year: '', count: 20 },
                { year: '', count: 35 },
                { year: '', count: 62 },
                { year: '', count: 28 }
            ]
        };
        this.stat_new_subscribes = {
            data: [
                { year: '', count: 20 },
                { year: '', count: 15 },
                { year: '', count: 35 },
                { year: '', count: 45 },
                { year: '', count: 10 },
            ]
        };
        this.stat_geo_country = {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
        this.stat_geo_regions = {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                'значение 1',
                'значение 2',
                'значение 3',
                'значение 4',
                'значение 5',
                'значение 6',
            ]
        };
        this.stat_coverage_devices = {
            data: [12, 19, 3, 5, 2, 3],
            labels: [
                '#FF6384',
                '#FF8F1F',
                '#FFCD56',
                '#4CBFC0',
                '#37A2EB',
            ]
        };
    }
    mapInit
    mapInitial() {
        const data = [
            ['ru-3637', 10], ['ru-ck', 11], ['ru-ar', 12], ['ru-nn', 13],
            ['ru-yn', 14], ['ru-ky', 15], ['ru-sk', 16], ['ru-kh', 17],
            ['ru-sl', 18], ['ru-ka', 19], ['ru-kt', 20], ['ru-2510', 21],
            ['ru-rz', 22], ['ru-sa', 23], ['ru-ul', 24], ['ru-om', 285],
            ['ru-ns', 26], ['ru-mm', 27], ['ru-ln', 28], ['ru-sp', 29],
            ['ru-ki', 30], ['ru-kc', 31], ['ru-in', 32], ['ru-kb', 33],
            ['ru-no', 34], ['ru-st', 35], ['ru-sm', 36], ['ru-ps', 37],
            ['ru-tv', 38], ['ru-vo', 39], ['ru-iv', 40], ['ru-ys', 41],
            ['ru-kg', 42], ['ru-br', 43], ['ru-ks', 44], ['ru-lp', 45],
            ['ru-ms', 46], ['ru-ol', 47], ['ru-nz', 48], ['ru-pz', 49],
            ['ru-vl', 50], ['ru-vr', 51], ['ru-ko', 52], ['ru-sv', 53],
            ['ru-bk', 54], ['ru-ud', 55], ['ru-mr', 56], ['ru-cv', 57],
            ['ru-cl', 58], ['ru-ob', 59], ['ru-sr', 60], ['ru-tt', 61],
            ['ru-to', 62], ['ru-ty', 63], ['ru-ga', 64], ['ru-kk', 65],
            ['ru-cn', 66], ['ru-kl', 67], ['ru-da', 68], ['ru-ro', 69],
            ['ru-bl', 70], ['ru-tu', 71], ['ru-ir', 72], ['ru-ct', 73],
            ['ru-yv', 74], ['ru-am', 75], ['ru-tb', 76], ['ru-tl', 77],
            ['ru-ng', 78], ['ru-vg', 79], ['ru-kv', 80], ['ru-me', 81],
            ['ru-ke', 82], ['ru-as', 83], ['ru-pr', 84], ['ru-mg', 85],
            ['ru-bu', 86], ['ru-kn', 87], ['ru-kd', 88], ['ru-ku', 89],
            ['ru-al', 90], ['ru-km', 91], ['ru-pe', 92], ['ru-ad', 93]
        ];

        const getGraticule = () => {
            const data = [];

            // Meridians
            for (let x = -180; x <= 180; x += 15) {
                data.push({
                    geometry: {
                        type: 'LineString',
                        coordinates: x % 90 === 0 ? [
                            [x, -90],
                            [x, 0],
                            [x, 90]
                        ] : [
                            [x, -80],
                            [x, 80]
                        ]
                    }
                });
            }

            // Latitudes
            for (let y = -90; y <= 90; y += 10) {
                const coordinates = [];
                for (let x = -180; x <= 180; x += 5) {
                    coordinates.push([x, y]);
                }
                data.push({
                    geometry: {
                        type: 'LineString',
                        coordinates
                    },
                    lineWidth: y === 0 ? 2 : undefined
                });
            }

            return data;
        };


        Highcharts.mapChart('referendum-statistic', {
            chart: {
                map: map_rus
            },

            title: {
                text: ''
            },

            subtitle: {
                // text: 'Source map: <a href="https://code.highcharts.com/mapdata/countries/ru/ru-all.topo.json">Russia</a>'
                text: ''
            },

            tooltip: {
                pointFormat: '{point.name}: {point.value}'
            },

            legend: {
                enabled: false
            },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },

            colorAxis: {
                min: 0
            },

            series: [{
                data: data,
                // name: 'Random data',
                states: {
                    hover: {
                        color: '#BADA55'
                    }
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}'
                },
                // type: 'mappoint',
                // name: 'Test',
                // marker: {
                //     symbol: 'url(https://www.highcharts.com/samples/graphics/museum.svg)',
                //     width: 24,
                //     height: 24
                // },
            }]
        });
    }
}
