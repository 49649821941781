import ko from 'knockout';
import Handler from 'engine/Handler';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

//import 'knockout-mapping';
export default class polls extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.answers = ko.observableArray([
            'Первый ответ', 'Второй ответ'
        ]);

        var el = document.querySelector('.draggable-list');
        Sortable.create(el);
    }

    addAnswerField() {
        this.answers.push(ko.observable(''));
    }

    deleteAnswer(id) {
        console.log(id)
        this.answers.remove(this.answers()[id]);
    }
}
