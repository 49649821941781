export const TrendingWidget = ({editor}) => {
    let prefix = 'trending';

    editor.Blocks.add(prefix, {
        media: `<svg width="65" height="65" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8324 29.8013C23.9583 29.1747 28 26.926 28 21.1112C28 15.8196 24.1267 12.2959 21.3415 10.6768C20.7235 10.3176 20 10.7901 20 11.5049V13.3334C20 14.7753 19.3938 17.4071 17.7093 18.5018C16.8493 19.0607 15.9205 18.2242 15.816 17.2039L15.7302 16.366C15.6304 15.392 14.6384 14.8008 13.86 15.3946C12.4615 16.4614 11 18.3296 11 21.1112C11 28.2223 16.2889 30.0001 18.9333 30.0001C19.0871 30.0001 19.2488 29.9955 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013Z" fill="#99D6FF"/>
                    <path d="M16 26.4442C16 29.064 18.1113 29.8742 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013C21.871 29.4343 23 28.4922 23 26.4442C23 25.1465 22.1814 24.3459 21.5401 23.9711C21.3439 23.8564 21.1161 24.0008 21.0985 24.2273C21.0429 24.9454 20.3534 25.5174 19.8836 24.9714C19.4685 24.4889 19.2941 23.784 19.2941 23.3331V22.7439C19.2941 22.3887 18.9365 22.1533 18.631 22.3346C17.4951 23.0085 16 24.3949 16 26.4442Z" fill="#008DEB"/>
                 </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Актуальные темы'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: prefix + '_title_section d-flex'
                        },
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        content: `
                             <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#E0F3FF"/>
                                <path d="M20.8324 29.8013C23.9583 29.1747 28 26.926 28 21.1112C28 15.8196 24.1267 12.2959 21.3415 10.6768C20.7235 10.3176 20 10.7901 20 11.5049V13.3334C20 14.7753 19.3938 17.4071 17.7093 18.5018C16.8493 19.0607 15.9205 18.2242 15.816 17.2039L15.7302 16.366C15.6304 15.392 14.6384 14.8008 13.86 15.3946C12.4615 16.4614 11 18.3296 11 21.1112C11 28.2223 16.2889 30.0001 18.9333 30.0001C19.0871 30.0001 19.2488 29.9955 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013Z" fill="#99D6FF"/>
                                <path d="M16 26.4442C16 29.064 18.1113 29.8742 19.4171 29.9858C19.863 29.9296 19.4171 30.085 20.8324 29.8013C21.871 29.4343 23 28.4922 23 26.4442C23 25.1465 22.1814 24.3459 21.5401 23.9711C21.3439 23.8564 21.1161 24.0008 21.0985 24.2273C21.0429 24.9454 20.3534 25.5174 19.8836 24.9714C19.4685 24.4889 19.2941 23.784 19.2941 23.3331V22.7439C19.2941 22.3887 18.9365 22.1533 18.631 22.3346C17.4951 23.0085 16 24.3949 16 26.4442Z" fill="#008DEB"/>
                             </svg>
                             <h5 class="main__item-title">Актуальные темы</h5>
                        `
                    },
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_items-top'
                                },
                                content: `
                                    <div class="${prefix}_item ${prefix}_top_item">
                                        <span class="${prefix}_item__content">1 Кроссовки-подделка</span>
                                    </div>
                                `
                            },
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_items-bottom'
                                },
                                content: `
                                    <div class="${prefix}_item">
                                        <span class="${prefix}_item__content">2 Айсберг</span>
                                    </div> 
                                    <div class="${prefix}_item">
                                        <span class="${prefix}_item__content">3 XAMAC</span>
                                    </div> 
                                    <div class="${prefix}_item">
                                        <span class="${prefix}_item__content">4 Кроссовки-подделки</span>
                                    </div> 
                                    <div class="${prefix}_item">
                                        <span class="${prefix}_item__content">5 Armbrok</span>
                                    </div> 
                                `
                            }
                        ]
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        font-family: sans-serif;
                     }
                     .${prefix}_title_section {
                        display: flex;
                        align-items: center;
                        margin-bottom: 14px;
                     }
                     .${prefix}_title_section > h5 {
                        margin: 0;
                        margin-left: 10px;
                     }
                     .${prefix}_items-bottom {
                        display: grid;
                        width: 100%;
                        grid-template-columns: repeat(2, 1fr);
                         gap: 1rem;
                     }
                     .${prefix}_items-bottom > div {
                        color: #008DEB;
                        background: #E0F3FF;
                     }
                     .${prefix}_items-top {
                        margin-bottom: 1rem;
                     }
                     .${prefix}_items-top > div {
                        background: #008DEB;
                        color: #E0F3FF;
                     }
                     .${prefix}_item {
                         height: 72px;
                         border-radius: 10px;
                         display: flex;
                         align-items: center;
                         padding: 0 25px;
                     }
                     .${prefix}_item__content {
                        font-size: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                     }
                     .${prefix}_top_item {
                        width: 100%;
                     }
                    
                `
            }
        }
    });
};
