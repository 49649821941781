export const LastSubscribesPostWidget = ({editor}) => {

    let prefix = 'last_subscribes_post';

    editor.Blocks.add(prefix, {
        media: `<svg width="40" height="65" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8852 2.5H24.1148C28.7315 2.49997 32.3496 2.49995 35.1726 2.87949C38.0621 3.26797 40.3421 4.07865 42.1317 5.86827C43.9213 7.65789 44.732 9.93794 45.1205 12.8274C45.5 15.6504 45.5 19.2685 45.5 23.8853V24.0618C45.5 27.8794 45.5 31.0046 45.2927 33.5491C45.0843 36.1061 44.6574 38.2428 43.7018 40.0175C43.2803 40.8002 42.7623 41.5011 42.1317 42.1317C40.3421 43.9213 38.0621 44.732 35.1726 45.1205C32.3496 45.5 28.7315 45.5 24.1147 45.5H23.8853C19.2685 45.5 15.6504 45.5 12.8274 45.1205C9.93794 44.732 7.65789 43.9213 5.86827 42.1317C4.28172 40.5452 3.4624 38.5705 3.0267 36.1209C2.5987 33.7145 2.5204 30.7206 2.50414 27.003C2.5 26.0573 2.5 25.0572 2.5 24.002L2.5 23.8852C2.49997 19.2685 2.49995 15.6504 2.87949 12.8274C3.26797 9.93794 4.07865 7.65789 5.86827 5.86827C7.65789 4.07865 9.93794 3.26797 12.8274 2.87949C15.6504 2.49995 19.2685 2.49997 23.8852 2.5ZM13.2272 5.85274C10.6703 6.1965 9.12891 6.85028 7.98959 7.98959C6.85028 9.12891 6.1965 10.6703 5.85274 13.2272C5.50319 15.8271 5.5 19.2435 5.5 24C5.5 25.0574 5.5 26.0514 5.50411 26.9898C5.52051 30.7381 5.60428 33.4812 5.98035 35.5955C6.34872 37.6666 6.97547 38.9963 7.98959 40.0104C9.12891 41.1497 10.6703 41.8035 13.2272 42.1473C15.8271 42.4968 19.2435 42.5 24 42.5C28.7565 42.5 32.1729 42.4968 34.7728 42.1473C37.3297 41.8035 38.8711 41.1497 40.0104 40.0104C40.4303 39.5906 40.7744 39.1262 41.0604 38.5951C41.7237 37.3633 42.1062 35.7156 42.3026 33.3054C42.4989 30.8965 42.5 27.8917 42.5 24C42.5 19.2435 42.4968 15.8271 42.1473 13.2272C41.8035 10.6703 41.1497 9.12891 40.0104 7.98959C38.8711 6.85028 37.3297 6.1965 34.7728 5.85274C32.1729 5.50319 28.7565 5.5 24 5.5C19.2435 5.5 15.8271 5.50319 13.2272 5.85274Z" fill="#0099FF"/>
                    <path d="M42.4974 26.5609L42.0531 26.4993C36.3657 25.7119 31.161 28.6685 28.5107 33.1252C25.0918 24.4752 16.0569 18.5761 5.96147 20.0257L5.50993 20.0908C5.50028 21.2841 5.5 22.5826 5.5 24C5.5 25.0574 5.5 26.0514 5.50411 26.9898C5.52051 30.7381 5.60428 33.4812 5.98034 35.5955C6.34872 37.6666 6.97547 38.9963 7.98959 40.0104C9.1289 41.1497 10.6703 41.8035 13.2272 42.1473C15.8271 42.4968 19.2435 42.5 24 42.5C28.7565 42.5 32.1729 42.4968 34.7728 42.1473C37.3297 41.8035 38.8711 41.1497 40.0104 40.0104C40.4303 39.5906 40.7744 39.1262 41.0604 38.5951C41.7237 37.3633 42.1062 35.7156 42.3026 33.3054C42.4535 31.4535 42.489 29.2494 42.4974 26.5609Z" fill="#99D6FF"/>
                    <path d="M36 16C36 18.2091 34.2091 20 32 20C29.7909 20 28 18.2091 28 16C28 13.7909 29.7909 12 32 12C34.2091 12 36 13.7909 36 16Z" fill="#0099FF"/>
                </svg>`,
        prefix,
        category: 'Виджеты',
        select: true,
        content: {type: prefix},
        label: 'Крайний пост подписчика'
    });

    editor.Components.addType(prefix, {
        model: {
            defaults: {
                tagName: 'div',
                draggable: '[data-gjs-type=one_column]',
                attributes: {
                    class: prefix + '_container'
                },
                components: [
                    {
                        tagName: 'div',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_line_cell_content'
                        },
                        components: [
                            {
                                tagName: 'div',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_card_line_cell_content'
                                },
                                components: [
                                    {
                                        tagName: 'img',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        attributes: {
                                            src: 'https://static.wikia.nocookie.net/vampirediariesfrance/images/6/6c/2-popo34000.jpg/revision/latest?cb=20140916185107&path-prefix=fr',
                                            class: prefix + '_avatar'
                                        }
                                    },
                                    {
                                        tagName: 'div',
                                        removable: false,
                                        draggable: false,
                                        droppable: false,
                                        copyable: false,
                                        selectable: false,
                                        hoverable: false,
                                        editable: false,
                                        content: `
                                            <h5 class="${prefix}_line_cell_title">Софья Смирнова</h5>
                                            <span class="${prefix}_line_cell_sub_title">10 минут назад</span>
                                        `
                                    },
                                ]
                            },
                            {
                                tagName: 'button',
                                removable: false,
                                draggable: false,
                                droppable: false,
                                copyable: false,
                                selectable: false,
                                hoverable: false,
                                editable: false,
                                attributes: {
                                    class: prefix + '_nav-menu'
                                },
                                content: `
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.33337 13C5.88566 13 6.33337 12.5523 6.33337 12C6.33337 11.4477 5.88566 11 5.33337 11C4.78109 11 4.33337 11.4477 4.33337 12C4.33337 12.5523 4.78109 13 5.33337 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.3334 13C12.8857 13 13.3334 12.5523 13.3334 12C13.3334 11.4477 12.8857 11 12.3334 11C11.7811 11 11.3334 11.4477 11.3334 12C11.3334 12.5523 11.7811 13 12.3334 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.3334 13C19.8857 13 20.3334 12.5523 20.3334 12C20.3334 11.4477 19.8857 11 19.3334 11C18.7811 11 18.3334 11.4477 18.3334 12C18.3334 12.5523 18.7811 13 19.3334 13Z" stroke="black" stroke-opacity="0.38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                `
                            },
                        ]
                    },
                    {
                        tagName: 'p',
                        removable: false,
                        draggable: false,
                        droppable: false,
                        copyable: false,
                        selectable: false,
                        hoverable: false,
                        editable: false,
                        attributes: {
                            class: prefix + '_post'
                        },
                        content: `
                            На календаре снова уже среда, а это значит, что на Альтерлите у меня вышла новая статья.
                            В этот раз даже сразу две.
                            Потому что тема там глубокая и таинственная, как недра Марианской впадины: "В сорок лет жизнь только начинается...'
                        `
                    }
                ],
                styles: `
                     .${prefix}_container {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        border-radius: 10px;
                        padding: 20px 32px;
                        width: 100%;
                        height: 100%;
                        font-family:SF Pro Text, sans-serif;
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                     }
                     .${prefix}_container::-webkit-scrollbar {
                        display: none;
                     }
                     .${prefix}_avatar {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 16px;
                        object-fit: cover;
                     }
                     .${prefix}_nav-menu {
                       background: none;
                       border: none;
                       cursor: pointer;
                       border-radius: 10px;
                       padding: 10px;
                       display: flex;
                       align-items: center;
                     }
                     .${prefix}_nav-menu:hover {
                        background: white;
                     }
                     .${prefix}_card_line_cell_content {
                        display: flex;
                        align-items: center;
                     }
                     .${prefix}_line_cell_title {
                        margin: 0 0 5px 0;
                     }
                     .${prefix}_line_cell_sub_title {
                        font-size: 14px;
                     }
                     .${prefix}_post {
                        font-size: 18px;
                        line-height: 30px;
                        padding: 15px;
                        color: #0000008A;
                        text-align: justify;
                     }
                     .${prefix}_line_cell_content {
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #008DEB;
                        background: #E0F3FF;
                        border-radius: 30px 10px 10px 30px;
                     }
                `
            }
        }
    });
};
